import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Button, Collapse, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyButton from "../shared_components/copy_button";

const RESOURCE_EMBED_OPTIONS = [
  { label: "Turn on captions", value: "show_subtitles", default: true, requireLogin: false, isPremium: false },
  { label: "Show pause prompts", value: "show_prompts", default: false, requireLogin: true, isPremium: false },
  { label: "Automatically skip profanity", value: "skip_profanity", default: false, requireLogin: true, isPremium: false }
];

const USER_CLIP_EMBED_OPTIONS = [
  { label: "Show pause prompts", value: "show_prompts", default: false, requireLogin: true, isPremium: false }
];

const DEFAULT_WIDTH = "480";
const DEFAULT_HEIGHT = "320";

interface Props {
  baseEmbedUrl: string;
  clipType: string;
  hasPremium?: boolean;
  isSupportedVideoHost?: boolean;
  userId?: number;
  trackingAttrs?: {
    "data-ch-event-type"?: string;
    "data-ch-event-name": string;
    "data-ch-event-model-id": string;
    "data-ch-event-model-type": string;
    "data-ch-event-link": string;
    "data-ch-event-action": string;
    [key: string]: string;
  }
}

function embedCodeHtml(embedCodeUrl, width, height) {
  // Replace multiple whitespace characters with a single space
  const iframeHtml = `
    <iframe
      allowfullscreen
      frameborder="0"
      height="${height}"
      src="${embedCodeUrl}"
      width="${width}"></iframe>`.replace(/\s+/g, ' ').trim();

  // Source: https://stackoverflow.com/a/34114771
  return iframeHtml;
}

function renderNotice(message) {
  return (
    <span className="ml-2">
      <OverlayTrigger
        placement={"top"}
        overlay={
          <Tooltip>{message}</Tooltip>
        }
      >
        <FontAwesomeIcon icon="info-circle" className="text-primary" />
      </OverlayTrigger>
    </span>
  );
}

function ClipEmbedCode({ baseEmbedUrl, clipType, hasPremium, isSupportedVideoHost, userId, trackingAttrs }: Props) {
  const defaultQueryParams = { show_subtitles: true };

  if (userId) {
    defaultQueryParams['user_id'] = userId;
  }

  const [queryParams, setQueryParams] = useState(defaultQueryParams);
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [open, setOpen] = useState(false);

  const randomUuid = uuid();
  const embedOptionsElementId = `clip-embed-options-${randomUuid}`;
  const inputId = `clip-embed-input-${randomUuid}`;

  const onOptionsChange = (e) => {
    const theQueryParams = { ...queryParams };

    if (e.target.checked) {
      theQueryParams[e.target.value] = true;
    } else {
      delete theQueryParams[e.target.value];
    }

    setQueryParams(theQueryParams);
  };

  let embedOptions = [];

  if (isSupportedVideoHost) {
    embedOptions = (clipType === "Resource") ? RESOURCE_EMBED_OPTIONS : USER_CLIP_EMBED_OPTIONS;
  }

  embedOptions = embedOptions.map((embedOption, index) => {
    let message;

    if (embedOption.requireLogin && !userId) {
      message = "Log in to use this option.";
    } else if (embedOption.isPremium && !hasPremium) {
      message = "Upgrade to Premium to use this option.";
    }

    return (
      <div className="d-flex flex-wrap mt-3" key={index}>
        <div>
          <Form.Check type="checkbox">
            <Form.Check.Input
              id={`${randomUuid}-${embedOption.value}`}
              type="checkbox"
              value={embedOption.value}
              onChange={onOptionsChange}
              defaultChecked={embedOption.default}
              disabled={!!message}
            />

            <Form.Check.Label htmlFor={`${randomUuid}-${embedOption.value}`}>
              {embedOption.label}
            </Form.Check.Label>
          </Form.Check>
        </div>

        {message && renderNotice(message)}
      </div>
    );
  });

  const urlParamString = new URLSearchParams(queryParams).toString();
  const embedCodeUrl = `${baseEmbedUrl}` + ((urlParamString.length > 0) ? `?${urlParamString}` : '');
  const embedHtml = embedCodeHtml(embedCodeUrl, width, height);

  return (
    <>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <FontAwesomeIcon icon="globe-americas" size="1x" />
            <span className="sr-only">Globe</span>
          </span>
        </div>

        <Form.Control id={inputId} type="text" value={embedHtml} readOnly={true} />

        <div className="input-group-append">
          <CopyButton targetElementId={inputId} trackingAttrs={trackingAttrs} />
        </div>
      </div>

      {embedOptions.length > 0 &&
        <>
          <Button
            variant="link"
            size="sm"
            className="mt-2"
            onClick={() => setOpen(!open)}
            aria-controls={embedOptionsElementId}
            aria-expanded={open}
          >
            Advanced options
          </Button>

          <Collapse in={open}>
            <div id={embedOptionsElementId}>
              <div className="form-row">
                <div className="form-group col-md-6 mb-0">
                  <Form.Label htmlFor={`${embedOptionsElementId}-width`} className="small mb-0">
                    Width
                  </Form.Label>

                  <Form.Control id={`${embedOptionsElementId}-width`} size="sm" type="number" value={width} onChange={e => setWidth(e.target.value)} />
                </div>

                <div className="form-group col-md-6 mb-0">
                  <Form.Label htmlFor={`${embedOptionsElementId}-height`} className="small mb-0">
                    Height
                  </Form.Label>

                  <Form.Control id={`${embedOptionsElementId}-height`} size="sm" type="number" value={height} onChange={e => setHeight(e.target.value)} />
                </div>
              </div>

              {embedOptions}
            </div>
          </Collapse>
        </>
      }
    </>
  );
}

export function renderClipEmbedCode(elementId, options) {
  const node = ReactDOM.render(
    <ClipEmbedCode
      baseEmbedUrl={options.baseEmbedUrl}
      clipType={options.clipType}
      userId={options.userId}
      hasPremium={options.hasPremium}
      isSupportedVideoHost={options.isSupportedVideoHost}
      trackingAttrs={options.trackingAttrs}
    />,
    document.getElementById(elementId)
  );

  return node;
}