import React, { MouseEventHandler } from "react";
import classNames from "classnames";

import cleverIcon from "./clever_icon.svg";
import styles from "./clever_sign_in_button.module.scss";

interface Props {
  url: string;
  buttonText?: 'Sign up' | 'Sign in';
  width?: string;
  onClick?: MouseEventHandler<EventTarget>;
}

export default function CleverSignInButton({ buttonText = "Sign up", width = "100%", url, onClick }: Props) {
  return (
    <a
      href={url}
      rel="noopener noreferrer"
      className={classNames(styles.button, "btn btn-link")}
      target="_blank"
      style={{ width: width }}
      onClick={onClick}
    >
      <img src={cleverIcon} className={styles.iconImg} />
      <span>{buttonText} with Clever</span>
    </a>
  );
}