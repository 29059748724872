import React from "react";

interface Tag {
  id: number;
  name: string;
}

interface Props {
  tag: Tag;
  enableClicks?: boolean;
}

export default function DisplayTag({ tag, enableClicks = true }: Props) {
  const queryParams = {
    q: tag.name,
    "filters[tags][]": tag.id.toString()
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const tagUrl = `/search?${queryString}`

  return (
    <div className="d-inline-block display-tag">
      {enableClicks &&
        <a href={tagUrl} className="text-reset">
          {tag.name}
        </a>
      }

      {!enableClicks && <span>{tag.name}</span>}
    </div>
  );
}
