import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDebouncedCallback } from "use-debounce";
import { validate } from "email-validator";
import emailSpellChecker from '@zootools/email-spell-checker';

import { fetchData, postData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import { chAnalyticsTrackEvent } from "../analytics";
import VerifyEmail from "./verify_email";
import { User } from "../../types";

type MODES = 'signup' | 'verify_email';

interface Props {
  onSuccess: (user: User, redirectUrl: string) => void;
  userReferredByOptions: Array<{ label: string, value: string }>;
  userTypeOptions: Array<{ label: string, value: string }>;
  shouldVerifyEmail?: boolean;
  includeStudentHelpText?: boolean;
  excludedFields?: Array<'password'>;
  userAttrs?: {
    name?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
    purchase_premium?: string;
    referred_user_code?: string;
    referred_by?: string;
    user_type?: string;
  };
}

function referredByDetailsDisplayLabel(referredBy: string) {
  switch (referredBy) {
    case "Event":
      return "Event Name";
    case "Blog":
      return "Which blog?";
    case "Other":
      return 'Please elaborate on "Other"';
    default:
      return '';
  }
}

// Prefixed with "Ch" so it's not a generic name that might clash with something else
export function ChSignUp({ includeStudentHelpText = false, shouldVerifyEmail = false, excludedFields = [], ...props }: Props) {
  const [name, setName] = useState(props.userAttrs?.name || "");
  const [email, setEmail] = useState(props.userAttrs?.email || "");
  const [userType, setUserType] = useState(props.userAttrs?.user_type || "");
  const [password, setPassword] = useState(props.userAttrs?.password || "");
  const [passwordConfirmation, setPasswordConfirmation] = useState(props.userAttrs?.password_confirmation || "");
  const [referredBy, setReferredBy] = useState(props.userAttrs?.referred_by || "");
  const [referredByDetails, setReferredByDetails] = useState("");
  const [terms, setTerms] = useState(false);

  const [mode, setMode] = useState<MODES>("signup");

  const [errors, setErrors] = useState([]);
  const [emailSuggestion, setEmailSuggestion] = useState<any>();
  const [emailError, setEmailError] = useState();

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = useDebouncedCallback(
    async () => {
      const { data } = await fetchData({
        url: '/users/validate_email',
        params: { email: email }
      });

      const isValid = data.errors.length === 0;
      const suggestion = emailSpellChecker.run({ email: email });

      setIsEmailValid(isValid);
      setEmailError(data.errors[0]);
      setEmailSuggestion(suggestion);
    },
    500
  );

  useEffect(() => {
    if (email.length < 3) return;

    validateEmail();
  }, [email]);

  const sendCreateAccountRequest = async () => {
    setIsLoading(true);

    try {
      const { data } = await postData({
        url: '/users',
        data: {
          user: {
            name: name,
            email: email,
            user_type: userType,
            password: password,
            password_confirmation: passwordConfirmation,
            referred_by: referredBy,
            referred_user_code: props.userAttrs?.referred_user_code,
            referred_by_details: referredByDetails,
            purchase_premium: props.userAttrs?.purchase_premium,
            terms: terms ? "1" : "0"
          }
        }
      });

      setIsLoading(false);
      props.onSuccess(data.user, data.redirect_url);
    } catch (e) {
      const { data } = e.response;

      setIsLoading(false);
      setErrors(data.errors);
    }
  };

  const handleSetReferredBy = (e) => {
    setReferredBy(e.target.value);
    setReferredByDetails('');
  };

  const handleEmailSuggestionClick = (e) => {
    e.preventDefault();
    setEmail(emailSuggestion.full);
    setEmailSuggestion(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await sendCreateAccountRequest();
  };

  const onVerifyEmailClick = () => {
    chAnalyticsTrackEvent("verify_email", {
      label: "sent_verification_email",
      link: "signup_btn",
      extra_data: {
        user_type: userType
      }
    });

    setMode("verify_email");
  };

  const onEmailVerified = async () => {
    await sendCreateAccountRequest();
  };

  const referredByDetailsLabel = referredByDetailsDisplayLabel(referredBy);
  const isEnabled = (
    name.length > 0 &&
    email.length > 0 &&
    userType.length > 0 &&
    password.length > 6 &&
    password === passwordConfirmation &&
    referredBy.length > 0 &&
    terms &&
    isEmailValid &&
    validate(email)
  );

  return (
    <>
      <FormErrorsList errors={errors} />

      {mode === "verify_email" &&
        <VerifyEmail
          email={email}
          onEmailVerified={onEmailVerified}
          onNavigateBack={() => setMode("signup")}
        />
      }

      {mode === "signup" &&
        <form onSubmit={onSubmit}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="name">Name</label>
              <input id="name" type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12 mb-0">
              <label htmlFor="email">Email Address</label>
              <input id="email" type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
            </div>

            <div className="form-group col-md-12">
              {emailSuggestion &&
                <div className="small">
                  Did you mean
                  {' '}
                  <a href="#" className="text-danger" onClick={handleEmailSuggestionClick}>
                    {emailSuggestion.full}
                  </a>
                  ?
                </div>
              }
              {emailError && <div className="text-danger">{`Email ${emailError}`}</div>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="user_type">Role</label>
              <select id="user_type" className="form-control" value={userType} onChange={e => setUserType(e.target.value)}>
                {
                  props.userTypeOptions.map((obj, index) => {
                    return (
                      <option value={obj.value} key={index}>
                        {obj.label}
                      </option>
                    );
                  })
                }
              </select>

              {includeStudentHelpText &&
                <div className="text-muted small">
                  Are you a student? Sign up with Clever or Google Classroom, or ask your teacher to
                  {' '}
                  <a
                    href="https://help.classhook.com/support/how-to-create-student-accounts"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    invite you
                  </a>
                  .
                </div>
              }
            </div>
          </div>

          {!excludedFields.includes("password") &&
            <>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="password">Password</label>
                  <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="password_confirmation">Confirm Password</label>
                  <input id="password_confirmation" type="password" className="form-control" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} />
                </div>
              </div>
            </>
          }

          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="referred_by">How did you hear about ClassHook? *</label>
              <select id="referred_by" className="form-control" value={referredBy} onChange={handleSetReferredBy}>
                {
                  props.userReferredByOptions.map((obj, index) => {
                    return (
                      <option value={obj.value} key={index}>
                        {obj.label}
                      </option>
                    );
                  })
                }
              </select>
            </div>
          </div>

          {referredByDetailsLabel.length > 0 &&
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="referred_by_details">{referredByDetailsLabel}</label>
                <input id="referred_by_details" type="text" className="form-control" value={referredByDetails} onChange={e => setReferredByDetails(e.target.value)} />
              </div>
            </div>
          }

          <div className="form-row">
            <div className="form-group col-md-12">
              <div className="form-check small">
                <input id="terms" type="checkbox" className="form-check-input" checked={terms} onChange={e => setTerms(!terms)} />
                <label htmlFor="terms" className="form-check-label">
                  By checking this box and clicking Create Account, I agree to be bound by the
                  {' '}
                  <a href="/terms_of_use" target="_blank">Terms of Use</a> and
                  {' '}
                  <a href="/privacy_policy" target="_blank">Privacy Policy</a>
                </label>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              {!shouldVerifyEmail &&
                <button type="submit" className="btn btn-primary font-weight-bold w-100" disabled={!isLoading && !isEnabled}>
                  Create Account
                </button>
              }

              {shouldVerifyEmail &&
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  disabled={!isLoading && !isEnabled}
                  onClick={onVerifyEmailClick}
                >
                  Next
                </button>
              }
            </div>
          </div>
        </form>
      }
    </>
  );
}

export function renderChSignUp(elementId, options) {
  const node = ReactDOM.render(
    <ChSignUp
      onSuccess={options.onSuccess}
      userTypeOptions={options.userTypeOptions}
      userReferredByOptions={options.userReferredByOptions}
      shouldVerifyEmail={options.shouldVerifyEmail}
      includeStudentHelpText={options.includeStudentHelpText}
      excludedFields={options.excludedFields}
      userAttrs={options.userAttrs}
    />,
    document.getElementById(elementId)
  );

  return node;
}