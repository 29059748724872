import React, { useRef } from 'react';
import ReactDOM from "react-dom";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';

import { retrieveCsrfToken } from '../global_functions';

interface Props {
  clientId: string;
  buttonText?: 'signin_with' | 'signup_with' | 'continue_with' | 'signin';
  width?: number;
}

export function GoogleSignInButton({ buttonText = 'signin_with', clientId, width = 207 }: Props) {
  const formRef = useRef(null);
  const inputIdTokenRef = useRef(null);

  const onSuccess = (response: CredentialResponse) => {
    const idToken = response.credential;

    if (inputIdTokenRef && inputIdTokenRef.current) {
      inputIdTokenRef.current.value = idToken;
      formRef?.current?.submit();
    }
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <form role="form" action="/google_oauth" method="post" ref={formRef}>
        <input type="hidden" name="authenticity_token" value={retrieveCsrfToken()} />
        <input type="hidden" name="google_user_id_token" ref={inputIdTokenRef} />
      </form>

      <GoogleLogin
        width={width}
        text={buttonText}
        shape="rectangular"
        logo_alignment='center'
        ux_mode='popup'
        login_uri="/google_oauth"
        type='standard'
        onSuccess={onSuccess}
      />
    </GoogleOAuthProvider>
  );
}

export function renderGoogleSignInButton(elementId, options) {
  const node = ReactDOM.render(
    <GoogleSignInButton
      buttonText={options.buttonText}
      clientId={options.clientId}
    />,
    document.getElementById(elementId)
  );

  return node;
}