import React, { useState } from "react";
import ReactDOM from "react-dom";
import sanitizeHtml from "sanitize-html";
import Truncate from "react-truncate";

import { postData, fetchData } from "../../src/global_functions";
import { timestampFromSeconds } from "../../src/time_functions";

import './styles.scss';

interface YouTubeSearchResponse {
  videoData: {
    videos: object;
    nextPageToken: string;
  };
}

interface VideoSearchResult {
  description: string;
  title: string;
  thumbnailUrl: string;
  videoDuration: number;
  videoId: string;
}

async function searchYouTube(searchQuery: string, nextPageToken?: string) {
  // Get the videos
  const { data } = await postData({
    url: "/ch_youtubes/search",
    data: {
      query: searchQuery,
      next_page_token: nextPageToken
    }
  });

  const videoData: YouTubeSearchResponse = { videos: {} };

  data?.items?.forEach(video => {
    videoData['videos'][video.id.videoId] = video;
  });

  videoData['nextPageToken'] = data?.nextPageToken;

  const videoIds = Object.keys(videoData.videos);

  // Get the duration for each video
  if (videoIds.length > 0) {
    const result = await fetchData({
      url: "/ch_youtubes/retrieve_video_info",
      params: {
        part: "id,contentDetails",
        video_ids: videoIds
      }
    });

    // Set the duration for each video previously retrieved
    result?.data?.forEach(video => {
      videoData['videos'][video.id]['contentDetails'] = video.contentDetails;
    });
  }

  return videoData;
}

function YouTubeSearchResult({ description, title, thumbnailUrl, videoDuration, videoId }: VideoSearchResult) {
  const videoLength = timestampFromSeconds(videoDuration, true);
  const displayTitle = sanitizeHtml(title);

  const onAddVideoHandler = (e) => {
    e.preventDefault();

    const inputVideoLength = timestampFromSeconds(videoDuration, false);

    // Populate the other tab on the modal
    $("#user_clip_url").val(`https://www.youtube.com/watch?v=${videoId}`);
    $("#user_clip_end_time").val(inputVideoLength);
    $("#user_clip_title").val(displayTitle);
    $("#user_clip_short_desc").val(description);
    $("#paste-link-tab").tab('show');

    // From app/assets/user_clips.js
    window.displayUserClipPreview(true);
  };

  return (
    <div className="youtube-search-result mx-2 my-3" key={videoId}>
      <div className="thumbnail-container">
        <img className="thumbnail" src={thumbnailUrl} alt={`${displayTitle} thumbnail`} />
        <div className="video-length">{videoLength}</div>
      </div>

      <div className="mt-2 title">
        <Truncate lines={2}>
          <div dangerouslySetInnerHTML={{ __html: displayTitle }} />
        </Truncate>
      </div>

      <div className="text-center">
        <button type="button" className="btn btn-sm btn-link border mt-2 w-50" onClick={onAddVideoHandler}>
          Add Clip
        </button>
      </div>
    </div>
  );
}

function renderVideos(videosObj, loadMoreDisabled = false, onClickHandler: (e) => void) {
  if (!videosObj) return null;

  const theVideos = Object.values(videosObj).map(video => {
    const { description, title, thumbnails } = video.snippet;
    const videoId = video.id.videoId;

    return (
      <YouTubeSearchResult
        key={videoId}
        description={description}
        title={title}
        thumbnailUrl={thumbnails.medium.url}
        videoDuration={video.contentDetails.duration}
        videoId={videoId}
      />
    );
  });

  return (
    <>
      <h4 className="text-center">YouTube search results</h4>

      <div id="youtube-search-results-container" className="youtube-search-results-container">
        <div className="youtube-search-results mt-3">
          {theVideos}
        </div>

        <div className="my-3 text-center">
          <button type="button" className="btn btn-link border w-50" onClick={onClickHandler} disabled={loadMoreDisabled}>
            Load More
          </button>
        </div>
      </div>
    </>
  );
}

function SearchYouTubeForm() {
  const [youtubeResult, setYoutubeResult] = useState<YouTubeSearchResponse>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loadMoreDisabled, setloadMoreDisabled] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const videoData = await searchYouTube(searchQuery, youtubeResult.nextPageToken);

    setYoutubeResult(videoData);
    setDisabled(false);

    document.getElementById("youtube-search-results-container").scrollTop = 0;
  };

  const loadMoreClickHandler = async (e) => {
    e.preventDefault();

    setloadMoreDisabled(true);

    const videoData = await searchYouTube(searchQuery, youtubeResult.nextPageToken);
    const newYoutubeResult = { ...videoData };
    newYoutubeResult['videos'] = { ...youtubeResult.videos, ...videoData.videos };

    setYoutubeResult(newYoutubeResult);
    setloadMoreDisabled(false);
  };

  const videosObj = youtubeResult?.videos;

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="d-flex mt-3">
          <div className="flex-grow-1 mb-3 mr-4">
            <input type="text" className="form-control" placeholder="Search YouTube" aria-label="Search YouTube" value={searchQuery} onChange={onChange} />
          </div>

          <button type="submit" className="btn btn-primary mb-3" disabled={disabled}>
            Search
          </button>
        </div>
      </form>

      {renderVideos(videosObj, loadMoreDisabled, loadMoreClickHandler)}
    </>
  );
}

export function renderUserClipsSearchYouTubeForm(elementId) {
  const node = ReactDOM.render(
    <SearchYouTubeForm />,
    document.getElementById(elementId)
  );

  return node;
}