import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";

import { patchData, postData } from "../global_functions";
import { Organization, User } from "../../types";

import { ChSignIn } from "../sessions/signin";
import { ChSignUp } from "../users/signup";
import SetupOrganizationForm from "../organizations/setup_organization_form";
import FormErrorsList from "../shared_components/form_errors_list";

interface Props {
  user?: User;
  userTypes: Array<Array<string>>;
  userReferredByValues: Array<string>;
  awsCustomerId: string;
}

enum SETUP_MODE {
  SignIn = 1,
  SignUp,
  OrganizationSetup,
  NewOrganizationSetup,
  SetupSuccess
}

function getStartingMode(user: User) {
  if (user && user.organization) {
    return SETUP_MODE.OrganizationSetup;
  } else if (user && !user.organization) {
    return SETUP_MODE.NewOrganizationSetup;
  } else if (!user) {
    return SETUP_MODE.SignIn;
  }
}

function AwsPurchaseSetup({ user, userTypes, userReferredByValues, awsCustomerId }: Props) {
  const [loggedInUser, setLoggedInUser] = useState<User>(user);
  const [mode, setMode] = useState<SETUP_MODE>(getStartingMode(user));
  const [organization, setOrganization] = useState<Organization>();

  const [orgButtonsLoading, setOrgButtonsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const userTypeOptions = userTypes.map(ary => ({ label: ary[0], value: ary[1] }));
  const userReferredByOptions = userReferredByValues.map(value => ({ label: value, value: value }));

  useEffect(() => {
    if (!loggedInUser) return;

    const updateAwsCustomer = async () => {
      try {
        await patchData({
          url: "/aws_marketplace/update_aws_customer",
          data: {
            customer_id: awsCustomerId,
            user_id: loggedInUser.id
          }
        });
      } catch (e) {
        // Do nothing - customer was already updated
      }
    };

    updateAwsCustomer();
  }, [loggedInUser]);

  const handleModeChange = (e, mode: SETUP_MODE) => {
    e.preventDefault();
    setMode(mode);
  };

  const signInSuccess = (user: User, _: string) => {
    setLoggedInUser(user);
    setMode(SETUP_MODE.OrganizationSetup);
  };

  const handleUseExistingOrganization = async (e) => {
    setOrgButtonsLoading(true);

    try {
      const { data } = await postData({
        url: "/aws_marketplace/setup_organization",
        data: { organization_id: loggedInUser.organization.id }
      });

      onOrganizationSetupSuccess(data);
    } catch (e) {
      setErrors(e.response.data.errors);
    } finally {
      setOrgButtonsLoading(false);
    }
  };

  const onOrganizationSetupSuccess = (data) => {
    setOrganization(data.organization);
    setMode(SETUP_MODE.SetupSuccess);
  };

  return (
    <>
      <FormErrorsList errors={errors} />

      {!loggedInUser &&
        <>
          <h2 className="text-center mb-5">Set Up Your ClassHook Account</h2>

          {mode === SETUP_MODE.SignIn &&
            <>
              <div className="mb-3">
                New to ClassHook?
                {' '}
                <a href="#" onClick={e => handleModeChange(e, SETUP_MODE.SignUp)}>Sign up today!</a>
              </div>

              <ChSignIn onSuccess={(theUser) => signInSuccess(theUser, "/")} />
            </>
          }

          {mode === SETUP_MODE.SignUp &&
            <>
              <div className="mb-3">
                Already have an account?
                {' '}
                <a href="#" onClick={e => handleModeChange(e, SETUP_MODE.SignIn)}>Sign in</a>
              </div>

              <ChSignUp
                onSuccess={(theUser, redirectUrl) => signInSuccess(theUser, redirectUrl)}
                userTypeOptions={userTypeOptions}
                userReferredByOptions={userReferredByOptions}
                shouldVerifyEmail={true}
              />
            </>
          }
        </>
      }

      {loggedInUser &&
        <>
          {(loggedInUser.organization && (mode === SETUP_MODE.OrganizationSetup)) &&
            <>
              <h3 className="mb-5">Use an existing organization or set up a new one?</h3>

              <div>
                <div className="text-center">
                  <Button type="button" variant="primary" size="lg" disabled={orgButtonsLoading} onClick={handleUseExistingOrganization}>
                    Use existing organization below
                  </Button>
                </div>

                <div className="text-center my-4">
                  <h4>{loggedInUser.organization.name}</h4>
                  <div>{loggedInUser.organization.address}</div>
                  <div>{loggedInUser.organization.city}, {loggedInUser.organization.state}</div>
                  <div>{loggedInUser.organization.country}</div>
                </div>

                <hr />

                <div className="text-center">
                  <Button type="button" variant="primary" size="lg" disabled={orgButtonsLoading} onClick={() => setMode(SETUP_MODE.NewOrganizationSetup)}>
                    Set up new organization
                  </Button>
                </div>
              </div>
            </>
          }

          {((!loggedInUser.organization || mode === SETUP_MODE.NewOrganizationSetup) && mode !== SETUP_MODE.SetupSuccess) &&
            <>
              <h2 className="text-center mb-5">Set up your organization</h2>

              {loggedInUser.organization &&
                <div className="small">
                  <Button type="button" variant="link" onClick={() => setMode(SETUP_MODE.OrganizationSetup)}>
                    ← Back
                  </Button>
                </div>
              }

              <SetupOrganizationForm
                onOrganizationSetupSuccess={onOrganizationSetupSuccess}
                postUrl="/aws_marketplace/setup_organization"
              />
            </>
          }
        </>
      }

      {(mode === SETUP_MODE.SetupSuccess) &&
        <div className="text-center">
          <h2 className="mb-5">Your organization has been set up successfully! 🎉</h2>

          <a href={`/organizations/${organization.id}`} className="btn btn-lg btn-primary">
            Invite your team to ClassHook
          </a>
        </div>
      }
    </>
  );
}

export function renderAwsPurchaseSetup(elementId, options) {
  const node = ReactDOM.render(
    <AwsPurchaseSetup
      awsCustomerId={options.awsCustomerId}
      user={options.user}
      userReferredByValues={options.userReferredByValues}
      userTypes={options.userTypes}
    />,
    document.getElementById(elementId)
  );

  return node;
}