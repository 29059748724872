import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stepper, Step } from 'react-form-stepper';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { validate } from 'email-validator';

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";
import { getCountrySelectOptions } from "../../lib/countries_helper";
import { formatCurrency } from "../currency_helper";
import { Quote } from "../../types";

import styles from "./order_quote_form.module.scss";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  quote: Quote;
}

const STEPS = [
  { label: "Plan" },
  { label: "Billing" },
  { label: "Payment Method" },
  { label: "License Holder" },
  { label: "Confirmation" }
];

const STEP_HEADERS = [
  "Select Your Plan",
  "Enter Your Billing Information",
  "What is your payment method?",
  "Set Up Your License Holder",
  "Confirm Details"
];

function OrderQuoteForm({ quote }: Props) {
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [state, setState] = useState({
    currentStep: 0,
    organization: quote.organization,
    quoteTiming: 'today',
    quoteStartDate: new Date(),
    firstName: quote.first_name,
    lastName: quote.last_name,
    email: quote.email,
    phone: '',
    billing1: '',
    billing2: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'United States of America',
    purchaseOrderFile: null,
    purchaseOrderNumber: null,
    licenseHolderName: '',
    licenseHolderEmail: '',
    acceptedTerms: false
  });

  const fileInputRef = useRef<HTMLInputElement>();

  const incrementStep = () => {
    if (state.currentStep >= STEPS.length) return;

    setState({ ...state, currentStep: state.currentStep + 1 });
  };

  const decrementStep = () => {
    if (state.currentStep < 1) return;

    setState({ ...state, currentStep: state.currentStep - 1 });
  };

  const onClickStep = (stepNum) => {
    if (stepNum <= state.currentStep) {
      setState({ ...state, currentStep: stepNum });
    }
  };

  const onSubmitFile = (e) => {
    setState({ ...state, purchaseOrderFile: e.target.files[0] });
  };

  const onClearFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setState({ ...state, purchaseOrderFile: null, purchaseOrderNumber: null });
  };

  const onSubmitOrder = async (e) => {
    if (!state.acceptedTerms) return;

    setIsSubmitting(true);

    // Submit a purchase order
    let headers = {};
    let formData: any = {
      file: state.purchaseOrderFile,
      po_number: state.purchaseOrderNumber || "other_payment_method",
      quote_id: quote.id,
      organization_name: state.organization,
      address1: state.billing1,
      address2: state.billing2,
      city: state.city,
      state: state.state,
      postal_code: state.zipCode,
      country: state.country,
      invoicing_name: `${state.firstName} ${state.lastName}`.trim(),
      invoicing_email: state.email,
      license_holder_name: state.licenseHolderName,
      license_holder_email: state.licenseHolderEmail,
      license_start_date: state.quoteStartDate
    };

    try {
      if (state.purchaseOrderFile) {
        // Send multi-part data
        const newFormData = new FormData();

        Object.entries(formData).forEach(([key, value]) => {
          newFormData.append(`purchase_order[${key}]`, (value as any));
        });

        formData = newFormData;
        headers['Content-Type'] = 'multipart/form-data';
      } else {
        formData = { purchase_order: formData };
      }

      const { data } = await postData({
        url: "/purchase_orders",
        data: formData,
        headers: headers
      });

      // Smoothly scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });

      setHasSubmitted(true);
      setIsSubmitting(false);
    } catch (e) {
      setErrors(e.response.data.errors);
    }
  };

  const stepsButtonContainerClass = (state.currentStep > 0) ? "justify-content-between" : "justify-content-end";

  let stepToRender;
  let nextButtonEnabled;
  let nextButtonText = "Next";

  switch (state.currentStep) {
    case 0:
      stepToRender = Step1(quote, state, setState);
      nextButtonEnabled = (state.organization.length > 0);
      break;
    case 1:
      stepToRender = Step2(quote, state, setState);
      nextButtonEnabled = state.firstName.length > 0 &&
        state.lastName.length > 0 &&
        state.email.length > 0 &&
        validate(state.email) &&
        state.billing1.length > 0 &&
        state.city.length > 0 &&
        state.state.length > 0 &&
        state.zipCode.length > 0 &&
        state.country.length > 0
        ;
      break;
    case 2:
      stepToRender = Step3(quote, state, setState);
      nextButtonEnabled = !state.purchaseOrderFile || (!!state.purchaseOrderFile && state.purchaseOrderNumber);

      if (!state.purchaseOrderFile) {
        nextButtonText = "Continue with different payment method";
      }

      break;
    case 3:
      stepToRender = Step4(quote, state, setState);
      nextButtonEnabled = state.licenseHolderName.length > 0 &&
        state.licenseHolderEmail.length > 0 &&
        validate(state.licenseHolderEmail)
        ;
      break;
    case 4:
      stepToRender = Step5(quote, state, setState);
      break;
  }

  if (hasSubmitted) {
    return orderConfirmationPage(quote, state);
  }

  return (
    <div className="p-2">
      <h2 className="text-center mb-4">{STEP_HEADERS[state.currentStep]}</h2>

      <Stepper activeStep={state.currentStep} styleConfig={{ activeBgColor: "#F05A28", completedBgColor: "#F05A28" }}>
        {STEPS.map((stepObj, index) => {
          const isCompleted = index < state.currentStep;
          const stepNumLabel = isCompleted ? <FontAwesomeIcon icon="check" /> : (index + 1);

          return (
            <Step
              key={index}
              label={stepObj.label}
              index={index}
              active={(index === state.currentStep)}
              completed={isCompleted}
              onClick={() => onClickStep(index)}
              children={
                (<span>{stepNumLabel}</span>)
              }
            />
          );
        })}
      </Stepper>

      <hr />

      {/* Persist the file input but keep it hidden unless it's the right step */}
      <div className={`${state.currentStep === 2 ? '' : 'd-none'}`}>
        <Form>
          <div className="form-row">
            <Form.Group controlId="quote_purchase_order" className="col-md-12">
              <Form.Label>Are you purchasing with a purchase order?</Form.Label>
              <Form.Control type="file" onChange={onSubmitFile} ref={fileInputRef} />
            </Form.Group>
          </div>

          {state.purchaseOrderFile &&
            <>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <button type="button" className="btn btn-sm btn-link" onClick={onClearFile}>
                    Remove File
                  </button>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <Form.Label htmlFor="quote_purchase_order_number">Purchase Order Number</Form.Label>
                  <Form.Control id="quote_purchase_order_number" type="text" onChange={e => setState({ ...state, purchaseOrderNumber: e.target.value })} />
                </div>
              </div>
            </>
          }
        </Form>
      </div>

      <FormErrorsList errors={errors} />

      {stepToRender}

      <hr />

      {state.currentStep < (STEPS.length - 1) &&
        <div className={`d-flex ${stepsButtonContainerClass}`}>
          {state.currentStep > 0 &&
            <Button type="button" variant="outline-secondary" onClick={decrementStep}>
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              &nbsp;
              <span>Previous</span>
            </Button>
          }

          <Button type="button" variant="primary" onClick={incrementStep} disabled={!nextButtonEnabled}>
            <span>{nextButtonText}</span>
            &nbsp;
            &nbsp;
            <FontAwesomeIcon icon="arrow-right" />
          </Button>
        </div>
      }

      {state.currentStep >= (STEPS.length - 1) &&
        <button type="button" className="btn btn-lg btn-success w-100" onClick={onSubmitOrder} disabled={!state.acceptedTerms || isSubmitting}>
          Submit
        </button>
      }
    </div>
  );
}

function Step1(quote: Quote, state, setState) {
  return (
    <>
      <Form>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Label htmlFor="quote_organization">School, District or Organization Name</Form.Label>
            <Form.Control id="quote_organization" type="text" defaultValue={state.organization} onChange={e => setState({ ...state, organization: e.target.value })} />
          </div>
        </div>

        <h4 className="my-3">Plan Details</h4>

        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Item</th>
                <th>Term</th>
                <th>Number of Schools</th>
                <th>Price Each</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>ClassHook Premium</td>
                <td>12 months</td>
                <td>{quote.num_sites}</td>
                <td>{formatCurrency(quote.price_per_site, 2)}</td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td className="text-right font-weight-bold">Total:</td>
                <td>
                  {formatCurrency((quote.price_in_cents / 100), 2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="border rounded px-3 py-2">
          <p className="font-weight-bold">Activate my subscription:</p>

          <Form.Check
            id="quote_activate_today"
            type="radio"
            name="quote_activation_time"
            value="today"
            checked={state.quoteTiming === 'today'}
            label="Today"
            onChange={e => setState({ ...state, quoteTiming: e.target.value, quoteStartDate: new Date() })}
          />

          <div className="d-flex">
            <Form.Check
              id="quote_activate_later"
              type="radio"
              name="quote_activation_time"
              value="future"
              checked={state.quoteTiming === 'future'}
              label="On a future date"
              onChange={e => setState({ ...state, quoteTiming: e.target.value })}
              className="mr-2"
            />

            {(state.quoteTiming === "future") &&
              <DatePicker
                startDate={new Date()}
                allowSameDay={true}
                selected={state.quoteStartDate}
                onChange={(date) => setState({ ...state, quoteStartDate: date })}
                wrapperClassName={styles.datepicker_wrapper}
              />
            }
          </div>

          <div className="alert alert-info p-2 my-2 mb-0">
            <FontAwesomeIcon icon="info-circle" />
            &nbsp;
            Payment is due 45 days after your activation date.
          </div>
        </div>
      </Form>
    </>
  );
}

function Step2(quote, state, setState) {
  return (
    <>
      <h4 className="my-3 text-center">Who is paying the invoice?</h4>

      <Form>
        <div className="form-row">
          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_first_name">First Name</Form.Label>
            <Form.Control id="quote_first_name" type="text" defaultValue={state.firstName} onChange={e => setState({ ...state, firstName: e.target.value })} />
          </div>

          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_last_name">Last Name</Form.Label>
            <Form.Control id="quote_last_name" type="text" defaultValue={state.lastName} onChange={e => setState({ ...state, lastName: e.target.value })} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_email">Email</Form.Label>
            <Form.Control id="quote_email" type="text" defaultValue={state.email} onChange={e => setState({ ...state, email: e.target.value })} />
          </div>

          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_phone">Phone (optional)</Form.Label>
            <Form.Control id="quote_phone" type="text" defaultValue={state.phone} onChange={e => setState({ ...state, phone: e.target.value })} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Label htmlFor="quote_billing1">Billing Address</Form.Label>
            <Form.Control id="quote_billing1" type="text" defaultValue={state.billing1} placeholder="Line 1" onChange={e => setState({ ...state, billing1: e.target.value })} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Control id="quote_billing2" type="text" defaultValue={state.billing2} placeholder="Line 2" onChange={e => setState({ ...state, billing2: e.target.value })} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-5">
            <Form.Label htmlFor="quote_city">City</Form.Label>
            <Form.Control id="quote_city" type="text" defaultValue={state.city} onChange={e => setState({ ...state, city: e.target.value })} />
          </div>

          <div className="form-group col-md-5">
            <Form.Label htmlFor="quote_state">State/Province</Form.Label>
            <Form.Control id="quote_state" type="text" defaultValue={state.state} onChange={e => setState({ ...state, state: e.target.value })} />
          </div>

          <div className="form-group col-md-2">
            <Form.Label htmlFor="quote_zip_code">Zip Code</Form.Label>
            <Form.Control id="quote_zip_code" type="text" defaultValue={state.zipCode} onChange={e => setState({ ...state, zipCode: e.target.value })} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Label htmlFor="quote_country">Country</Form.Label>
            <Select
              id="quote_country"
              options={getCountrySelectOptions()}
              closeMenuOnSelect={true}
              defaultValue={{ label: "United States of America", value: "United States of America" }}
              onChange={option => setState({ ...state, country: option.value })}
            />
          </div>
        </div>
      </Form>
    </>
  );
}

function Step3(quote: Quote, state, setState) {
  // Handled in the main component since it's just a file input that needs to persist
  return (
    <></>
  );
}

function Step4(quote: Quote, state, setState) {
  return (
    <>
      <h4 className="my-3 text-center">Who will be managing ClassHook for your organization?</h4>

      <Form>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Label htmlFor="quote_license_holder_name">License Holder Name</Form.Label>
            <Form.Control id="quote_license_holder_name" type="text" defaultValue={state.licenseHolderName} onChange={e => setState({ ...state, licenseHolderName: e.target.value })} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Label htmlFor="quote_license_holder_email">License Holder Email</Form.Label>
            <Form.Control id="quote_license_holder_email" type="email" defaultValue={state.licenseHolderEmail} onChange={e => setState({ ...state, licenseHolderEmail: e.target.value })} />
          </div>
        </div>
      </Form>
    </>
  );
}

function step5Values(quote: Quote, state) {
  return [
    {
      "Organization Name": state.organization,
      "Plan Name": "ClassHook Premium",
      "Term": "12 months",
      "Number of Schools": quote.num_sites,
      "Price Each": formatCurrency(quote.price_per_site, 2),
      "Subtotal": formatCurrency((quote.price_in_cents / 100), 2),
      "Total": formatCurrency((quote.price_in_cents / 100), 2)
    },
    {
      "Contact Name": `${state.firstName} ${state.lastName}`,
      "Contact Email": state.email,
      "Address Line 1": state.billing1,
      "Address Line 2": state.billing2,
      "City": state.city,
      "State/Province": state.state,
      "Zip Code": state.zipCode,
      "Country": state.country
    },
    {
      "Uploaded purchase order?": !!state.purchaseOrderFile ? "Yes" : "No",
      "Purchase Order Number": !!state.purchaseOrderNumber ? state.purchaseOrderNumber : "None"
    },
    {
      "Name": state.licenseHolderName,
      "Email": state.licenseHolderEmail
    }
  ];
}

function Step5(quote: Quote, state, setState) {
  const stepValues = step5Values(quote, state);

  return (
    <>
      {stepValues.map((valueObj, index) => (
        <div key={index}>
          <div className="d-flex mt-3">
            <h3 className="mb-0">{STEPS[index].label}</h3>
            <button type="button" className="btn btn-link" onClick={() => setState({ ...state, currentStep: index })}>
              <FontAwesomeIcon icon="edit" />
              <span className="sr-only">Edit</span>
            </button>
          </div>

          <hr className="my-2" />

          {Object.entries(valueObj).map(([key, value]) => (
            <div className="row" key={key}>
              <div className="col-md-6 font-weight-bold">{key}</div>
              <div className="col-md-6">{value}</div>
            </div>
          ))}
        </div>
      ))}

      <div className="mt-3">
        <h3 className="mb-0">Terms</h3>

        <hr className="my-2" />

        <p>
          Upon signature by Customer and submission to ClassHook, this Order Form is legally binding unless this Order Form
          is rejected by ClassHook for any of the following:
          (1) the signatory below does not have the authority to bind Customer to this Order Form,
          (2) the requested order information or signature is incomplete or does not match our records or the rest of this Order Form.
          Orders are non-refundable. By checking this box and clicking Submit, I agree to be bound by the
          &nbsp;
          <a href="/terms_of_use" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
        </p>

        <Form.Check
          id="quote_accept_terms"
          type="checkbox"
          checked={state.acceptedTerms}
          label="I accept the terms"
          onChange={e => setState({ ...state, acceptedTerms: !state.acceptedTerms })}
        />
      </div>
    </>
  );
}

function orderConfirmationPage(quote: Quote, state) {
  const stepValues = step5Values(quote, state);

  return (
    <div className="p-2">
      <h2 className="text-center mb-4">Thank You for Your Order</h2>

      <div className="mb-5">
        <p>
          Your order has been confirmed! We will review it within 48 hours.
          Your order details are listed below.
        </p>

        <p>
          The listed billing contact will receive a confirmation email, and
          the listed license holder will receive an email to set up your organization.
        </p>
      </div>

      {stepValues.map((valueObj, index) => (
        <div key={index}>
          <div className="mt-3">
            <h3 className="mb-0">{STEPS[index].label}</h3>
          </div>

          <hr className="my-2" />

          {Object.entries(valueObj).map(([key, value]) => (
            <div className="row" key={key}>
              <div className="col-md-6 font-weight-bold">{key}</div>
              <div className="col-md-6">{value}</div>
            </div>
          ))}
        </div>
      ))}

      <div className="text-center mt-5">
        <a href="/" className="btn btn-lg btn-primary w-100">
          Return to home
        </a>
      </div>
    </div>
  );
}

export function renderOrderQuoteForm(elementId, options) {
  const node = ReactDOM.render(
    <OrderQuoteForm
      quote={options.quote}
    />,
    document.getElementById(elementId)
  );

  return node;
}