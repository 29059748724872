import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { DateTime } from "luxon";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";

interface Props {
  course: {
    id: number;
    name: string;
    period?: string;
    year?: string;
  },
  classNames?: string;
}

function DuplicateCourseButton({ course, classNames = "btn btn-primary" }: Props) {
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [shown, setShown] = useState(false);
  const [name, setName] = useState(`${course.name} copy`);
  const [period, setPeriod] = useState(course.period);
  const [year, setYear] = useState(DateTime.now().year.toString());
  const [copyTeachers, setCopyTeachers] = useState(false);
  const [copyStudents, setCopyStudents] = useState(false);
  const [copyAssignments, setCopyAssignments] = useState(false);

  const handleShow = () => setShown(true);
  const handleClose = () => setShown(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setDisabled(true);

    const params = {
      course: {
        name: name,
        period: period,
        year: year
      }
    };

    if (copyTeachers) params["copy_teachers"] = "1";
    if (copyStudents) params["copy_students"] = "1";
    if (copyAssignments) params["copy_assignments"] = "1";

    try {
      await postData({
        url: `/courses/${course.id}/duplicate`,
        data: params
      });

      setShown(false);

      // On success, reload the page
      window.location.reload();
    } catch (e) {
      setErrors(e.response.data.errors);
    }

    setDisabled(false);
  };

  return (
    <>
      <button type="button" className={classNames} onClick={handleShow}>
        Make a Copy
      </button>

      <Modal show={shown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate This Course</Modal.Title>
        </Modal.Header>

        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errors && <FormErrorsList errors={errors} />}

            <Form.Group>
              <Form.Label>New Course Name</Form.Label>
              <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <div className="form-row">
              <Form.Group className="col-md-6">
                <Form.Label>Class Period</Form.Label>
                <Form.Control value={period} onChange={(e) => setPeriod(e.target.value)} />
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label>Year</Form.Label>
                <Form.Control value={year} onChange={(e) => setYear(e.target.value)} />
              </Form.Group>
            </div>

            <hr />

            <div className="mb-3">
              <Form.Check
                inline
                type="checkbox"
                label="Copy Teachers"
                id="course_copy_teachers"
                onChange={e => setCopyTeachers(e.target.checked)}
              />

              <Form.Check
                inline
                type="checkbox"
                label="Copy Students"
                id="course_copy_students"
                onChange={e => setCopyStudents(e.target.checked)}
              />

              <Form.Check
                inline
                type="checkbox"
                label="Copy Assignments"
                id="course_copy_assignments"
                onChange={e => setCopyAssignments(e.target.checked)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer className="justify-content-center px-5">
            <Button type="submit" variant="primary" className="px-3" aria-label="Copy to New Course" disabled={disabled}>
              Copy Course
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export function renderDuplicateCourseButton(elementId, options) {
  const node = ReactDOM.render(
    <DuplicateCourseButton
      course={options.course}
      classNames={options.classNames}
    />,
    document.getElementById(elementId)
  );

  return node;
}