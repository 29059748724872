import React, { useState } from "react";
import ResourceThumbnail from "./resource_thumbnail";
import DisplayTag from "./display_tag";
import DisplaySubject from "./display_subject";
import DisplayAgeRange from "./display_age_range";

import { AgeRange, Tag } from "../../types";

interface ResourceObj {
  resource: object;
  resource_subjects: Array<object>;
  subject_tags: Array<Tag>;
  age_ranges: Array<AgeRange>;
  title: string;
  url: string;
}

interface Props {
  resourceObj: ResourceObj;
  thumbnailClasses?: string;
}

export default function DisplayResourceFlex({ resourceObj, thumbnailClasses }: Props) {
  return (
    <div className="resource-container">
      <div className="resource-thumbnail-container">
        <ResourceThumbnail resourceObj={resourceObj} extraClasses={thumbnailClasses} />
      </div>

      <div className="resource-title">
        <a href={resourceObj.url}>{resourceObj.title}</a>
      </div>

      <div>
        <DisplayAgeRange ageRanges={resourceObj.age_ranges} size="sm" />
      </div>

      <div className="resource-tag">
        <div className="d-flex flex-wrap mt-2">
          {
            resourceObj.resource_subjects.map((subjectObj, index) => (
              <div className="mb-2 mr-2" key={index}>
                <DisplaySubject subjectObj={subjectObj} />
              </div>
            ))
          }

          {
            resourceObj.subject_tags.map((tag, index) => (
              <div className="mb-2 mr-2" key={index}>
                <DisplayTag tag={tag} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
