import React, { useEffect, useState } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row, } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";
import ReactHtmlParser from 'react-html-parser';
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";

import FormErrorsList from "../shared_components/form_errors_list";
import ClipQueue from "./clip_queue";
import DiscussionContext from "./discussion_context";
import { deleteData, fetchData, patchData, postData } from "../global_functions";
import { renderVideoPlayer } from "../video_player/video_player";
import { markersFromPausePrompts } from "../video_player/functions";
import { VideoPausePromptService } from "../video_player/video_pause_prompt_service";
import { TINYMCE_INITIAL_SETTINGS } from "../tinymce_helper";
import { timestampFromSeconds } from "../time_functions";
import {
  createVideoPlayerResource,
  fetchPausePrompts,
  fetchExistingVideoPausePrompts,
  buildVideoIdParams
} from "./discussion_helper";
import StartDiscussionModal from "./start_discussion_modal";
import DiscussionSettingsModal from "./discussion_settings_modal";
import AddPromptButton from "../pause_prompts/add_prompt_button";
import UpgradeButton from "../shared_components/upgrade_button";
import { chAnalyticsTrackEvent } from "../analytics";

import "./discussion.scss";

interface QuestionProps {
  question: {
    id: number;
    prompt: string;
    start_time: number;
    pause_duration: number;
    auto_resume: boolean;
    is_public: boolean;
  }
  refreshCurrentVideoQuestions: () => void;
  setQuestionFormState: (any) => void;
  setSeekTime: (any) => void;
  setDiscussionContainerErrors: (any) => void;
}

function Question({
  question: { id, prompt, start_time, pause_duration, auto_resume, is_public },
  refreshCurrentVideoQuestions,
  setQuestionFormState,
  setSeekTime,
  setDiscussionContainerErrors
}: QuestionProps) {
  const handleEdit = (e) => {
    e.preventDefault();
    const formState = convertPausePromptToQuestionFormState(
      id,
      prompt,
      start_time,
      pause_duration,
      auto_resume,
      is_public
    );
    setSeekTime(start_time);
    setQuestionFormState(formState);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const response = await deleteData({ url: `/pause_prompts/${id}` });
      if (response.status === 200) {
        setDiscussionContainerErrors(null);
        refreshCurrentVideoQuestions();
      }
    } catch (err) {
      setDiscussionContainerErrors(err.response.data.errors);
    }
  };

  return (
    <div className="d-flex m-2 mb-4">
      <div className="d-flex border flex-grow-1 p-1">
        <div>{ReactHtmlParser(sanitizeHtml(prompt))}</div>

        <div className="d-flex flex-column flex-shrink-0 ml-auto p-2">
          <div className="d-flex justify-content-between">
            <FontAwesomeIcon icon="clock" size="lg" className="mr-2" title="Question Start Time" />
            <span>{timestampFromSeconds(start_time)}</span>
            <span className="sr-only">Question Start Time</span>
          </div>

          <div className="d-flex justify-content-between">
            <FontAwesomeIcon icon="hourglass" size="lg" className="mr-2" title="Question Duration" />
            <span>{timestampFromSeconds(pause_duration)}</span>
            <span className="sr-only">Question Duration</span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-around pl-2">
        <Button onClick={handleEdit} variant="link" size="sm">
          <FontAwesomeIcon icon="edit" size="lg" />
        </Button>

        <Button onClick={handleDelete} variant="link" size="sm" className="text-red">
          <FontAwesomeIcon icon="trash" size="lg" />
        </Button>
      </div>
    </div>
  );
}

function CreateDiscussionEmptyState() {
  return (
    <div>
      <p>Get started by adding or editing a clip in your queue.</p>
      <p>How it works:</p>

      <ol>
        <li>Add clips to your queue.</li>
        <li>Create questions for each clip.</li>
        <li>Start your discussion and share the code with students.</li>
      </ol>
    </div>
  );
}

function convertPausePromptToQuestionFormState(
  id: number,
  prompt: string,
  start_time: number,
  pause_duration: number,
  auto_resume: boolean,
  is_public: boolean,
) {
  return {
    id,
    start_time,
    prompt,
    pause_duration_minutes: String(Math.floor(pause_duration / 60)),
    pause_duration_seconds: String(Math.floor(pause_duration % 60)),
    auto_resume: auto_resume ? "1" : "0",
    public: is_public ? "1" : "0"
  }
}

interface QuestionFormQuestion {
  id: number;
  prompt: string;
  auto_resume: string;
  pause_duration_minutes: string;
  pause_duration_seconds: string;
  public: string;
  plaintext_prompt?: string;
};

interface QuestionFormProps {
  currentTime: number;
  promptable_type: string;
  promptable_id: string;
  refreshCurrentVideoQuestions: () => void;
  cancelAddQuestion: () => void;
  pausePromptService?: VideoPausePromptService;
  existingVideoQuestions?: Array<QuestionFormQuestion>;
  existingQuestion: QuestionFormQuestion;
}

function QuestionForm({ currentTime, promptable_type, promptable_id, existingVideoQuestions, refreshCurrentVideoQuestions, cancelAddQuestion, existingQuestion, pausePromptService }: QuestionFormProps) {
  const [formState, setFormState] = useState({
    promptable_type,
    promptable_id,
    prompt: "",
    auto_resume: "1",
    pause_duration_minutes: "1",
    pause_duration_seconds: "0",
    public: "1"
  });

  const [errors, setErrors] = useState(null);

  const isNewQuestion = Object.keys(existingQuestion).length === 0;
  const existingQuestionsOptions = existingVideoQuestions.map(question => ({
    value: question.id,
    label: `${timestampFromSeconds(question.start_time)} | ${question.plaintext_prompt}`
  }));

  useEffect(() => {
    // disable rendering pause prompts when this form is open
    pausePromptService?.toggleEnabled(false);
  });

  useEffect(() => {
    setFormState({
      ...formState,
      ...existingQuestion
    });
  }, [existingQuestion]);

  const handleEditorChange = (content, editor) => {
    setFormState({
      ...formState,
      prompt: content
    });
  };

  const onChangeFactory = (fieldName) => (e) => {
    setFormState({
      ...formState,
      [fieldName]: e.target.value
    });
  };

  const onSelectExistingQuestion = (e) => {
    const questionId = e.value;
    const selectedQuestion = existingVideoQuestions.find(theQuestion => theQuestion.id === questionId);

    if (!selectedQuestion) return;

    const selectedQuestionState = convertPausePromptToQuestionFormState(
      selectedQuestion.id,
      selectedQuestion.prompt,
      selectedQuestion.start_time,
      selectedQuestion.pause_duration,
      selectedQuestion.auto_resume,
      selectedQuestion.is_public
    );

    setFormState({
      ...formState,
      ...selectedQuestionState
    });

    return true;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const requestData = {
      pause_prompt: {
        ...formState,
        start_time: currentTime
      }
    };

    try {
      let response;

      if (isNewQuestion) {
        response = await postData({ url: "/pause_prompts", data: requestData });
      } else {
        response = await patchData({ url: `/pause_prompts/${existingQuestion.id}`, data: requestData });
      }

      if (response.status === 200) {
        refreshCurrentVideoQuestions();
        cancelAddQuestion();
      }
    } catch (err) {
      setErrors(err.response.data.errors);
    }
  };

  return (
    <div className="p-3 pb-2 border rounded bg-white">
      {errors &&
        <FormErrorsList errors={errors} />
      }

      <h5 className="mt-0">
        {isNewQuestion ? "Creating" : "Editing"} question at
        <span id="pause-prompt-display-start-time"> {timestampFromSeconds(currentTime)}</span>
      </h5>

      {!isNewQuestion && (
        <p id="pause_prompt_reset_start_time" className="text-muted">
          Original prompt start time: {timestampFromSeconds(existingQuestion.start_time)}
        </p>
      )}

      {isNewQuestion &&
        <div className="my-3">
          <Select
            onChange={onSelectExistingQuestion}
            options={existingQuestionsOptions}
            isDisabled={existingVideoQuestions?.length == 0}
            placeholder={existingVideoQuestions?.length == 0 ? "No existing questions available..." : "Use existing question..."}
            controlShouldRenderValue={false}
            noOptionsMessage="No existing questions available..."
            className="existing-question-select"
          />
        </div>
      }

      <Form>
        <Form.Label>Your Prompt</Form.Label>
        <Editor
          init={TINYMCE_INITIAL_SETTINGS}
          value={formState.prompt}
          onEditorChange={handleEditorChange}
        />

        <Form.Row className="mt-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label>Timed?</Form.Label>
              <Form.Control as="select"
                value={formState.auto_resume}
                onChange={onChangeFactory("auto_resume")}>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label>Pause Length</Form.Label>
              <Row>
                <Col md={6}>
                  <Form.Control type="number" min="0" max="59" step="1"
                    value={formState.pause_duration_minutes} onChange={onChangeFactory("pause_duration_minutes")}
                    disabled={formState.auto_resume === "0"} />
                  <span id="pause_duration_minutes_help_block" className="form-text text-right">minutes</span>
                </Col>
                <Col md={6}>
                  <Form.Control type="number" min="0" max="59" step="1"
                    value={formState.pause_duration_seconds} onChange={onChangeFactory("pause_duration_seconds")}
                    disabled={formState.auto_resume === "0"} />
                  <span id="pause_duration_seconds_help_block" className="form-text text-right">seconds</span>
                </Col>
              </Row>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Label>Privacy</Form.Label>
              <Form.Control as="select"
                value={formState.public}
                onChange={onChangeFactory("public")}>
                <option value="1">Public</option>
                <option value="0">Private</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>

        <Button variant="primary" type="submit" onClick={handleSave}>
          {isNewQuestion ? "Add Prompt" : "Save Changes"}
        </Button>

        <Button variant="link" type="button" onClick={cancelAddQuestion}>
          Cancel
        </Button>
      </Form>
    </div>
  );
}

interface Props {
  discussionRoomId: number;
  isPaidOrTrialUser: boolean;
  userId: string;
}

function DiscussionContainer({ discussionRoomId, isPaidOrTrialUser, userId }: Props) {
  // for the discussion room
  const [discussionRoom, setDiscussionRoom] = useState(null);

  // for video queue
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentPlayerRef, setCurrentPlayerRef] = useState(null);
  const [currentPausePromptService, setCurrentPausePromptService] = useState(null);
  const [isUsingEduPlayer, setIsUsingEduPlayer] = useState(window.chUseEduPlayer);

  // for pause prompts
  const [currentTime, setCurrentTime] = useState(0);
  const [questionFormState, setQuestionFormState] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [existingVideoQuestions, setExistingVideoQuestions] = useState([]);
  const [canUserAddQuestion, setCanUserAddQuestion] = useState(true);
  const [seekTime, setSeekTime] = useState(0);
  const [discussionContainerErrors, setDiscussionContainerErrors] = useState(null);

  // for handling modal
  const [activeModalName, setActiveModalName] = useState("");
  const handleClose = () => setActiveModalName("");
  const handleShow = (modalName) => () => setActiveModalName(modalName);

  const contextData = {
    discussionRoom,
    setDiscussionRoom,
    discussionRoomId: discussionRoomId,
    videos,
    currentVideo,
    isPaidOrTrialUser,
    updateQueue: jsonData => {
      const newVideos = jsonData.discussion_queue_video || jsonData.queue_list
      if (newVideos) {
        setVideos(videos.concat(newVideos));
      }
    },
    setQueue: jsonData => {
      const videos = jsonData.queue_list;
      if (videos) {
        setVideos(videos);
      }
    },
    setCurrentVideo,
    setIsUsingEduPlayer,
    modal: {
      activeModalName,
      handleClose,
      handleShow
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await fetchData({ url: `/discussion_rooms/${discussionRoomId}` });
      setDiscussionRoom(data.discussion_room)
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const params = { discussion_room_id: discussionRoomId };
      const { data } = await fetchData({ url: "/discussion_queue_videos", params });
      contextData.updateQueue(data);
    };

    getData();
  }, []);


  const fetchPausePromptsAndSetQuestions = async () => {
    const { data } = await fetchPausePrompts(currentVideo, userId);
    const thePausePrompts = data.pause_prompts;

    setQuestions(thePausePrompts);
    setCanUserAddQuestion(data.can_user_add_pause_prompt);

    // Update the pause prompts on the service
    if (currentPausePromptService) {
      currentPausePromptService.pausePrompts = thePausePrompts;
    }

    // Update the markers on the service
    if (currentPlayerRef && currentPlayerRef.current) {
      currentPlayerRef.current.setMarkers(markersFromPausePrompts(thePausePrompts));
    }

    return thePausePrompts;
  };

  useEffect(() => {
    const getPausePromptsAndRenderPlayer = async () => {
      if (!currentVideo) return;

      // remove old video before appending new one
      const videoContainerId = 'video-container';
      const container = document.getElementById(videoContainerId);

      unmountComponentAtNode(container);

      // reset video specific state
      setQuestionFormState(null);
      setCurrentTime(0);

      const pausePrompts = await fetchPausePromptsAndSetQuestions();

      const pausePromptService = new VideoPausePromptService({
        isHomeworkMode: false,
        isPaidOrTrialUser: isPaidOrTrialUser,
        pausePrompts: pausePrompts,
        mode: "PRESENT",
        pausePromptBackdrop: true
      });

      const playerRef = renderVideoPlayer({
        elementId: 'video-container',
        useEduPlayer: isUsingEduPlayer,
        resource: createVideoPlayerResource(currentVideo.queueable),
        inlinePausePrompt: true,
        onTimeUpdate: (time) => {
          pausePromptService.onTimeUpdate(time);
          setCurrentTime(time);
        },
        onEduPlayerUnavailable: function (thePlayerRef) {
          setIsUsingEduPlayer(false);
        },
        markers: markersFromPausePrompts(pausePrompts)
      });

      pausePromptService.register(playerRef);

      setCurrentPlayerRef(playerRef)
      setCurrentPausePromptService(pausePromptService)
    };
    getPausePromptsAndRenderPlayer();
  }, [currentVideo, isUsingEduPlayer])

  // For loading the existing pause prompts on the clip
  useEffect(() => {
    const getExistingPausePrompts = async () => {
      if (!currentVideo) return;

      const { data } = await fetchExistingVideoPausePrompts(currentVideo);
      const thePausePrompts = data.pause_prompts;

      setExistingVideoQuestions(thePausePrompts);
    };

    getExistingPausePrompts();
  }, [currentVideo])

  useEffect(() => {
    // when editing a pause prompt, seek to that time
    currentPlayerRef?.current?.seekTo(seekTime);
  }, [seekTime])

  const cancelAddQuestion = () => {
    setQuestionFormState(null);
    currentPausePromptService?.toggleEnabled(true);
  };

  const onUpgradeButtonClick = () => {
    chAnalyticsTrackEvent("paywall", {
      user_id: userId,
      model_id: currentVideo.queueable.id,
      model_type: currentVideo.queueable.resource_type ? "Resource" : "UserClip",
      feature: "live_discussions",
      label: "click_paywall",
      link: "upgrade_button"
    });
  };

  const videoIdParams = buildVideoIdParams(currentVideo);

  return (
    <DiscussionContext.Provider value={contextData}>
      <>
        <Row>
          <Col md={10}>
            {discussionContainerErrors &&
              <div className="mt-2 text-danger">
                {discussionContainerErrors}
              </div>
            }

            <div className="d-flex align-items-center mb-3">
              <h3 className="mr-3">{discussionRoom?.title}</h3>

              <Button variant="link" size="sm" className="mr-3" onClick={handleShow("DiscussionSettingsModal")}>
                <FontAwesomeIcon className="mx-3" icon="cog" size="lg" />
              </Button>

              <Button variant="outline-secondary" size="sm" onClick={handleShow("StartDiscussionModal")}>
                Start Discussion
              </Button>
            </div>

            <div>
              {!currentVideo && <CreateDiscussionEmptyState />}

              {currentVideo &&
                <Row className="col-md-12">
                  <Col md={6} className="p-0">
                    <div>
                      <div id="video-container"></div>

                      <div className="mt-2 mb-4">
                        {(!questionFormState && canUserAddQuestion) &&
                          <AddPromptButton
                            canUserAddPrompt={canUserAddQuestion}
                            btnText="Add question"
                            onClick={() => setQuestionFormState({})}
                          />
                        }

                        {(!questionFormState && !canUserAddQuestion) &&
                          <div className="d-flex flex-wrap">
                            <AddPromptButton
                              canUserAddPrompt={canUserAddQuestion}
                              btnText="Add question"
                              extraClasses="mr-2"
                            />

                            <UpgradeButton
                              tooltipText="Upgrade to Premium to add more prompts."
                              onClick={onUpgradeButtonClick}
                            />
                          </div>
                        }
                      </div>

                      {questionFormState &&
                        <QuestionForm
                          currentTime={currentTime}
                          refreshCurrentVideoQuestions={fetchPausePromptsAndSetQuestions}
                          cancelAddQuestion={cancelAddQuestion}
                          existingQuestion={questionFormState}
                          pausePromptService={currentPausePromptService}
                          existingVideoQuestions={existingVideoQuestions}
                          {...videoIdParams} />
                      }
                    </div>
                  </Col>

                  <Col md={6} className="mb-4">
                    <div className="border rounded bg-white">
                      <div className="text-center p-2 rounded-top">
                        Questions for this clip
                      </div>

                      <hr className="m-0" />

                      {questions.map((question, index) =>
                        <Question
                          key={index}
                          refreshCurrentVideoQuestions={fetchPausePromptsAndSetQuestions}
                          setSeekTime={setSeekTime}
                          setQuestionFormState={setQuestionFormState}
                          question={question}
                          setDiscussionContainerErrors={setDiscussionContainerErrors}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              }
            </div>
          </Col>

          <Col md={2} className="border rounded px-0 bg-white">
            <ClipQueue />
          </Col>
        </Row>

        <StartDiscussionModal />
        <DiscussionSettingsModal />
      </>
    </DiscussionContext.Provider>
  )
}

export function renderDiscussion(options: {
  elementId: string;
  discussionRoomId: number;
  isPaidOrTrialUser: boolean;
  userId: string;
}) {
  ReactDOM.render(
    <DiscussionContainer
      discussionRoomId={options.discussionRoomId}
      isPaidOrTrialUser={options.isPaidOrTrialUser}
      userId={options.userId}
    />,
    document.getElementById(options.elementId)
  );
}
