import React, { createRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { chAnalyticsTrackEvent } from "../analytics";
import { DiscussionQuestionsTable } from "../discussion_questions/discussion_questions_table";
import { PausePromptsTable } from "../pause_prompts/pause_prompts_table";
import { ClipType, DiscussionQuestion, PausePrompt, User } from "../../types";

import styles from "./discussion_tools_section.module.scss";

type SectionKeyOptions = 'clip_questions' | 'pause_prompts';

interface Props {
  defaultSectionKey: SectionKeyOptions;
  canAddPausePrompt: boolean;
  clipId: number;
  clipType: ClipType;
  discussionQuestions: Array<DiscussionQuestion>;
  isPremium: boolean;
  pausePrompts: Array<PausePrompt>;
  user: User;
}

export const DiscussionToolsSection = forwardRef(({ defaultSectionKey = 'clip_questions', ...props }: Props, ref) => {
  const [activeTabKey, setActiveTabKey] = useState(defaultSectionKey);

  const discussionQuestionsTableRef = useRef();
  const pausePromptsTableRef = useRef();

  const onClickTab = (tabName: SectionKeyOptions) => {
    if (activeTabKey === tabName) return;

    chAnalyticsTrackEvent("click", {
      model_id: props.clipId,
      model_type: props.clipType,
      link: (tabName === "clip_questions") ? "discussion_questions_tab" : "pause_prompts_tab",
      label: "discussion_tools"
    });

    setActiveTabKey(tabName);
  };

  const onUseDiscussionQuestionAsPausePrompt = (discussionQuestion: DiscussionQuestion) => {
    setActiveTabKey("pause_prompts");
    pausePromptsTableRef.current.setActiveMode("add");
    pausePromptsTableRef.current.updateNewPromptText(discussionQuestion.prompt);
  };

  useImperativeHandle(ref, () => ({
    get discussionQuestionsRef() { return discussionQuestionsTableRef },
    get pausePromptsRef() { return pausePromptsTableRef },
    setActiveTab: (tabKey: SectionKeyOptions) => {
      setActiveTabKey(tabKey);
    }
  }));

  return (
    <div>
      <Tab.Container activeKey={activeTabKey}>
        <div data-discussion-mode-toggle="true">
          <div className="d-flex flex-wrap align-items-center">
            <h4 className="mb-3">Discussion Tools</h4>

            <div className="flex-grow-1"></div>

            <Nav variant="pills" className="border rounded mb-3">
              <Nav.Item className={classNames(styles.navItem, "small")}>
                <Nav.Link eventKey="clip_questions" className={styles.leftLink} onClick={() => onClickTab("clip_questions")}>
                  <FontAwesomeIcon icon="question-circle" className="mr-1" />
                  {' '}
                  Clip Questions
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className={classNames(styles.navItem, "small")}>
                <Nav.Link eventKey="pause_prompts" className={styles.rightLink} onClick={() => onClickTab("pause_prompts")}>
                  <FontAwesomeIcon icon="comment-dots" className="mr-1" />
                  {' '}
                  Pause Prompts
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <Tab.Content className={styles.tabContent}>
          <Tab.Pane eventKey="clip_questions">
            <DiscussionQuestionsTable
              isPremium={props.isPremium}
              discussableType={props.clipType}
              discussableId={props.clipId}
              discussionQuestions={props.discussionQuestions}
              user={props.user}
              ref={discussionQuestionsTableRef}
              onUseAsPausePrompt={onUseDiscussionQuestionAsPausePrompt}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="pause_prompts">
            <PausePromptsTable
              canAddPausePrompt={props.canAddPausePrompt}
              promptableType={props.clipType}
              promptableId={props.clipId}
              pausePrompts={props.pausePrompts}
              user={props.user}
              ref={pausePromptsTableRef}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
});

export function focusVideoPlayerAddEditMode(mode, highlightClass) {
  // Allow the video editor to be used during add or edit mode
  // Hide other elements to concentrate focus on the video player and the editor
  if (mode == null) {
    document.getElementById("video-player-container")?.classList?.remove(highlightClass);
    document.querySelectorAll("[data-discussion-mode-toggle]").forEach(element => element.removeAttribute("hidden"));
  } else {
    document.getElementById("video-player-container")?.classList?.add(highlightClass);
    document.querySelectorAll("[data-discussion-mode-toggle]").forEach(element => element.setAttribute("hidden", "true"));
  }
}

export function renderDiscussionToolsSection(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <DiscussionToolsSection
      defaultSectionKey={options.defaultSectionKey}
      canAddPausePrompt={options.canAddPausePrompt}
      clipId={options.clipId}
      clipType={options.clipType}
      discussionQuestions={options.discussionQuestions}
      isPremium={options.isPremium}
      pausePrompts={options.pausePrompts}
      user={options.user}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}