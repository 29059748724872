import React, { createRef, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { v4 as uuid } from "uuid";

import { fetchData } from "../global_functions";

import 'react-bootstrap-typeahead/css/Typeahead.css';

const PER_PAGE = 10;
const USERS_PATH = "/users";

interface Props {
  htmlInputName: string
  licenseId?: number;
  organizationId?: number;
  multiple?: boolean;
}

interface RequestProps {
  licenseId?: number;
  organizationId?: number;
}

function makeRequest(query, props: RequestProps = {}) {
  const params = {
    "user[q]": query
  };

  if (props.licenseId) params["license_id"] = props.licenseId;
  if (props.organizationId) params["organization_id"] = props.organizationId;

  return fetchData({
    url: USERS_PATH,
    params: params
  });
}

function renderHtmlInput(selectedResults, htmlInputName) {
  const selectedOptionsHtml = selectedResults.map((user, index) => {
    return (<option key={index} value={user.id} selected></option>);
  });

  return (
    <select name={htmlInputName} multiple hidden>
      {selectedOptionsHtml}
    </select>
  );
}

const AddUsersTypeahead = React.forwardRef(({ htmlInputName, organizationId, licenseId, multiple = true }: Props, ref) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);

  const typeaheadId = `license-users-typeahead-${uuid()}`;

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const handleSearch = async (query) => {
    setLoading(true);

    const response = await makeRequest(query, {
      organizationId,
      licenseId
    });

    setResults(response.data.users);
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setSelectedResults([]);
    }
  }));

  return (
    <>
      <AsyncTypeahead
        filterBy={filterBy}
        id={typeaheadId}
        isLoading={loading}
        options={results}
        labelKey="email"
        maxResults={PER_PAGE}
        minLength={2}
        multiple={multiple}
        onChange={setSelectedResults}
        onSearch={handleSearch}
        placeholder="Search for a user..."
        selected={selectedResults}
        renderMenuItemChildren={user => (
          <div key={user.id}>
            <div className="font-weight-bold">
              {user.name}
            </div>
            {user.email}
          </div>
        )}
        useCache={false}
      />

      {renderHtmlInput(selectedResults, htmlInputName)}
    </>
  );
});

export function renderAddUsersTypeahead(elementId, options) {
  const typeaheadRef = createRef();

  ReactDOM.render(
    <AddUsersTypeahead
      licenseId={options.licenseId}
      organizationId={options.organizationId}
      htmlInputName={options.htmlInputName}
      ref={typeaheadRef}
      multiple={options.multiple}
    />,
    document.getElementById(elementId)
  );

  return typeaheadRef;
}