export type MODES = null | 'add' | 'edit';

// In milliseconds
export const UPDATE_ROW_HIGHLIGHT_DURATION = 5000;
export const CUSTOM_TABLE_STYLES = {
  table: {
    style: {
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden"
    }
  },
  headRow: {
    style: {
      minHeight: '36px',
      backgroundColor: "#f8f9fa !important" // from Bootstrap's bg-light class
    }
  },
  rows: {
    style: {
      alignItems: 'center',
      paddingTop: '0.35rem',
      paddingBottom: '0.35rem'
    }
  }
};
