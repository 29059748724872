import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./discussion_tools_overlay.module.scss";

interface Props {
  clipTitle: string;
  clipThumbnailUrl: string;
}

export default function DiscussionToolsOverlay({ clipTitle, clipThumbnailUrl }: Props) {
  return (
    <div className={styles.backgroundContainer}>
      <div className="px-4">
        <div className={classNames(styles.actionMenu, "rounded text-muted")}>
          <div className="mb-2">Start a Live Discussion</div>
          <div>Assign to Students</div>
        </div>

        <div className="d-flex mt-5">
          <h5 className={styles.clipTitle}>{clipTitle}</h5>

          <div className="flex-grow-1"></div>

          <div className="flex-shrink-0">
            <button type="button" className="btn btn-sm btn-outline-secondary mr-2" tabIndex={-1}>
              <FontAwesomeIcon icon="comment-dots" size="sm" />
            </button>

            <button type="button" className="btn btn-sm btn-outline-secondary mr-2" tabIndex={-1}>
              <FontAwesomeIcon icon="plus-circle" size="sm" />
            </button>

            <button type="button" className="btn btn-sm btn-outline-secondary" tabIndex={-1}>
              <FontAwesomeIcon icon="share-square" size="sm" />
            </button>
          </div>
        </div>

        <div className="mt-2 text-center">
          <img
            src={clipThumbnailUrl}
            className={classNames("rounded", styles.thumbnail)}
            width="100%"
            alt="Video thumbnail"
          />
        </div>

        <div className="mt-3">
          <div className={styles.videoDescPlaceholder100}></div>
          <div className={styles.videoDescPlaceholder85}></div>

          <div className="mt-2">
            <div className={styles.videoDescPlaceholder75}></div>
            <div className={styles.videoDescPlaceholder65}></div>
          </div>
        </div>
      </div>
    </div>
  );
}