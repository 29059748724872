import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import Button from "react-bootstrap/Button";

import { AddStandardsTypeahead } from "../standards/add_standards_typeahead";

import { patchData } from "../global_functions";
import { chAnalyticsTrackEvent } from "../analytics";
import { ClipType, Standard, User } from "../../types";

import { CUSTOM_TABLE_STYLES } from "../discussion_questions/constants_and_types";

import styles from "../shared_components/discussion_tools.module.scss";

type MODES = 'add' | null;

interface Props {
  clipType: ClipType;
  clipId: number;
  standards: Array<Standard>;
  user: User;
}

interface StandardsViewMoreObj {
  [standardId: number]: boolean;
}

const STANDARD_TEXT_DISPLAY_LENGTH = 103;

function StandardsTable(props: Props) {
  const [standards, setStandards] = useState<Array<Standard>>([...props.standards]);
  const [standardsViewMoreObj, setStandardsViewMoreObj] = useState<StandardsViewMoreObj>({});
  const [standardsToAdd, setStandardsToAdd] = useState<Array<Standard>>([]);

  const [mode, setMode] = useState<MODES>();

  const onViewMoreClick = (e, standard: Standard) => {
    e.preventDefault();
    setStandardsViewMoreObj({ ...standardsViewMoreObj, [standard.id]: true });
  };

  const tableColumns = useMemo(() => [
    {
      name: "Standard",
      sortable: true,
      wrap: true,
      grow: 0,
      minWidth: "120px",
      cell: (standard: Standard) => {
        return (
          <a href={standard.url} rel="noopener noreferrer" target="_blank">
            {standard.code}
          </a>
        );
      }
    },
    {
      name: "Type",
      selector: (standard: Standard) => standard.standards_set,
      sortable: true,
      wrap: true,
      grow: 0,
      minWidth: "120px"
    },
    {
      name: "Description",
      sortable: true,
      wrap: true,
      grow: 1,
      cell: (standard: Standard) => {
        const shouldViewMore = !!standardsViewMoreObj[standard.id];

        return (
          <>
            {!shouldViewMore &&
              <div className="d-block">
                <span>{standard.sanitized_statement.substring(0, STANDARD_TEXT_DISPLAY_LENGTH)}</span>
                {(standard.sanitized_statement.length > STANDARD_TEXT_DISPLAY_LENGTH) &&
                  <>
                    ...
                    <a href="#" onClick={e => onViewMoreClick(e, standard)}>more</a>
                  </>
                }
              </div>
            }

            {shouldViewMore && <span>{standard.sanitized_statement}</span>}
          </>
        );
      }
    }
  ], [standards, standardsViewMoreObj]);

  const onAddStandardsChanged = (theStandards: Array<Standard>) => {
    setStandardsToAdd(theStandards);
  };

  const onSubmitAddStandards = async () => {
    let path;
    let paramKey;

    if (props.clipType === "Resource") {
      paramKey = "resource";
      path = `/resources/${props.clipId}/add_standards`;
    } else if (props.clipType == "UserClip") {
      paramKey = "user_clip";
      path = `/user_clips/${props.clipId}/add_standards`;
    }

    const params = {
      [paramKey]: { t_standards: standardsToAdd.map(standard => standard.id) }
    };

    const { data } = await patchData({ url: path, data: params });

    const newStandards = data.standards_added;

    if (!newStandards || newStandards.length < 1) return;

    const updatedStandards = [...standards, ...newStandards].sort((standard1: Standard, standard2: Standard) => {
      return (standard1.standard_type.localeCompare(standard2.standard_type) + standard1.code.localeCompare(standard2.code));
    });

    chAnalyticsTrackEvent("standards", {
      model_id: props.clipId,
      model_type: props.clipType,
      standard_ids: newStandards.map(standard => standard.id),
      standard_codes: newStandards.map(standard => standard.code),
      label: "added_to_clip"
    })

    setStandards(updatedStandards);
    setStandardsToAdd([]);
    setMode(null);
  };

  const onCancelAdd = () => {
    setStandardsToAdd([]);
    setMode(null);
  };

  const inputName = useMemo(() => {
    return (props.clipType === "Resource") ? "resource[t_standards]" : "user_clip[t_standards]"
  }, []);

  return (
    <>
      <h4 className="mb-3">Standards</h4>

      <div className="content-container">
        <div className={classNames(styles.tableContainer, "px-3", "py-2")}>
          <div className="d-flex flex-wrap align-items-center">
            <h5 className="my-1 mr-3">All Standards</h5>
            <div className="flex-grow-1"></div>

            <Button variant="outline-secondary" size="sm" className="my-1" disabled={mode != null} onClick={() => setMode("add")}>
              <FontAwesomeIcon icon="plus" />
              {' '}
              Add a Standard
            </Button>
          </div>
        </div>

        {mode != null &&
          <div className="p-3">
            <AddStandardsTypeahead
              htmlInputName={inputName}
              clipId={props.clipId}
              clipType={props.clipType}
              onStandardsChanged={onAddStandardsChanged}
            />

            <div className="d-flex flex-wrap justify-content-end mt-3">
              <div className="mr-3">
                <Button type="button" size="sm" variant="outline-secondary" onClick={onCancelAdd}>
                  Cancel
                </Button>
              </div>

              <div>
                <Button type="button" variant="primary" size="sm" onClick={onSubmitAddStandards} disabled={(standardsToAdd.length < 1)}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        }

        {mode == null &&
          <DataTable
            columns={tableColumns}
            customStyles={CUSTOM_TABLE_STYLES}
            data={standards}
            noDataComponent={
              <div className="p-3 text-center">
                <div className="mb-2">
                  <FontAwesomeIcon icon="clipboard-list" size="2x" />
                </div>

                <div>No standards currently aligned.</div>
              </div>
            }
            responsive
            fixedHeader
          />
        }
      </div>
    </>
  );
}

export function renderStandardsTable(elementId, options) {
  const node = ReactDOM.render(
    <StandardsTable
      clipId={options.clipId}
      clipType={options.clipType}
      standards={options.standards}
      user={options.user}
    />,
    document.getElementById(elementId)
  );

  return node;
}