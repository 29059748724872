import React, { createRef, useImperativeHandle, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import classNames from "classnames";

import { Subtitle } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrentVideoPlayer } from "../video_player/functions";
import { chAnalyticsTrackEvent } from "../analytics";

import styles from "./video_transcript.module.scss";

interface Props {
  containerElementId: string;
  subtitlesHash: Array<Subtitle>;
  clipType?: string;
  clipId?: number;
}

const CUSTOM_TABLE_STYLES = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#29F9FF"
    },
    style: {
      borderBottom: "0 !important"
    }
  }
};

function calculateScrollTop(row: HTMLElement) {
  const prevRow = row.previousElementSibling;
  const prevTwoRows = prevRow?.previousElementSibling;

  let offset = 0;

  if (prevTwoRows) offset -= prevTwoRows.scrollHeight;
  if (prevRow) offset -= prevRow.scrollHeight;

  const scrollTop = Math.max(0, row.offsetTop + offset);

  return scrollTop;
}

export const VideoTranscript = React.forwardRef((props: Props, ref) => {
  const [activeSubtitleId, setActiveSubtitleId] = useState<number>();

  const containerElement = useMemo(() => document.getElementById(props.containerElementId), []);

  useImperativeHandle(ref, () => ({
    onTimeUpdate: (time) => {
      const activeSubtitle = props.subtitlesHash.find(subtitle => {
        return (time >= subtitle.start_time && time <= subtitle.end_time);
      });

      if (!activeSubtitle) {
        setActiveSubtitleId(null);
        return;
      }

      if (activeSubtitleId !== activeSubtitle.id) {
        setActiveSubtitleId(activeSubtitle.id);

        const row = document.getElementById(`subtitle-row-${activeSubtitle.id}`).parentElement.parentElement;
        const scrollTop = calculateScrollTop(row);

        containerElement.scrollTop = scrollTop;
      }
    }
  }));

  const tableColumns = useMemo(() => [
    {
      cell: (subtitle: Subtitle) => (
        <div id={`subtitle-row-${subtitle.id}`} className="d-flex align-items-start my-2 small">
          <div className={classNames("border rounded mr-3 flex-shrink-0", styles.startTime)} id={`subtitle-start-time-${subtitle.id}`} data-tag="allowRowEvents">
            {subtitle.formatted_start_time}
          </div>

          <div className={classNames(styles.phrase)} id={`subtitle-phrase-${subtitle.id}`} data-tag="allowRowEvents">
            {subtitle.censored_phrase}
          </div>
        </div>
      ),
      wrap: false,
      allowOverflow: false
    }
  ], [props.subtitlesHash]);

  const rowClick = (row: Subtitle) => {
    let videoPlayer = getCurrentVideoPlayer();
    if (videoPlayer) {
      videoPlayer.seekTo(row.start_time)
    }

    const analyticsParams = {
      label: "video_transcript",
      link: "timestamp_row",
      start_time: row.start_time
    };

    if (props.clipType) analyticsParams["model_type"] = props.clipType;
    if (props.clipId) analyticsParams["model_id"] = props.clipId;

    chAnalyticsTrackEvent("click", analyticsParams);
  };

  return (
    <DataTable
      columns={tableColumns}
      conditionalRowStyles={[
        {
          when: (subtitle: Subtitle) => activeSubtitleId && (subtitle.id === activeSubtitleId),
          classNames: [styles.selectedRow]
        }]
      }
      onRowClicked={(row, event) => rowClick(row)}
      data={props.subtitlesHash}
      noDataComponent={
        <div className="p-3 text-center">
          <div className="mb-2">
            <FontAwesomeIcon icon="inbox" size="2x" />
          </div>

          <div>No transcript available</div>
        </div>
      }
      highlightOnHover
      pointerOnHover
      noTableHead
      customStyles={CUSTOM_TABLE_STYLES}
      style={{ maxHeight: '400px' }}
      dense
    />
  );
});

export function renderVideoTranscript(elementId, options) {
  const typeaheadRef = createRef();

  ReactDOM.render(
    <VideoTranscript
      containerElementId={elementId}
      subtitlesHash={options.subtitlesHash}
      clipType={options.clipType}
      clipId={options.clipId}
      ref={typeaheadRef}
    />,
    document.getElementById(elementId)
  );

  return typeaheadRef;
}