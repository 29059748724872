import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { postData } from "../global_functions";

interface Props {
  anyRemainingLicenses: boolean;
  assignedLicenseId?: number;
  userId: number;
  organizationId: number;
  placement?: string;
  onSuccess?: (data) => void;
}

const TOOLTIP_MESSAGE = "Contact our team to purchase more licenses";
const buttonClasses = "w-100";

export function AssignUserLicenseButton(props: Props) {
  const thePlacement = props.placement || "bottom";

  const hasLicense = props.assignedLicenseId;
  const disabled = !props.anyRemainingLicenses && !hasLicense;

  let buttonText;
  let buttonVariant;
  let url;

  if (hasLicense) {
    buttonText = "Remove";
    buttonVariant = "danger";
    url = `/assigned_licenses/${props.assignedLicenseId}/remove_from_user`;
  } else {
    buttonText = "Assign";
    buttonVariant = "success";
    url = "/assigned_licenses";
  }

  const requestData = {
    organization_id: props.organizationId,
    user_id: props.userId
  };

  const handleOnButtonClick = async (e) => {
    try {
      const { data } = await postData({ url: url, data: requestData });
      props.onSuccess?.(data);
    } catch {
      // No-op
    }
  };

  const buttonHtml = (
    <Button
      className={buttonClasses}
      variant={buttonVariant}
      size="sm"
      disabled={disabled}
      onClick={handleOnButtonClick}
    >
      {buttonText}
    </Button>
  );

  return (
    <>
      { disabled &&
        <OverlayTrigger
          placement={thePlacement}
          overlay={
            <Tooltip>{TOOLTIP_MESSAGE}</Tooltip>
          }
        >
          {buttonHtml}
        </OverlayTrigger>
      }

      {!disabled && buttonHtml}
    </>
  );
}