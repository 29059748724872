import React, { useContext, useState } from "react";
import {
  Button, Form,
  Modal
} from "react-bootstrap";

import FormErrorsList from "../shared_components/form_errors_list";
import { patchData } from "../global_functions";
import DiscussionContext from "./discussion_context";

export default function DiscussionSettingsModal(): React.ReactNode {
  const [errors, setErrors] = useState([]);

  const { discussionRoom, setDiscussionRoom, discussionRoomId, modal: { activeModalName, handleClose } } = useContext(DiscussionContext);
  const saveDiscussionRoom = async () => {
    const updateData = {
      discussion_room: {
        title: discussionRoom.title,
        lesson_objective: discussionRoom.lesson_objective
      }
    };
    const { data } = await patchData({ url: `/discussion_rooms/${discussionRoomId}`, data: updateData });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setErrors([]);
      setDiscussionRoom(data.discussion_room)
      handleClose();
    }
  }

  return (
    <Modal show={activeModalName == "DiscussionSettingsModal"} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="discussion-settings-modal">Discussion Settings</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {errors && <FormErrorsList errors={errors} />}

        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={discussionRoom?.title}
              onChange={(e) => setDiscussionRoom({
                ...discussionRoom,
                title: e.target.value
              })}>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Lesson Objective / Theme</Form.Label>
            <Form.Control
              value={discussionRoom?.lesson_objective}
              onChange={(e) => setDiscussionRoom({
                ...discussionRoom,
                lesson_objective: e.target.value
              })}
              as="textarea"
              rows={3}>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-content-center px-5">
        <Button variant="primary" className="px-5" aria-label="Save" onClick={saveDiscussionRoom}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
