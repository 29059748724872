import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'

import { fetchData, patchData } from "../global_functions";
import { chAnalyticsTrackEvent } from "../analytics";
import FormErrorsList from "../shared_components/form_errors_list";

interface ElementProps {
  queueableId: number;
  queueableType: string;
}

interface Props {
  queueableId: number;
  queueableType: string;
  elementId: string;
}

function AddQueueableModal({ queueableId, queueableType }: ElementProps) {
  const [show, setShow] = useState(false);
  const [selectedDiscussionRoomId, setSelectedDiscussionRoomId] = useState<string>("");
  const [discussionRooms, setDiscussionRooms] = useState([]);
  const [errors, setErrors] = useState([]);

  const getData = async () => {
    let { data } = await fetchData({ url: "/discussion_rooms" });

    // Filter out discussions that already have this clip
    const theDiscussionRooms = data.filter(discussionRoom => {
      const clip = discussionRoom.discussion_queue_videos.find(video => {
        return video.queueable_id === queueableId && video.queueable_type === queueableType
      });

      return !clip;
    });

    setDiscussionRooms(theDiscussionRooms);
    setSelectedDiscussionRoomId(theDiscussionRooms[0]?.id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedDiscussionRoomId) return;

    try {
      await patchData({
        url: `/discussion_rooms/${selectedDiscussionRoomId}/add_queueable`,
        data: {
          queueable_id: queueableId,
          queueable_type: queueableType
        }
      });

      chAnalyticsTrackEvent("discussion_room", {
        model_id: selectedDiscussionRoomId,
        model_type: "DiscussionRoom",
        label: "update",
        link: "add_to_existing_discussion_button",
        clip_id: queueableId,
        clip_type: queueableType
      }, () => {
        window.location.href = `/discussion_rooms/${selectedDiscussionRoomId}/edit`;
      });
    } catch (error) {
      const theErrors = error.response?.data?.errors || [];
      setErrors(theErrors);
    }

    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getData();
  }, []);

  if (discussionRooms.length < 1) {
    return null;
  }

  return (
    <>
      <Button onClick={handleShow} className="dropdown-item">
        Add to existing discussion
      </Button>

      <Modal show={show} onHide={handleClose} id="existing-discussions-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add to Existing Discussion</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <p>
              If the clip has already been added to a discussion, the discussion will not be listed below.
            </p>

            <FormErrorsList errors={errors} />

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                onChange={e => setSelectedDiscussionRoomId(e.target.value)}
                as="select"
              >
                {
                  discussionRooms.map(discussionRoom => {
                    return (
                      <option key={discussionRoom.id} value={discussionRoom.id}>
                        {discussionRoom.title}
                      </option>
                    );
                  })
                }
              </Form.Control>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={!selectedDiscussionRoomId}>
              Add
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export function renderAddQueueableModal(options: Props) {
  ReactDOM.render(
    <AddQueueableModal
      queueableId={options.queueableId}
      queueableType={options.queueableType}
    />,
    document.getElementById(options.elementId)
  );
}