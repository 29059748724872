import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import classNames from "classnames";
import { Option } from "react-multi-select-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import QuestionTemplate from "./question_template";
import UpgradeButton from "../shared_components/upgrade_button";

import { fetchData, deleteData } from "../global_functions";
import { chAnalyticsTrackEvent } from "../analytics";
import { DiscussionQuestionTemplate } from "../../types";
import DiscussionQuestionContext from "../discussion_questions/discussion_question_context";

import styles from "./question_bank.module.scss";

interface Props {
  discussableType: string;
  discussableId: number;
  isPremium: boolean;
  onSelected?: (questionTemplate: DiscussionQuestionTemplate) => void;
  onAddTemplateClicked?: () => void;
}

const DEFAULT_ACTIVE_TAB = 'starter_templates';
const MAX_FREE_TEMPLATES = 5;
const MAX_FREE_STARTER_TEMPLATES = 30;

async function fetchTemplates({ discussableType, discussableId, isStarter = false, query = '', filters = {} }) {
  const searchPath = isStarter ? 'search_starter' : 'search';
  const url = `/discussion_question_templates/${searchPath}`;

  const params = {
    discussable_type: discussableType,
    discussable_id: discussableId
  };

  const filterKeys = filters && Object.keys(filters);

  if (query) params['query'] = query;
  if (filterKeys?.length > 0) {
    filterKeys.forEach(filterKey => params[`filters[${filterKey}]`] = filters[filterKey]);
  }

  const { data } = await fetchData({
    url: url,
    params: params
  });

  return data;
}

const QuestionBank = forwardRef((
  { discussableId, discussableType, isPremium, onSelected, onAddTemplateClicked }: Props,
  ref
) => {
  const [userQuestionTemplates, setUserQuestionTemplates] = useState<Array<DiscussionQuestionTemplate>>([]);
  const [starterQuestionTemplates, setStarterQuestionTemplates] = useState<Array<DiscussionQuestionTemplate>>([]);
  const [selectedQuestionTemplate, setSelectedQuestionTemplate] = useState<DiscussionQuestionTemplate>();
  const [numRemainingTemplates, setNumRemainingTemplates] = useState(MAX_FREE_TEMPLATES);
  const [maxStarterTemplates, setMaxStarterTemplates] = useState(MAX_FREE_STARTER_TEMPLATES);

  const [categoryFilterOptions, setCategoryFilterOptions] = useState<Array<Option>>([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [activeTabKey, setActiveTabKey] = useState(DEFAULT_ACTIVE_TAB);

  const { user } = useContext(DiscussionQuestionContext);

  useEffect(() => {
    getTemplates();
  }, [selectedCategory]);

  useImperativeHandle(ref, () => ({
    appendUserTemplate: (questionTemplate: DiscussionQuestionTemplate) => {
      setUserQuestionTemplates([...userQuestionTemplates, questionTemplate]);
    },
    updateRemainingTemplates: (numTemplates: number) => {
      setNumRemainingTemplates(numTemplates);
    }
  }));

  const canAddQuestionTemplate = useMemo(() => {
    return (isPremium || numRemainingTemplates > 0);
  }, [numRemainingTemplates]);

  const getTemplates = useCallback(async () => {
    const filters = {};

    if (selectedCategory.length > 0) {
      filters['categories'] = [selectedCategory];
    }

    const userTemplateData = await fetchTemplates({ discussableType, discussableId, filters: filters });
    setUserQuestionTemplates(userTemplateData.discussion_question_templates);
    setNumRemainingTemplates(userTemplateData.num_templates_remaining);

    const starterTemplateData = await fetchTemplates({ discussableType, discussableId, isStarter: true, filters: filters });
    setStarterQuestionTemplates(starterTemplateData.discussion_question_templates);
    setMaxStarterTemplates(starterTemplateData.max_num_starter_templates);

    if (categoryFilterOptions.length < 1) {
      let uniqueCategories = {};

      const allTemplates = userTemplateData.discussion_question_templates.concat(starterTemplateData.discussion_question_templates);

      allTemplates.forEach((questionTemplate: DiscussionQuestionTemplate) => {
        if (questionTemplate.category) {
          uniqueCategories[questionTemplate.category] = true;
        }
      });

      const categoryOptions = Object.keys(uniqueCategories).map(category => ({ label: category, value: category })).sort((objA, objB) => objA.label.localeCompare(objB.label));

      setCategoryFilterOptions(categoryOptions);
    }
  }, [selectedCategory]);

  const onTemplateSelected = (questionTemplate: DiscussionQuestionTemplate) => {
    if (selectedQuestionTemplate?.id == questionTemplate.id) {
      setSelectedQuestionTemplate(null);
      onSelected?.(null);
    } else {
      chAnalyticsTrackEvent("click", {
        model_id: discussableId,
        model_type: discussableType,
        label: `${questionTemplate.template_type}_discussion_question_template`,
        link: "selected"
      });

      setSelectedQuestionTemplate(questionTemplate);
      onSelected?.(questionTemplate);
    }
  };

  const onTemplateDeleteClicked = async (questionTemplate: DiscussionQuestionTemplate) => {
    if (questionTemplate.user_id !== user.id) return;

    const { data } = await deleteData({
      url: `/discussion_question_templates/${questionTemplate.id}`
    });

    if (data.discussion_question_template) {
      const updatedUserQuestionTemplates = userQuestionTemplates.filter(template => template.id !== data.discussion_question_template.id);
      setUserQuestionTemplates(updatedUserQuestionTemplates);
    }

    if (data.num_templates_remaining) {
      setNumRemainingTemplates(data.num_templates_remaining);
    }

    if (selectedQuestionTemplate?.id === questionTemplate.id) {
      setSelectedQuestionTemplate(null);
      onSelected?.(null);
    }
  };

  const handleAddTemplateClicked = () => {
    if (!canAddQuestionTemplate) return;

    chAnalyticsTrackEvent("click", {
      model_id: discussableId,
      model_type: discussableType,
      label: "discussion_question_template",
      link: "add_template_button"
    });

    onAddTemplateClicked?.();
  };

  const onUpgradeClick = (templateType: 'user' | 'starter') => {
    chAnalyticsTrackEvent("paywall", {
      user_id: user.id,
      model_id: discussableId,
      model_type: discussableType,
      feature: "discussion_question_template",
      label: "click_paywall",
      link: "upgrade_button",
      extra_data: {
        template_type: templateType
      }
    });
  };

  return (
    <>
      <Tab.Container activeKey={activeTabKey}>
        <Nav variant="pills" className="border rounded mb-3">
          <Nav.Item className={classNames(styles.navItem, "small")} onClick={() => setActiveTabKey("starter_templates")}>
            <Nav.Link eventKey="starter_templates" className={styles.leftLink}>Starter Templates</Nav.Link>
          </Nav.Item>

          <Nav.Item className={classNames(styles.navItem, "small")} onClick={() => setActiveTabKey("user_templates")}>
            <Nav.Link eventKey="user_templates" className={styles.rightLink}>Your Templates</Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="flex-grow-1 mb-3">
          <Form.Control as="select" size="sm" onChange={e => setSelectedCategory(e.target.value)}>
            <option value="">Select Question Type</option>

            {categoryFilterOptions.map((filterOption: Option, index) =>
              <option value={filterOption.value} key={index}>
                {filterOption.label}
              </option>
            )}
          </Form.Control>
        </div>

        {(activeTabKey === "user_templates") &&
          <div className="d-flex flex-wrap align-items-center">
            {!isPremium &&
              <>
                <div className="text-muted small mb-3 mr-2">
                  {numRemainingTemplates}/{MAX_FREE_TEMPLATES}
                  {' '}
                  templates remaining
                </div>

                <div className="mb-3">
                  <UpgradeButton buttonText="UPGRADE" onClick={() => onUpgradeClick("user")} />
                </div>
              </>
            }

            <div className="flex-grow-1"></div>

            <div className="mb-3">
              <Button type="button" variant="link" size="sm" disabled={!canAddQuestionTemplate} onClick={handleAddTemplateClicked}>
                <FontAwesomeIcon icon="plus" size="sm" />
                {' '}
                Add
              </Button>
            </div>
          </div>
        }

        {(!isPremium && activeTabKey === "starter_templates") &&
          <div className="d-flex flex-wrap align-items-center">
            <div className="text-muted small mb-3 mr-2">
              Accessing {MAX_FREE_STARTER_TEMPLATES}/{maxStarterTemplates}
              {' '}
              starter templates
            </div>

            <div className="mb-3">
              <UpgradeButton buttonText="UPGRADE" onClick={() => onUpgradeClick("starter")} />
            </div>
          </div>
        }

        <Tab.Content className={styles.tabContent}>
          <Tab.Pane eventKey="starter_templates">
            {starterQuestionTemplates.map(questionTemplate =>
              <QuestionTemplate
                key={questionTemplate.id}
                questionTemplate={questionTemplate}
                onSelected={onTemplateSelected}
                extraClasses={classNames("mb-3", selectedQuestionTemplate?.id === questionTemplate.id ? styles.selectedItem : "")}
              />
            )}
          </Tab.Pane>

          <Tab.Pane eventKey="user_templates">
            {userQuestionTemplates.map(questionTemplate =>
              <QuestionTemplate
                key={questionTemplate.id}
                questionTemplate={questionTemplate}
                onSelected={onTemplateSelected}
                onDeleteClicked={onTemplateDeleteClicked}
                extraClasses={classNames("mb-3", selectedQuestionTemplate?.id === questionTemplate.id ? styles.selectedItem : "")}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container >
    </>
  );
});

export default QuestionBank;