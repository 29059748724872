import React, { useEffect, useState } from "react";
import classNames from "classnames";
import pluralize from "pluralize";

import { postData } from "../global_functions";
import UpgradeButton from "../shared_components/upgrade_button";
import InviteUsersField from "../shared_components/invite_users_field";

import styles from "./paywall_upgrade_prompt.module.scss";

interface Props {
  className?: string;
  labelText?: string;
  rewardCount?: number;
  onInvited?: (inviteCount: number) => void;
}

export function ReferPaywallPrompt({ className, labelText, rewardCount = 4, onInvited }: Props) {
  const [inviteCount, setInviteCount] = useState(0);
  const [hasSentInvites, setHasSentInvites] = useState(false);
  const [receivedReward, setReceivedReward] = useState(false);

  const [error, setError] = useState('');

  const onSuccess = async (responseData: { invites_sent: number }) => {
    const numInvites = responseData.invites_sent;

    setError('');
    setInviteCount(inviteCount + numInvites);
    setHasSentInvites(true);

    onInvited?.(numInvites);
  };

  useEffect(() => {
    if (inviteCount < 1 || receivedReward) return;

    const requestReward = async () => {
      const { data } = await postData({ url: "/referral_invitations/check_grant_reward" });

      if (data.errors) {
        setError(data.errors[0]);
        return;
      }

      setReceivedReward(data.received_reward);
    };

    requestReward();
  }, [inviteCount]);

  return (
    <div className={classNames(styles.container, className)}>
      <div className="d-flex align-items-center">
        <div className="mr-3">
          <UpgradeButton icon="users" disableClicks={true} size="lg" />
        </div>

        <div className={styles.upgradeText}>Refer others to ClassHook</div>
      </div>

      {labelText &&
        <div className="my-3 small">{labelText}</div>
      }

      <div className="d-flex align-items-center my-3">
        <>
          {!receivedReward &&
            <InviteUsersField
              extraContainerClasses="w-100"
              extraButtonClasses="mt-2 w-100"
              placeholder="Enter emails"
              postUrl="/users/send_invitations"
              maxUsers={30}
              onSuccess={onSuccess}
            />
          }

          {receivedReward &&
            <div className="alert alert-info small">
              Great job! You've received 7 days of free Premium access. Refresh the page on your browser.
            </div>
          }
        </>
      </div>

      {hasSentInvites && !receivedReward &&
        <div className="small">{pluralize('invitation', inviteCount, true)} sent</div>
      }

      {error &&
        <div className="small text-red">{error}</div>
      }
    </div >
  )
}