import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "react-bootstrap";
import AddClipModal from "./add_clip_modal";
import DiscussionContext from "./discussion_context";
import { deleteData, fetchData, patchData } from "../global_functions";

import "./clip_queue.scss";

function Clip({ discussionQueueVideo, index }): React.ReactNode {
  const { discussionRoomId, setQueue, setCurrentVideo, setIsUsingEduPlayer } = useContext(DiscussionContext);
  const clip = discussionQueueVideo.queueable;

  const handleEdit = (e) => {
    e.preventDefault();
    setCurrentVideo(discussionQueueVideo);
    setIsUsingEduPlayer(window.chUseEduPlayer);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    await deleteData({ url: `/discussion_queue_videos/${discussionQueueVideo.id}` });
    const { data } = await fetchData({ url: "/discussion_queue_videos", params: { discussion_room_id: discussionRoomId } });
    setQueue(data);
  };

  const thumbnailUrl = clip.video_file ? clip.video_file.thumbnail_url : clip.thumbnail_url;

  return (
    <Draggable draggableId={discussionQueueVideo.id.toString()} index={index}>
      {provided => (
        <div className="d-flex pb-3 justify-content-center"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <img className="queue-thumbnail border" src={thumbnailUrl} />
          <div className="d-flex flex-column justify-content-around pl-2">
            <Button onClick={handleEdit} variant="link" size="sm">
              <FontAwesomeIcon icon="edit" size="lg" />
            </Button>

            <Button onClick={handleDelete} variant="link" size="sm" className="text-red">
              <FontAwesomeIcon icon="trash" size="lg" />
            </Button>
          </div>
        </div>
      )}
    </Draggable>
  );
}

function ClipQueue(): React.ReactNode {
  const { videos, discussionRoomId, modal: { handleShow }, setQueue } = useContext(DiscussionContext);

  async function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const discussionQueueVideoId = videos[result.source.index].id;
    const patchBody = {
      position: result.destination.index + 1
    };

    await patchData({ url: `/discussion_queue_videos/${discussionQueueVideoId}`, data: patchBody });
    const { data } = await fetchData({ url: "/discussion_queue_videos", params: { discussion_room_id: discussionRoomId } });
    setQueue(data);
  }

  return (
    <div>
      <div className="text-center px-3 py-2 rounded-top">Clips in Queue</div>

      <hr className="m-0" />

      <div className="px-3 pt-3 h-100 rounded-bottom">
        <Button onClick={handleShow("AddClipModal")} className="mb-4" variant="outline-secondary" size="sm" block>
          <FontAwesomeIcon className="mr-1" icon="plus" size="sm" />
          Add
        </Button>

        <div className="clip-queue-video-list">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  { videos && videos.map((video, index) =>
                    <Clip key={video.id} discussionQueueVideo={video} index={index} />)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <AddClipModal />
      </div>
    </div>
  );
}

export default ClipQueue;
