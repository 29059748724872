import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  canUserAddPrompt: boolean;
  btnText?: string;
  extraClasses?: string;
  iconName?;
  onClick?: () => void;
}

export default function AddPromptButton({ canUserAddPrompt, btnText, extraClasses, iconName, onClick }: Props) {
  const theBtnText = btnText || "Add a Prompt";

  const onClickHandler = () => {
    if (!canUserAddPrompt) return null;
    onClick?.();
  };

  return (
    <Button variant="outline-secondary" size="sm" className={extraClasses} onClick={onClickHandler} disabled={!canUserAddPrompt}>
      {iconName &&
        <>
          <FontAwesomeIcon icon={iconName} />
          {' '}
        </>
      }

      {theBtnText}
    </Button>
  );
}