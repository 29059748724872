import React from "react";
import ReactDOM from "react-dom";

import LoggedOutImage from "./discussion_tools_section_logged_out_image.svg";

function DiscussionToolsSectionLoggedOut() {
  return (
    <div className="border rounded bg-light dts-logged-out-container">
      <div className="row">
        <div className="col-md-8 col-sm-12 col-xs-12">
          <div className="px-4 py-3 dts-logged-out-row-1">
            <h5>Discuss with your students</h5>

            <p>
              Sign up to access lesson features such as sample questions, standards alignment,
              and interactive discussion tools to check for understanding.
            </p>

            <div>
              <a href="/signup" className="btn btn-sm btn-primary">Sign up to continue</a>
              {' '}
              <span className="mx-3">or</span>
              {' '}
              <a href="/signin">Sign In</a>
            </div>
          </div>
        </div>

        <div className="col-md-4 d-flex align-items-center justify-content-center dts-logged-out-img">
          <img src={LoggedOutImage} width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
}

export function renderDiscussionToolsSectionLoggedOut(elementId) {
  const node = ReactDOM.render(
    <DiscussionToolsSectionLoggedOut />,
    document.getElementById(elementId)
  );

  return node;
}