import React, { useState } from "react";
import ReactDOM from "react-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import DataTable from "react-data-table-component";
import Select from 'react-select';

import { isAssignmentMatch } from "./assignments_helper";
import { patchData, deleteData } from "../global_functions";
import DuplicateAssignmentButton from "./duplicate_assignment_button";
import { Assignment, Course, User } from "../../types";

import styles from './assignments.module.scss';

interface Props {
  assignments: Array<Assignment>;
  submissionCounts: {
    [assignmentId: number]: {
      student_count: number;
      submission_count: number;
    }
  },
  courses: Array<Course>;
  user: User;
  onDeleted?: (assignment: Assignment) => void;
  onDuplicated?: (assignment: Assignment) => void;
}

function ViewAssignmentsTable({ assignments, submissionCounts, courses, user, onDeleted, onDuplicated }: Props) {
  const [theAssignments, setTheAssignments] = useState(assignments);
  const [theSubmissionCounts, setTheSubmissionCounts] = useState(submissionCounts);
  const [filterText, setFilterText] = useState("");
  const [filteredCourseIds, setFilteredCourseIds] = useState([]);

  let filteredAssignments = theAssignments;

  if (filterText.length > 0) {
    filteredAssignments = filteredAssignments.filter(assignment => isAssignmentMatch(assignment, filterText));
  }

  if (filteredCourseIds.length > 0) {
    filteredAssignments = filteredAssignments.filter(assignment => {
      return assignment.courses?.find(course => filteredCourseIds.includes(course.id));
    });
  }

  const handleFilterChange = e => setFilterText(e.target.value);
  const handleFilterCourses = (ary) => {
    const theAry = ary || [];
    const courseIds = theAry.map(courseObj => courseObj.value);

    setFilteredCourseIds(courseIds);
  };

  const onDuplicateAssignment = (assignment, newAssignment) => {
    const newAssignments = [newAssignment, ...theAssignments];
    const newSubmissionCount = { ...theSubmissionCounts[assignment.id], submission_count: 0 };
    const newSubmissionCounts = { ...theSubmissionCounts, [newAssignment.id]: newSubmissionCount };

    setTheSubmissionCounts(newSubmissionCounts);
    setTheAssignments(newAssignments);

    onDuplicated?.(assignment);
  };

  const handleArchiveActivateAssignment = async (e, assignment: Assignment) => {
    e.preventDefault();

    let response;

    const newState = (assignment.state === "active") ? "archived" : "active";

    try {
      response = await patchData({
        url: `/assignments/${assignment.id}`,
        data: {
          assignment: { state: newState }
        }
      });

      const updatedAssignment = response.data.assignment;

      if (updatedAssignment) {
        window.location.reload();
      }
    } catch (e) {
      response = e.response;
    }

    return response;
  };

  const handleDeleteAssignment = async (e, assignment) => {
    e.preventDefault();

    const result = window.confirm(`Are you sure you want to delete assignment: ${assignment.name}? This will also delete all associated student submissions.`);

    if (!result) return;

    let response;

    try {
      response = await deleteData({ url: `/assignments/${assignment.id}` });

      const updatedAssignments = theAssignments.filter(theAssignment => theAssignment.id !== assignment.id);
      setTheAssignments(updatedAssignments);

      onDeleted?.(assignment);
    } catch (e) {
      response = e.response;
    }

    return response;
  };

  const tableColumns = [
    {
      name: "Name",
      selector: assignment => assignment.name,
      sortable: true,
      wrap: true
    },
    {
      name: "Code",
      selector: assignment => assignment.code,
      sortable: true,
      wrap: true
    },
    {
      name: "Courses",
      selector: assignment => assignment.courses?.map(course => course.name)?.join(', '),
      sortable: true,
      wrap: true,
      hide: "md"
    },
    {
      name: "Assigned Item",
      selector: assignment => assignment.assignedItem.title,
      sortable: true,
      wrap: true,
      cell: assignment => {
        const assignedItem = assignment.assignedItem;

        return (
          <a href={assignedItem.url} target="_blank" rel="noopener noreferrer">{assignedItem.title}</a>
        );
      },
      hide: "sm"
    },
    {
      name: "Submissions",
      selector: assignment => theSubmissionCounts[assignment.id].submission_count,
      sortable: true,
      wrap: true,
      cell: assignment => {
        return `${theSubmissionCounts[assignment.id].submission_count} / ${theSubmissionCounts[assignment.id].student_count}`;
      },
      hide: "md"
    },
    {
      name: "Created at",
      selector: assignment => {
        const dateTime = DateTime.fromISO(assignment.createdAt);
        return dateTime.toLocaleString(DateTime.DATETIME_FULL);
      },
      sortable: true,
      wrap: true,
      cell: assignment => DateTime.fromISO(assignment.createdAt).toRelative(),
      hide: "md"
    },
    {
      name: "Actions",
      cell: (assignment: Assignment) => {
        const actionMenuId = `assignment_${assignment.id}_actions`;
        const isCreator = (user.id == assignment.userId);

        return (
          <div className="d-flex flex-row flex-wrap align-items-center">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`assignment-${assignment.id}-view-tooltip`}>
                  View Assignment
                </Tooltip>
              }
            >
              <a href={`/assignments/${assignment.id}`} className="mr-3 text-reset">
                <FontAwesomeIcon icon="external-link-alt" />
                <span className="sr-only">View</span>
              </a>
            </OverlayTrigger>

            {isCreator &&
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`assignment-${assignment.id}-view-tooltip`}>
                    View Submissions
                  </Tooltip>
                }
              >
                <a href={`/assignments/${assignment.id}/report`} className="mr-2">
                  <FontAwesomeIcon icon="clipboard-list" />
                  <span className="sr-only">View Submissions</span>
                </a>
              </OverlayTrigger>
            }

            <div className="dropdown">
              <button
                id={actionMenuId}
                type="button"
                className="btn btn-sm-btn-link"
                data-toggle="dropdown"
                data-boundary="window"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <small><FontAwesomeIcon icon="ellipsis-h" className="text-muted" /></small>
                <span className="sr-only">Actions</span>
              </button>

              <div className="dropdown-menu" aria-labelledby={actionMenuId}>
                <button type="button" className="copy-btn dropdown-item" data-clipboard-text={assignment.url}>Copy Join Link</button>

                {isCreator &&
                  <>
                    <a href={`/assignments/${assignment.id}/student_questions`} className="dropdown-item">View Student Questions</a>
                    <a href={`/assignments/${assignment.id}/edit`} className="dropdown-item">Edit</a>
                  </>
                }

                <DuplicateAssignmentButton assignment={assignment} onSuccess={onDuplicateAssignment} classNames="dropdown-item" />

                {isCreator &&
                  <>
                    <a href="#" onClick={e => handleArchiveActivateAssignment(e, assignment)} className="dropdown-item">
                      {assignment.state === "active" ? "Archive" : "Activate"}
                    </a>

                    <a href="#" onClick={e => handleDeleteAssignment(e, assignment)} className="dropdown-item">Delete</a>
                  </>
                }
              </div>
            </div>
          </div>
        );
      }
    }
  ];

  const courseOptions = courses.map(course => {
    return { label: course.name, value: course.id };
  });

  return (
    <>
      <div className="row">
        <div className="col-md-4 offset-md-4 mb-3">
          <Select
            id="assignments_filter_course"
            onChange={handleFilterCourses}
            options={courseOptions}
            isMulti
            closeMenuOnSelect={false}
            isDisabled={false}
            placeholder="Filter by course"
          />
        </div>

        <div className="col-md-4 mb-3">
          <input type="input" className="form-control" placeholder="Search..." value={filterText} onChange={handleFilterChange} />
        </div>
      </div>

      <div className={styles['assignments-table']}>
        <DataTable
          columns={tableColumns}
          data={filteredAssignments}
        />
      </div>
    </>
  );
}

export function renderViewAssignmentsTable(elementId, options) {
  const node = ReactDOM.render(
    <ViewAssignmentsTable
      assignments={options.assignments}
      submissionCounts={options.submissionCounts}
      courses={options.courses}
      user={options.user}
      onDeleted={options.onDeleted}
      onDuplicated={options.onDuplicated}
    />,
    document.getElementById(elementId)
  );

  return node;
}