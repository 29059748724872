import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { string } from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";

interface Props {
  extraBtnClasses?: string;
  iconName?: string;
  message?: string;
  placement?: string;
  size?: string;
  text?: string;
  targetElementId: string;
  trackingAttrs?: {
    "data-ch-event-type"?: string;
    "data-ch-event-name": string;
    "data-ch-event-model-id": string;
    "data-ch-event-model-type": string;
    "data-ch-event-link": string;
    "data-ch-event-action": string;
    [key: string]: string;
  }
}

function iconHtml(iconName, text) {
  if (!iconName) return text;

  return (
    <>
      <FontAwesomeIcon icon={iconName} />
      <span className="sr-only">{text}</span>
    </>
  );
}

// Tooltip show/hide handled by app/assets/javascripts/clipboard_helper.js
export default function CopyButton({
  extraBtnClasses = "btn btn-secondary",
  iconName,
  message = "Copied!",
  placement = "bottom",
  size = "",
  text = "Copy",
  trackingAttrs = {},
  targetElementId
}: Props) {
  const theIconHtml = iconHtml(iconName, text);

  return (
    <Button
      className={`copy-btn ${extraBtnClasses}`}
      data-clipboard-target={`#${targetElementId}`}
      data-toggle="tooltip"
      data-trigger="click"
      placement={placement}
      size={size}
      title={message}
      { ...trackingAttrs }
    >
      {theIconHtml}
    </Button>
  );
}
