import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpgradeButton from "../shared_components/upgrade_button";

import styles from "./paywall_upgrade_prompt.module.scss"
import upgradeSectionStyles from "./paywall_upgrade_section.module.scss";

interface Props {
  benefitText: string;
  mobileUpgradeReason?: string;
  className?: string;
  onUpgradeClicked?: (e) => void;
}

const PREMIUM_FEATURE_COUNT = 8;

export function PaywallUpgradePrompt({ benefitText, onUpgradeClicked, mobileUpgradeReason = "", ...props }: Props) {
  const benefitsAry = [
    benefitText,
    `+${PREMIUM_FEATURE_COUNT} more benefits`
  ];

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className="d-flex align-items-center">
        <div className="mr-3">
          <UpgradeButton disableClicks={true} size="lg" />
        </div>

        <div className={styles.upgradeText}>
          Upgrade to the
          {' '}
          <span className={upgradeSectionStyles.premiumPlanText}>Premium plan</span>

          {mobileUpgradeReason &&
            <span className={styles.mobileUpgradeReason}>
              {mobileUpgradeReason}
            </span>
          }
        </div>
      </div>

      <div className="d-flex align-items-center my-3">
        <div className={classNames(styles.priceText, "mr-2")}>
          <span className={styles.priceAmount}>$8</span>
          {' '}
          /
          {' '}
          <span className="text-muted">month</span>
        </div>
      </div>

      <div className="small">
        {benefitsAry.map((benefitTextItem, index) =>
          <div className="d-flex mt-1" key={index}>
            <div className="flex-shrink-0 mr-3">
              <FontAwesomeIcon icon="check" className="text-muted" />
            </div>

            <div>{benefitTextItem}</div>
          </div>
        )}
      </div>

      <div className="mt-4 w-100">
        <a href="/upgrade" className="btn btn-sm btn-primary w-100" onClick={onUpgradeClicked}>
          Upgrade
        </a>
      </div>
    </div >
  )
}