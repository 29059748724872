import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";
import { Assignment } from "../../types";

interface Props {
  assignment: {
    id: number;
    instructions?: string;
    name?: string;
  },
  classNames?: string;
  onSuccess: (assignment, newAssignment: Assignment) => void;
}

export default function DuplicateAssignmentButton({ assignment, classNames = "btn btn-primary", onSuccess }: Props) {
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [shown, setShown] = useState(false);
  const [name, setName] = useState(`${assignment.name} copy`);
  const [instructions, setInstructions] = useState(assignment.instructions || '');

  const handleShow = () => setShown(true);
  const handleClose = () => setShown(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setDisabled(true);

    try {
      const { data } = await postData({
        url: `/assignments/${assignment.id}/duplicate`,
        data: { name: name, instructions: instructions }
      });

      setShown(false);

      onSuccess(assignment, data.assignment);
    } catch (e) {
      setErrors(e.response.data.errors);
    }

    setDisabled(false);
  };

  return (
    <>
      <button type="button" className={classNames} onClick={handleShow}>
        Make a Copy
      </button>

      <Modal show={shown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate This Assignment</Modal.Title>
        </Modal.Header>

        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errors && <FormErrorsList errors={errors} />}

            <Form.Group>
              <Form.Label>New Assignment Name</Form.Label>
              <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Instructions</Form.Label>
              <Form.Control as="textarea" rows={3} value={instructions} onChange={(e) => setInstructions(e.target.value)} />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="justify-content-center px-5">
            <Button type="submit" variant="primary" className="px-3" aria-label="Copy to New Assignment" disabled={disabled}>
              Duplicate Assignment
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
