import React, { createRef, useImperativeHandle, useState } from 'react';
import ReactDOM from "react-dom";

import FormErrorsList from '../shared_components/form_errors_list';
import { patchData } from "../global_functions";
import { capitalizeString } from "../string_functions";

interface Props {
  assignmentSubmissionId?: number;
  canSubmit: boolean;
  mode: 'submit' | 'unsubmit';
  onSubmitSuccess: () => void;
}

const SubmitAssignmentButton = React.forwardRef((
  { assignmentSubmissionId, mode, onSubmitSuccess, ...props }: Props,
  ref
) => {
  const [theAssignmentSubmissionId, setTheAssignmentSubmissionId] = useState(assignmentSubmissionId);
  const [isDisabled, setIsDisabled] = useState(false);
  const [canSubmit, setCanSubmit] = useState(props.canSubmit);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async () => {
    if (!theAssignmentSubmissionId || !canSubmit) return true;

    setIsDisabled(true);

    const { data } = await patchData({
      url: `/assignment_submissions/${theAssignmentSubmissionId}`,
      data: { mode: mode }
    });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      onSubmitSuccess();
    }

    setIsDisabled(false);
  };

  useImperativeHandle(ref, () => ({
    get assignmentSubmissionId() { return theAssignmentSubmissionId },
    updateAssignmentSubmissionId: (newSubmissionId: number) => {
      setTheAssignmentSubmissionId(newSubmissionId);
    },
    updateCanSubmit: (userCanSubmit: boolean) => {
      setCanSubmit(userCanSubmit);
    }
  }));

  const btnClass = (mode === "submit") ? "btn-primary" : "btn-secondary";

  return (
    <>
      <FormErrorsList errors={errors} />

      <button type="button" className={`btn btn-lg ${btnClass}`} disabled={isDisabled || !canSubmit} onClick={handleSubmit}>
        {capitalizeString(mode)} Assignment
      </button>
    </>
  );
});

export function renderSubmitAssignmentButton(elementId, options) {
  const submitAssignmentButtonRef = createRef();

  ReactDOM.render(
    <SubmitAssignmentButton
      assignmentSubmissionId={options.assignmentSubmissionId}
      canSubmit={options.canSubmit}
      mode={options.mode}
      onSubmitSuccess={options.onSubmitSuccess}
      ref={submitAssignmentButtonRef}
    />,
    document.getElementById(elementId)
  );

  return submitAssignmentButtonRef;
}