import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { patchData } from "../global_functions";

const SUPPORTED_PREFERENCE_KEYS = ["age_range", "clip_length", "no_profanity"];

interface Props {
  userId: number;
  searchFilterValues: {
    "age_range"?: Array<string>,
    "clip_length"?: Array<string>,
    "no_profanity"?: string;
  };
}

export default function SaveSearchPreferencesButton({ userId, searchFilterValues }: Props) {
  const [saved, setSaved] = useState(false);
  const [savedText, setSavedText] = useState("Saved");

  const onSaveClick = async () => {
    const settingsData = {};

    SUPPORTED_PREFERENCE_KEYS.forEach(key => {
      if (searchFilterValues[key] && searchFilterValues[key].length > 0) {
        settingsData[key] = searchFilterValues[key];
      }
    });

    onSaveAndClear(settingsData, "Saved");
  };

  const onClearClick = async () => {
    onSaveAndClear({}, "Cleared");
  };

  const onSaveAndClear = async (settingsData, theSavedText) => {
    await patchData({
      url: `/user_preferences/${userId}`,
      data: {
        user: {
          preferences: {
            search_settings: settingsData
          }
        }
      }
    });

    setSavedText(theSavedText);
    setSaved(true);

    setTimeout(() => {
      setSaved(false);
    }, 2500);
  };

  return (
    <>
      <div className="d-flex flex-wrap align-self-end align-items-center mb-3">
        <div>
          <Button variant="link" size="sm" onClick={onSaveClick}>
            Save search filters
          </Button>
        </div>

        <div className="mr-1">
          <OverlayTrigger placement="top"
            overlay={
              <Tooltip id="save_search_preferences_help">
                Saves grade level, clip length, and profanity preferences for future searches
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon="info-circle" size="sm" />
          </OverlayTrigger>
        </div>

        <div>
          <Button variant="link" size="sm" className="px-1" onClick={onClearClick}>
            <FontAwesomeIcon icon="times" size="sm" />
            <span className="sr-only">Clear filters</span>
          </Button>
        </div>

        {saved &&
          <div className="text-muted small">
            <FontAwesomeIcon icon="check" className="text-gold mr-2" />
            {savedText}
          </div>
        }
      </div>
    </>
  );
}