import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Container, Col, Form, Button, Alert } from "react-bootstrap";
import { fetchData, postData } from "../global_functions";


function JoinDiscussionContainer({ codeParam }: { codeParam: string }) {
  const removeSpaces = (roomCode) => {
    return roomCode.replace(/\s+/g, "");
  }

  const [code, setCode] = useState(removeSpaces(codeParam) || "");
  const [name, setName] = useState("");
  const [isValidCode, setCodeValidity] = useState(false);
  const [generateNicknames, setGenerateNicknames] = useState(false);
  const [error, setError] = useState(null);


  // when mount, if roomcode in param, check code validity
  useEffect(() => {
    if (code) {
      checkValidCode()
    }
  }, []);

  const checkValidCode = async (e) => {
    e?.preventDefault();
    setError(null);

    if (!code.match(/^\d{6}$/)) {
      setError("Code must be 6 digits. Please re-enter code.");
      return;
    }

    const json = await fetchData({ url: `/discussions/${code}/validate_code` });

    if (json.data.discussion_room_code) {
      setGenerateNicknames(json.data.discussion_room_code.generate_nicknames);
      setCodeValidity(true);
      if (json.data.discussion_room_code.generate_nicknames) {
        window.location.href = `/discussions/${code}/joined`
      }
    } else {
      setError(json.data.error);
    }
  }

  const enterDiscussion = async (e) => {
    e.preventDefault();
    window.location.href = `/discussions/${code}/joined?name=${name}`
  }

  return (
    <Container>
      <Col md={{ span: 6, offset: 3 }}>
        <h3 className="text-center">Join Discussion</h3>
        <hr className="my-4" />
        <Form onSubmit={isValidCode ? enterDiscussion : checkValidCode}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="code">
            <Form.Label>Enter code</Form.Label>
            <Form.Control
              onChange={(e) => setCode(removeSpaces(e.target.value))}
              defaultValue={code}
              required
              disabled={isValidCode}
            />
          </Form.Group>
          {isValidCode && !generateNicknames &&
            <Form.Group controlId="name">
              <Form.Label>Enter your name</Form.Label>
              <Form.Control onChange={(e) => setName(e.target.value)} required minLength="2" />
            </Form.Group>
          }
          <div className="text-center py-4">
            <Button variant="outline-secondary" type="submit" className="px-5">
              Join Discussion
            </Button>
          </div>
        </Form>
      </Col>
    </Container>
  )
}

export function renderJoinDiscussion(options: {
  elementId: string;
  codeParam: string;
}) {
  ReactDOM.render(
    <JoinDiscussionContainer
      codeParam={options.codeParam}
    />,
    document.getElementById(options.elementId)
  );
}
