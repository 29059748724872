import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";

import styles from "./ch_modal.module.scss";

const ModalContext = createContext({
  onHide() {}
});

interface ChModalProps {
  shown: boolean;
  onClose: () => void;
}

interface ChModalHeaderProps {
  closeButton: boolean;
}

// Source for help: https://stackoverflow.com/a/71335980/2430657
const ChModal = ({ children, shown, onClose }: PropsWithChildren<ChModalProps>) => {
  const backdropRef = useRef();

  const onBodyClick = useCallback((e) => {
    if (e.target === backdropRef.current) {
      onClose?.();
    }
  }, []);

  const modalContext = {
    onHide() { onClose() }
  };

  useEffect(() => {
    if (shown) {
      document.body.classList.add(styles.bodyPreventScrolling);
      document.body.addEventListener("click", onBodyClick);
    } else {
      document.body.classList.remove(styles.bodyPreventScrolling);
      document.body.removeEventListener("click", onBodyClick);
    }
  }, [shown]);

  if (!shown) return null;

  return (
    <ModalContext.Provider value={modalContext}>
      <div className={styles.modalBackdrop} ref={backdropRef}></div>

      <div className={styles.modal}>
        {children}
      </div>
    </ModalContext.Provider>
  );
};

ChModal.Header = ({ closeButton, children }: PropsWithChildren<ChModalHeaderProps>) => {
  const { onHide } = useContext(ModalContext);

  return (
    <div className={styles.modalHeader}>
      {closeButton &&
        <Button variant="link" className={styles.closeButton} onClick={onHide}>
          Close
        </Button>
      }

      {children}
    </div>
  );
};

ChModal.Body = ({ children }) => {
  return (
    <div className={styles.modalBody}>
      {children}
    </div>
  );
};

ChModal.Footer = ({ children }) => {
  return (
    <div className={styles.modalFooter}>
      {children}
    </div>
  );
};

export default ChModal;