import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { postData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import QuestionBank from "../discussion_question_templates/question_bank";
import { ClipType, DiscussionQuestion, DiscussionQuestionTemplate, DiscussionQuestionTemplateBlank } from "../../types";

import styles from "./add_discussion_question_form.module.scss";
import { AddQuestionTemplateForm } from "../discussion_question_templates/add_question_template_form";

type MODES = 'add_template' | null;

interface Props {
  discussableType: ClipType;
  discussableId: number;
  isPremium: boolean;
  onCreate: (discussionQuestion: DiscussionQuestion) => void;
  onCancel: () => void;
}

const DEFAULT_PRIVACY = 'public';

export default function AddDiscussionQuestionForm({ discussableType, discussableId, isPremium, ...props }: Props) {
  const [prompt, setPrompt] = useState('');
  const [privacy, setPrivacy] = useState(DEFAULT_PRIVACY);
  const [isDisabled, setIsDisabled] = useState(false);

  const [selectedQuestionTemplate, setSelectedQuestionTemplate] = useState<DiscussionQuestionTemplate>();
  const [questionBlankTexts, setQuestionBlankTexts] = useState({});

  const [mode, setMode] = useState<MODES>();
  const [errors, setErrors] = useState([]);

  const questionBankRef = useRef();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    }
  }, []);

  const sendCreateQuestionRequest = async () => {
    let idKey;
    let path;

    if (discussableType === "Resource") {
      idKey = "resource_id";
      path = "/resource_discussion_questions";
    } else {
      idKey = "user_clip_id";
      path = "/user_clip_discussion_questions";
    }

    return postData({
      url: path,
      data: {
        discussion_question: {
          prompt: prompt,
          privacy: privacy,
          [idKey]: discussableId
        }
      }
    });
  };

  const sendUseQuestionTemplateRequest = async () => {
    return postData({
      url: `/discussion_question_templates/${selectedQuestionTemplate.id}/use`,
      data: {
        discussable_type: discussableType,
        discussable_id: discussableId,
        privacy: privacy,
        question_blanks: questionBlankTexts
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let data;

    setErrors([]);
    setIsDisabled(true);

    try {
      let response;

      if (selectedQuestionTemplate) {
        response = await sendUseQuestionTemplateRequest();
      } else {
        response = await sendCreateQuestionRequest();
      }

      data = response.data;

      props.onCreate(data.discussion_question);
    } catch (e) {
      data = e.response.data;

      if (data.errors) {
        setErrors(data.errors);
      }
    } finally {
      if (mountedRef.current) {
        setIsDisabled(false);
      }
    }
  };

  const onChangeQuestionTemplate = (questionTemplate: DiscussionQuestionTemplate) => {
    setSelectedQuestionTemplate(questionTemplate);
    setQuestionBlankTexts({});
  };

  const onChangeQuestionBlankText = (e, questionBlank: DiscussionQuestionTemplateBlank) => {
    const newQuestionBlankTexts = { ...questionBlankTexts, [questionBlank.char_position]: e.target.value };
    setQuestionBlankTexts(newQuestionBlankTexts);
  };

  const onCreateQuestionTemplate = (questionTemplate: DiscussionQuestionTemplate, numTemplatesRemaining: number) => {
    if (questionBankRef && questionBankRef.current) {
      questionBankRef.current.appendUserTemplate(questionTemplate);
      questionBankRef.current.updateRemainingTemplates(numTemplatesRemaining);
    }

    setMode(null);
  };

  return (
    <>
      {errors?.length > 0 &&
        <div className="p-3">
          <FormErrorsList errors={errors} />
        </div>
      }

      <div>
        <div className="p-3">
          <h5 className="mb-0">Add a Clip Question</h5>
        </div>

        <hr className="m-0" />

        <div className="row">
          <div className="col-md-7 border-right">
            <div className="p-3">
              <QuestionBank
                discussableType={discussableType}
                discussableId={discussableId}
                isPremium={isPremium}
                onSelected={onChangeQuestionTemplate}
                onAddTemplateClicked={() => setMode('add_template')}
                ref={questionBankRef}
              />
            </div>
          </div>

          <div className="col-md-5">
            <div className="p-3">
              {mode === "add_template" &&
                <AddQuestionTemplateForm
                  onCreate={onCreateQuestionTemplate}
                  onCancel={() => setMode(null)}
                />
              }

              {mode == null &&
                <>
                  <h5>{selectedQuestionTemplate?.prompt || "Add a question"}</h5>

                  {selectedQuestionTemplate?.blanks?.length > 0 &&
                    <div>
                      <p className="mt-3 mb-1 text-muted">Fill in the blanks</p>

                      {selectedQuestionTemplate.blanks.map((questionBlank: DiscussionQuestionTemplateBlank) =>
                        <div className="mb-3" key={questionBlank.id}>
                          <Form.Control className="w-100" onChange={e => onChangeQuestionBlankText(e, questionBlank)} />
                        </div>
                      )}
                    </div>
                  }

                  {!selectedQuestionTemplate &&
                    <Form.Control as="textarea" rows={3} placeholder="Write a prompt" onChange={e => setPrompt(e.target.value)} />
                  }
                </>
              }
            </div>
          </div>
        </div>

        <hr className="m-0" />

        <div className={classNames("p-3", styles.formFooter)}>
          <Form className="d-flex flex-row flex-wrap" onSubmit={onSubmit}>
            <Button variant="outline-secondary" size="sm" className="px-3" onClick={props.onCancel}>
              Cancel
            </Button>

            <div className="flex-grow-1"></div>

            <Form.Control as="select" size="sm" className="w-25 mr-3" onChange={e => setPrivacy(e.target.value)}>
              <option value="public">Public</option>
              <option value="private">Private</option>
            </Form.Control>

            <Button type="submit" variant="primary" size="sm" className="px-3" disabled={isDisabled}>
              Add Question
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}