import React from "react";

import { Subject } from "../../types";

interface SubjectObj {
  subject: Subject;
  url: string;
}

interface Props {
  subjectObj: SubjectObj
  shouldDowncase?: boolean;
}

export default function DisplaySubject({ subjectObj, shouldDowncase = true }: Props) {
  const subjectName = shouldDowncase ? subjectObj.subject.name.toLowerCase() : subjectObj.subject.name;

  return (
    <div className="d-inline-block display-tag">
      <a href={subjectObj.url} className="text-reset">{subjectName}</a>
    </div>
  )
}
