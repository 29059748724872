import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Select from 'react-select';

import Nav from "react-bootstrap/Nav";

import { timestampFromSeconds } from "../time_functions";
import { CUSTOM_TABLE_STYLES } from "../discussion_questions/constants_and_types";
import { AssignmentItem, Comment } from "../../types";

import styles from "./assignments_report.module.scss";

interface Props {
  questionsResponsesObj: { [assignment_id: number]: AssignmentItem },
}

interface AssignmentItemTabProps {
  assignmentItem: AssignmentItem;
}

const MAX_PROMPT_CHARACTERS_TO_SHOW = 20;

function assignmentItemPrompt(assignmentItem: AssignmentItem, shorten: boolean = false) {
  let prompt = assignmentItem.assignable.prompt;

  if (shorten && prompt.length > MAX_PROMPT_CHARACTERS_TO_SHOW) {
    prompt = `${prompt.substring(0, MAX_PROMPT_CHARACTERS_TO_SHOW)}...`;
  }

  return prompt;
}

function AssignmentItemTab({ assignmentItem }: AssignmentItemTabProps) {
  return (
    <>
      {assignmentItem.assignable_type === "PausePrompt" &&
        <div>
          {timestampFromSeconds(assignmentItem.assignable.start_time)}
        </div>
      }

      <div>
        {assignmentItemPrompt(assignmentItem, true)}
      </div>
    </>
  );
}

export function AssignmentsReportResponsesTable({ questionsResponsesObj }: Props) {
  const [selectedAssignmentItem, setSelectedAssignmentItem] = useState<AssignmentItem>();
  const [selectedStudentNames, setSelectedStudentNames] = useState([]);

  const allStudentNames = useMemo(() => {
    let studentNamesObj = {};

    Object.values(questionsResponsesObj).map((assignmentItem: AssignmentItem) => {
      assignmentItem.comments.forEach((comment: Comment) => {
        studentNamesObj[comment.user.displayName] = true;
      });
    });

    return Object.keys(studentNamesObj).sort();
  }, []);

  const allStudentNameOptions = useMemo(() => allStudentNames.map(studentName => ({ label: studentName, value: studentName })), []);

  const selectedComments = useMemo(() => {
    if (!selectedAssignmentItem) return [];

    let theComments = selectedAssignmentItem.comments.sort((commentA: Comment, commentB: Comment) => {
      return commentA.user.displayName.localeCompare(commentB.user.displayName);
    });

    if (selectedStudentNames.length > 0) {
      theComments = theComments.filter((comment: Comment) => selectedStudentNames.includes(comment.user.displayName));
    }

    return theComments;
  }, [selectedAssignmentItem, selectedStudentNames]);

  const questionResponseColumns = useMemo(() => {
    return [
      {
        name: "Student Name",
        selector: (comment: Comment) => comment.user.displayName,
        sortable: true,
        wrap: true
      },
      {
        name: "Response",
        selector: comment => comment.comment_text,
        sortable: true,
        wrap: true
      }
    ];
  }, []);

  const handleFilterStudents = (ary) => {
    const theAry = ary || [];
    const studentNames = theAry.map(studentNameObj => studentNameObj.value);

    setSelectedStudentNames(studentNames);
  };

  return (
    <div className="row">
      <div className="col-md-3 border-right">
        <h4 className="text-center pt-4 mb-3">Questions</h4>

        <div className={styles.questionResponsesSection}>
          <Nav variant="pills">
            {
              Object.values(questionsResponsesObj).map((assignmentItem, index) => {
                const isActive = (assignmentItem.id === selectedAssignmentItem?.id);

                return (
                  <Nav.Item key={index} className="rounded border mb-3 w-100">
                    <Nav.Link active={isActive} onClick={() => setSelectedAssignmentItem(assignmentItem)}>
                      <AssignmentItemTab assignmentItem={assignmentItem} />
                    </Nav.Link>
                  </Nav.Item>
                )
              })
            }
          </Nav>
        </div>
      </div>

      <div className="col-md-9">
        <h4 className="pt-4 mb-3">Student Responses</h4>

        {!selectedAssignmentItem && <h5>Select a question to view student responses</h5>}

        {selectedAssignmentItem &&
          <>
            <p>Question: {assignmentItemPrompt(selectedAssignmentItem, false)}</p>

            <div className="my-3">
              {/* Set z-index to 2 so it is displayed above the table header (z-index: 1) */}
              <Select
                id="assignments_report_responses_filter_students"
                onChange={handleFilterStudents}
                options={allStudentNameOptions}
                isMulti
                closeMenuOnSelect={false}
                isDisabled={false}
                placeholder="Filter by student"
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </div>

            <div>
              <DataTable
                customStyles={CUSTOM_TABLE_STYLES}
                columns={questionResponseColumns}
                data={selectedComments}
                noDataComponent={"No responses to display"}
                fixedHeader
              />
            </div>
          </>
        }
      </div>
    </div>
  );
}