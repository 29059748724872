import React from "react";
import classNames from "classnames";

import "./resource_thumbnail.scss";

interface ResourceObj {
  resource: object;
  title: string;
}

interface Props {
  resourceObj: ResourceObj;
  extraClasses?: string;
}

export default function ResourceThumbnail({ resourceObj, extraClasses }: Props) {
  return (
    <div>
      {resourceObj && resourceObj.resource?.thumbnail_url ?
        <img src={resourceObj.resource.thumbnail_url}
          alt={`${resourceObj.title} thumbnail`}
          className={classNames("resource-thumbnail", extraClasses)}
        />
        :
        <div className={classNames("search-thumbnail-error", extraClasses)}>
          <div>No Preview available</div>
        </div>
      }
    </div>
  )
}
