import Swiper, { Navigation, Pagination } from "swiper";
import { SwiperOptions } from "swiper/types/swiper-options";

// Docs: https://v9.swiperjs.com/get-started
import "swiper/swiper-bundle.min.css";
import "./ch_swiper.scss";

export function renderChSwiper(container: string | HTMLElement, options: SwiperOptions) {
  const swiper = new Swiper(container, {
    ...options,
    modules: [Navigation, Pagination]
  });

  return swiper;
}