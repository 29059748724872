import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { postData } from "../global_functions";

interface Props {
  email: string;
  onEmailVerified: () => void;
  onNavigateBack: () => void;
}

export default function VerifyEmail({ email, onEmailVerified, onNavigateBack }: Props) {
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [resentMessage, setResentMessage] = useState('');

  const isDisabled = (verificationCode.length < 6) || isLoading;
  const mountedRef = useRef(true);

  useEffect(() => {
    sendVerificationCode();

    return () => {
      mountedRef.current = false;
    }
  }, []);

  const sendVerificationCode = useCallback(async () => {
    try {
      await postData({
        url: "/account_verifications/send_code",
        data: { email: email }
      });
    } catch (e) {
      let theError = e.response.data.errors && e.response.data.errors[0];
      if (!theError) theError = "There was an error. Please try again.";

      setError(theError);
    }
  }, []);

  const onResendCode = useCallback(async (e) => {
    e.preventDefault();

    sendVerificationCode();

    setResentMessage("Verification code resent.");
    setTimeout(() => setResentMessage(''), 4000);
  }, []);

  const onBackClicked = useCallback((e) => {
    e.preventDefault();

    onNavigateBack();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) return;

    setIsLoading(true);

    try {
      const { data } = await postData({
        url: `/account_verifications/${verificationCode}/check`,
        data: { email: email }
      });

      if (data.success) {
        onEmailVerified();
      }
    } catch (e) {
      let theError = e.response.data.errors && e.response.data.errors[0];
      if (!theError) theError = "There was an error. Please try again.";

      setError(theError);
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="text-center mb-3">
        <FontAwesomeIcon icon="envelope" size="2x" />
      </div>

      <h5 className="text-center">Enter Verification Code</h5>

      <div className="text-center my-4">
        <p>
          Enter the confirmation code we sent to {email}. If you don't see it in your inbox, check
          your spam folder.
          {' '}
          <a href="#" className="small font-weight-bold" onClick={onResendCode}>Resend Code</a>
        </p>

        {resentMessage &&
          <div className="text-center text-success font-weight-bold">
            Verification code resent!
          </div>
        }
      </div>

      <form onSubmit={onSubmit}>
        <input type="text" className="form-control" placeholder="Verification Code" onChange={e => setVerificationCode(e.target.value)} />

        <button type="submit" className="btn btn-primary font-weight-bold w-100 mt-3" disabled={isDisabled}>
          Create Account
        </button>
      </form>

      <div className="text-center my-3">
        <a href="#" onClick={onBackClicked}>Go Back</a>
      </div>

      {error && <div className="text-center text-red">{error}</div>}
    </div>
  );
}