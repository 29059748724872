import React, { useState } from "react";
import ReactDOM from "react-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from "uuid";

import FormErrorsList from "../shared_components/form_errors_list";
import CopyButton from "../shared_components/copy_button";
import { postData } from "../global_functions";
import { Assignment, Course } from "../../types";

const DATETIME_FORMAT = "YYYY-MM-DD";

interface Props {
  defaultInstructions?: string;
  courses: Array<Course>;
  assignable: {
    id: number;
    assignableType: string;
  },
  onSuccess?: (assignment: Assignment) => void;
  onClose?: (assignment: Assignment) => void;
  showSuccessMessage: boolean;
}

function NewAssignmentForm({
  defaultInstructions = '',
  courses,
  assignable,
  onSuccess,
  onClose,
  showSuccessMessage = true
}: Props) {
  // Needed to easily reset the state after submission
  const initialState = {
    name: '',
    instructions: defaultInstructions,
    dueDate: '',
    playAfterPrompt: true,
    preventSkipAhead: false,
    courseIds: [],
    isDisabled: false,
    errors: [],
    createdAssignment: null
  };

  const [state, setState] = useState(initialState);

  const courseIdOptions = courses.map(course => {
    return { label: course.name, value: course.id };
  });

  const onChangeCourseIds = (courseOptions) => {
    let newCourseIds = courseOptions || [];
    newCourseIds = newCourseIds.map(courseOption => courseOption.value);

    setState({ ...state, courseIds: newCourseIds });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setState({ ...state, isDisabled: true, errors: [] });

    try {
      const { data } = await postData({
        url: "/assignments",
        data: {
          assignment: {
            name: state.name,
            instructions: state.instructions,
            due_date: state.dueDate,
            assignable_id: assignable.id,
            assignable_type: assignable.assignableType,
            course_ids: state.courseIds,
            play_after_prompt: state.playAfterPrompt,
            prevent_skip_ahead: state.preventSkipAhead
          }
        }
      });

      if (data.success) {
        onSuccess?.(data.assignment);

        if (showSuccessMessage) {
          setState({ ...state, isDisabled: false, createdAssignment: data.assignment });
        }
      }
    } catch (e) {
      const errors = e.response.data.errors;

      setState({ ...state, isDisabled: false, errors: errors });
    }
  };

  const handleOnClose = (e) => {
    onClose?.(createdAssignment);
    setState(initialState);
  };

  const buttonDisabled = state.isDisabled || state.name.length < 2 || state.instructions.length < 2;
  const copyInputId = `new-assignment-input-${uuid()}`;

  const { createdAssignment, errors, dueDate } = state;

  const handleDateApply = (ev, picker) => setState({ ...state, dueDate: picker.startDate.format(DATETIME_FORMAT) });
  const handleDateCancel = (ev, picker) => setState({ ...state, dueDate: '' });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      {!createdAssignment &&
        <>
          <FormErrorsList errors={errors} />

          <Form onSubmit={onSubmit}>
            <Row>
              <div className="form-group col-md-12">
                <label htmlFor="new_assignment_name">Assignment Name *</label>
                <input
                  id="new_assignment_name"
                  className="form-control"
                  type="text"
                  onChange={e => setState({ ...state, name: e.target.value })}
                />
              </div>
            </Row>

            <Row>
              <div className="form-group col-md-12">
                <label htmlFor="new_assignment_instructions">Instructions *</label>
                <textarea
                  id="new_assignment_instructions"
                  className="form-control"
                  rows={3}
                  defaultValue={state.instructions}
                  onChange={e => setState({ ...state, instructions: e.target.value })}
                />
              </div>
            </Row>

            <Row>
              <div className="form-group col-md-12">
                <div className="d-flex flex-wrap align-items-center">
                  <Form.Check
                    id="new_assignment_play_after_prompt"
                    className="mr-2"
                    label="Continue video after students submit an answer"
                    defaultChecked={state.playAfterPrompt}
                    onChange={e => setState({ ...state, playAfterPrompt: e.target.checked })}
                  />

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="assignment-form-play-after-prompt-tooltip">
                        If unchecked, students will need to press the play button
                        to continue the video after submitting a response to a question.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon="info-circle" className="text-primary" />
                  </OverlayTrigger>
                </div>
              </div>
            </Row>

            <Row>
              <div className="form-group col-md-12">
                <div className="d-flex flex-wrap align-items-center">
                  <Form.Check
                    id="new_assignment_prevent_skip_ahead"
                    className="mr-2"
                    label="Prevent students from skipping past questions"
                    defaultChecked={state.preventSkipAhead}
                    onChange={e => setState({ ...state, preventSkipAhead: e.target.checked })}
                  />

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="assignment-form-prevent-skip-ahead-tooltip">
                        If checked, students will not be able to go past a question until they answer it
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon="info-circle" className="text-primary" />
                  </OverlayTrigger>
                </div>
              </div>
            </Row>

            <Row>
              <div className="form-group col-md-12">
                <div>
                  <label htmlFor="new_assignment_course_ids" className="mb-0">
                    Assign to specific classes
                  </label>
                  {' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="assignment-form-course-ids-tooltip">
                        If you don't specify a class, any student can join.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon="info-circle" className="text-primary" />
                  </OverlayTrigger>
                </div>

                <div className="mb-2">
                  <a href="/courses" target="_blank" className="small" rel="noopener noreferrer">
                    Need to add a class?
                  </a>
                </div>

                <Select
                  id="new_assignment_course_ids"
                  onChange={onChangeCourseIds}
                  options={courseIdOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isDisabled={false}
                />
              </div>
            </Row>

            <Row>
              <div className="form-group col-md-12">
                <label htmlFor="new_assignment_due_date">Due Date</label>
                <DateRangePicker
                  initialSettings={{
                    minDate: tomorrow,
                    autoUpdateInput: false,
                    singleDatePicker: true,
                    drops: 'up',
                    locale: { cancelLabel: "Clear", format: DATETIME_FORMAT }
                  }}
                  onHide={handleDateApply}
                  onApply={handleDateApply}
                  onCancel={handleDateCancel}
                >
                  <input type="text" className="form-control" value={dueDate} onChange={e => setState({ ...state, dueDate: e.target.value })} />
                </DateRangePicker>
              </div>
            </Row>

            <div className="text-center">
              <Button type="submit" variant="primary" className="px-5" disabled={buttonDisabled}>
                Submit
              </Button>
            </div>
          </Form>
        </>
      }

      {createdAssignment &&
        <>
          <div className="mb-3">Your assignment was created!</div>

          <div className="font-weight-bold">Access it here</div>
          <a href={createdAssignment.url}>{createdAssignment.url}</a>

          <div className="font-weight-bold mt-3 mb-1">Link for Students</div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon="link" size="1x" />
                <span className="sr-only">Link</span>
              </span>
            </div>

            <Form.Control id={copyInputId} type="text" value={createdAssignment.student_url} readOnly={true} />

            <div className="input-group-append">
              <CopyButton targetElementId={copyInputId} />
            </div>
          </div>

          <div className="text-center">
            <Button type="submit" variant="secondary" className="mt-4 px-5" onClick={handleOnClose}>
              Close
            </Button>
          </div>
        </>
      }
    </>
  );
}

export function renderNewAssignmentForm(elementId, options) {
  const node = ReactDOM.render(
    <NewAssignmentForm
      defaultInstructions={options.defaultInstructions}
      courses={options.courses}
      assignable={options.assignable}
      onSuccess={options.onSuccess}
      onClose={options.onClose}
      showSuccessMessage={options.showSuccessMessage}
    />,
    document.getElementById(elementId)
  );

  return node;
}