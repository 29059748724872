import React from "react";
import {
  Button
} from "react-bootstrap";

import { AgeRange } from "../../types";

interface Props {
  ageRanges: Array<AgeRange>;
  size?: "sm" | "lg";
  baseKey?: string;
}

export default function DisplayAgeRange({ ageRanges, size, baseKey }: Props) {
  if (ageRanges?.length < 1) {
    return null;
  }

  return (
    <>
      {ageRanges.map((ageRange, index) => {
        const btnClass = `btn-grade-level-${ageRange.abbreviation.toLowerCase()}`;
        const key = `${baseKey || ''}${index}`;

        return (
          <React.Fragment key={key}>
            <Button className={`btn ${btnClass}`} size={size} title={ageRange.name}>
              {ageRange.abbreviation}
            </Button>
            {' '}
          </React.Fragment>
        );
      })}
    </>
  );
}
