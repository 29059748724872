import React, { useState } from "react";

import "./styles.scss";

export interface Comment {
  anonymous: boolean;
  comment_text: string;
  id: Number;
  is_hidden: boolean;
  user: {
    display_name: string;
    profile_image: {
      url: string;
    }
  };
}

interface Props {
  comment: Comment;
}

export function PausePromptComment({ comment }: Props) {
  const { user } = comment;

  return (
    <div className="d-flex" key={`${comment.id}`}>
      <div className="mr-3">
        <div className="profile_image rounded-circle" style={{ backgroundImage: `url('${user.profile_image.url}')` }}></div>
      </div>

      {/* The 'w-100' class is a fix for text wrapping in IE 11 */}
      <div className="d-flex flex-column flex-grow-1 w-100">
        <div className="d-flex flex-row flex-wrap mb-1">
          <h6 className="font-weight-bold mb-0 mr-2">
            {user.display_name}
          </h6>
        </div>

        <div id={`comment-${comment.id}-text`}>
          {comment.comment_text}
        </div>
      </div>
    </div>
  );
}