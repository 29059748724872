import React, { createRef, forwardRef, useImperativeHandle, useState, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/DropdownItem";

import AddDiscussionQuestionForm from "./add_discussion_question_form";
import EditDiscussionQuestionForm from "./edit_discussion_question_form";
import { focusVideoPlayerAddEditMode } from "../resources/discussion_tools_section";

import { chAnalyticsTrackEvent } from "../analytics";
import { deleteData, postData } from "../global_functions";
import { capitalizeString } from "../string_functions";
import { ClipType, DiscussionQuestion, User } from "../../types";
import { MODES, UPDATE_ROW_HIGHLIGHT_DURATION, CUSTOM_TABLE_STYLES } from "./constants_and_types";
import DiscussionQuestionContext from "./discussion_question_context";

import styles from "../shared_components/discussion_tools.module.scss";

interface Props {
  isPremium: boolean;
  discussableType: ClipType;
  discussableId: number;
  discussionQuestions: Array<DiscussionQuestion>;
  onUseAsPausePrompt: (discussionQuestion: DiscussionQuestion) => void;
  user: User;
  mode?: MODES;
}

function isAiGeneratedQuestion(question: DiscussionQuestion) {
  return (!question.user && !question.user_id);
}

export const DiscussionQuestionsTable = forwardRef((props: Props, ref) => {
  const [discussionQuestions, setDiscussionQuestions] = useState<Array<DiscussionQuestion>>([...props.discussionQuestions]);
  const [updatedDiscussionQuestionId, setUpdatedDiscussionQuestionId] = useState<number>();
  const [selectedDiscussionQuestion, setSelectedDiscussionQuestion] = useState<DiscussionQuestion>();
  const [mode, setMode] = useState<MODES>(props.mode);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    setActiveMode: (mode: MODES) => {
      setMode(mode);
    },
    toggleAddClicked: (buttonClicked: string = "add_button") => {
      onAddQuestionClick(buttonClicked);
    }
  }));

  const contextData = useMemo(() => {
    return { user: props.user };
  }, []);

  const tableColumns = useMemo(() => [
    {
      name: "Question",
      selector: (question: DiscussionQuestion) => question.prompt,
      sortable: true,
      wrap: true,
      grow: 1
    },
    {
      name: "Owner",
      sortable: true,
      wrap: true,
      grow: 0,
      minWidth: "130px",
      selector: (question: DiscussionQuestion) => {
        let ownerName = "Community";

        if (isAiGeneratedQuestion(question)) {
          ownerName = (
            <span>
              <FontAwesomeIcon icon="magic" />
              {' '}
              ClassHook Generated
            </span>
          )
        } else if (props.user.id === question.user_id) {
          ownerName = "You";
        }

        return ownerName;
      }
    },
    {
      name: "Visibility",
      selector: (question: DiscussionQuestion) => capitalizeString(question.privacy),
      sortable: true,
      wrap: true,
      grow: 0,
      minWidth: "125px"
    },
    {
      grow: 0,
      center: true,
      width: "70px",
      cell: (question: DiscussionQuestion) => {
        const actionMenuId = `discussion_question_${question.id}_actions`;
        const isCreator = (props.user.id === question.user_id);
        const isGeneratedQuestion = isAiGeneratedQuestion(question);

        return (
          <Dropdown>
            <Dropdown.Toggle id={actionMenuId} variant="link" size="sm" className={styles.dropdownToggle}>
              <FontAwesomeIcon icon="ellipsis-h" className="text-muted" />
              <span className="sr-only">Actions</span>
            </Dropdown.Toggle>

            <Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
              {(isGeneratedQuestion || !isCreator) &&
                <DropdownItem onClick={() => onSaveQuestion(question)}>Save As My Question</DropdownItem>
              }

              {isCreator &&
                <>
                  <DropdownItem onClick={() => onUseAsPausePromptClick(question)}>Use as Pause Prompt</DropdownItem>
                  <DropdownItem onClick={() => onEditQuestion(question)}>Edit</DropdownItem>
                </>
              }

              {(isGeneratedQuestion || isCreator || props.user.is_admin) &&
                <DropdownItem onClick={() => onDeleteQuestion(question)}>Delete</DropdownItem>
              }
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ], [discussionQuestions]);

  useEffect(() => {
    if (!updatedDiscussionQuestionId) return;

    setTimeout(() => {
      setUpdatedDiscussionQuestionId(null);
    }, UPDATE_ROW_HIGHLIGHT_DURATION);
  }, [updatedDiscussionQuestionId]);

  useEffect(() => {
    focusVideoPlayerAddEditMode(mode, styles.addOrEditModeHighlight);

    document.body.removeEventListener("keyup", onKeyUp);

    if (mode != null) {
      document.body.addEventListener("keyup", onKeyUp);
    }
  }, [mode]);

  const onKeyUp = useMemo(() => {
    return (e) => {
      if (e.key === "Escape") {
        onCancelEdit();
      }
    };
  }, []);

  const onCancelEdit = () => {
    setMode(null);
    setSelectedDiscussionQuestion(null);
  };

  const onCreateQuestion = (newDiscussionQuestion: DiscussionQuestion) => {
    setDiscussionQuestions([...discussionQuestions, newDiscussionQuestion]);
    setMode(null);
    setUpdatedDiscussionQuestionId(newDiscussionQuestion.id);
  };

  const onUseAsPausePromptClick = (discussionQuestion: DiscussionQuestion) => {
    chAnalyticsTrackEvent("click", {
      clip_id: props.discussableId,
      clip_type: props.discussableType,
      model_id: discussionQuestion.id,
      model_type: "DiscussionQuestion",
      link: "use_as_pause_prompt",
      label: "discussion_questions_table"
    });

    props.onUseAsPausePrompt(discussionQuestion);
  };

  const onEditQuestion = (discussionQuestion: DiscussionQuestion) => {
    chAnalyticsTrackEvent("click", {
      clip_id: props.discussableId,
      clip_type: props.discussableType,
      model_id: discussionQuestion.id,
      model_type: "DiscussionQuestion",
      link: "edit_discussion_question",
      label: "discussion_questions_table"
    });

    setSelectedDiscussionQuestion(discussionQuestion);
    setMode("edit");
  };

  const onDeleteQuestion = async (discussionQuestionToDelete: DiscussionQuestion) => {
    if (isAiGeneratedQuestion(discussionQuestionToDelete)) {
      const updatedQuestions = [...discussionQuestions].filter(question => question.id !== discussionQuestionToDelete.id);
      setDiscussionQuestions(updatedQuestions);
    } else {
      let urlPath = (props.discussableType === "Resource") ? "/resource_discussion_questions" : "/user_clip_discussion_questions";

      const { data } = await deleteData({ url: `${urlPath}/${discussionQuestionToDelete.id}` });
      const updatedQuestions = [...discussionQuestions].filter(question => question.id !== data.discussion_question.id);

      setDiscussionQuestions(updatedQuestions);
    };
  }

  const onUpdateQuestion = (updatedDiscussionQuestion: DiscussionQuestion) => {
    const existingQuestionIndex = discussionQuestions.findIndex(question => question.id === updatedDiscussionQuestion.id);

    const updatedQuestions = [...discussionQuestions];
    updatedQuestions.splice(existingQuestionIndex, 1, updatedDiscussionQuestion);

    setDiscussionQuestions(updatedQuestions);
    setMode(null);
    setUpdatedDiscussionQuestionId(updatedDiscussionQuestion.id);
  };

  const generateQuestions = async () => {
    if (props.discussableType !== "Resource") return;

    setIsLoading(true);

    chAnalyticsTrackEvent("click", {
      model_id: props.discussableId,
      model_type: props.discussableType,
      link: "generate_questions",
      label: "discussion_questions_table"
    });

    let newQuestions = [];

    try {
      const response = await postData({ url: `/resources/${props.discussableId}/generate_questions` });

      newQuestions = response.data.discussion_questions || [];
    } catch (e) {
      // No-op
    }

    const updatedQuestions = [...discussionQuestions].filter(question => !isAiGeneratedQuestion(question));

    setDiscussionQuestions([...newQuestions, ...updatedQuestions]);
    setIsLoading(false);
  };

  const onSaveQuestion = async (existingDiscussionQuestion: DiscussionQuestion) => {
    const response = await sendCreateQuestionRequest(existingDiscussionQuestion);
    const existingQuestionIndex = discussionQuestions.findIndex(question => question === existingDiscussionQuestion);
    const newDiscussionQuestion = response.data.discussion_question;

    const updatedQuestions = [...discussionQuestions];

    if (isAiGeneratedQuestion(existingDiscussionQuestion)) {
      // Replace the AI generated question
      updatedQuestions.splice(existingQuestionIndex, 1, newDiscussionQuestion);
    } else {
      // Add the user's question before the community one
      updatedQuestions.splice(existingQuestionIndex, 2, newDiscussionQuestion, existingDiscussionQuestion);
    }

    chAnalyticsTrackEvent("click", {
      clip_id: props.discussableId,
      clip_type: props.discussableType,
      created_model_id: newDiscussionQuestion.id,
      created_model_type: "DiscussionQuestion",
      model_id: existingDiscussionQuestion.id,
      model_type: "DiscussionQuestion",
      is_ai_generated: isAiGeneratedQuestion(existingDiscussionQuestion),
      link: "save_question",
      label: "discussion_questions_table"
    });

    setDiscussionQuestions(updatedQuestions);
    setMode(null);
    setUpdatedDiscussionQuestionId(newDiscussionQuestion.id);
  }

  const sendCreateQuestionRequest = async (newDiscussionQuestion: DiscussionQuestion) => {
    return postData({
      url: "/resource_discussion_questions",
      data: {
        discussion_question: {
          prompt: newDiscussionQuestion.prompt,
          privacy: "public",
          resource_id: newDiscussionQuestion.discussable_id
        }
      }
    });
  };

  const onAddQuestionClick = (buttonClicked: string = "discussion_questions_table_add_button") => {
    chAnalyticsTrackEvent("click", {
      model_id: props.discussableId,
      model_type: props.discussableType,
      link: "add_discussion_question",
      label: "discussion_questions_table",
      button_clicked: buttonClicked
    });

    setMode("add");
  };

  return (
    <>
      {mode != null &&
        <div className="modal-backdrop show"></div>
      }

      <div className={classNames("content-container", mode != null ? styles.addOrEditModeHighlight : null)}>
        {mode === "add" &&
          <DiscussionQuestionContext.Provider value={contextData}>
            <AddDiscussionQuestionForm
              discussableId={props.discussableId}
              discussableType={props.discussableType}
              isPremium={props.isPremium}
              onCreate={onCreateQuestion}
              onCancel={() => setMode(null)}
            />
          </DiscussionQuestionContext.Provider>
        }

        {mode === "edit" &&
          <EditDiscussionQuestionForm
            discussionQuestion={selectedDiscussionQuestion}
            onUpdate={onUpdateQuestion}
            onCancel={() => onCancelEdit()}
          />
        }

        {mode == null &&
          <>
            <div className={classNames(styles.tableContainer, "px-3", "py-2")}>
              <div className="d-flex flex-wrap align-items-center">
                <h5 className="mr-3 my-1">Clip Questions</h5>
                <div className="flex-grow-1"></div>

                {props.discussableType === "Resource" &&
                  <Button variant="outline-secondary" size="sm" className="mr-3 my-1" disabled={isLoading} onClick={generateQuestions}>
                    <FontAwesomeIcon icon="redo-alt" />
                    {' '}
                    Generate Questions
                  </Button>
                }

                <Button variant="outline-secondary" size="sm" className="my-1" onClick={() => onAddQuestionClick()}>
                  <FontAwesomeIcon icon="plus" />
                  {' '}
                  Add a Question
                </Button>
              </div>
            </div>

            <DataTable
              columns={tableColumns}
              customStyles={CUSTOM_TABLE_STYLES}
              conditionalRowStyles={[
                {
                  when: (question: DiscussionQuestion) => updatedDiscussionQuestionId && (question.id === updatedDiscussionQuestionId),
                  classNames: [styles.highlightedRow]
                }]
              }
              data={discussionQuestions}
              noDataComponent={
                <div className="p-3 text-center">
                  <div className="mb-2">
                    <FontAwesomeIcon icon="inbox" size="2x" />
                  </div>

                  <div>
                    There's nothing here! Generate or add your own questions above.
                  </div>
                </div>
              }
              responsive
              fixedHeader
            />
          </>
        }
      </div>
    </>
  );
});

export function renderDiscussionQuestionsTable(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <DiscussionQuestionsTable
      isPremium={options.isPremium}
      discussableId={options.discussableId}
      discussableType={options.discussableType}
      discussionQuestions={options.discussionQuestions}
      onUseAsPausePrompt={options.onUseAsPausePrompt}
      user={options.user}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}