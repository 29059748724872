import React, { useReducer, useState } from "react";
import ReactDOM from "react-dom";
import Form from 'react-bootstrap/Form';

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";

import { User, VideoQuestion } from "../../types";

interface Props {
  questionableId: number;
  questionableType: string;
  currentUser: User;
  onCreateSuccess?: (videoQuestion: VideoQuestion) => void;
}

export function NewVideoQuestionForm({ questionableId, questionableType, currentUser, onCreateSuccess }: Props) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onCreate = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data;

    // @tsignore - From vanilla JS
    const player = GetCurrentVideoPlayer();
    const time = player?.currentTime || 0;

    try {
      const response = await postData({
        url: "/video_questions",
        data: {
          time: time,
          questionable_id: questionableId,
          questionable_type: questionableType,
          user_name: currentUser.name
        }
      });

      data = response.data;
    } catch (e) {
      data = e.response.data;
    }

    if (data.errors) {
      setErrors(data.errors);
    } else if (data.video_question) {
      onCreateSuccess(data.video_question);
    }

    setIsLoading(false);
  };

  return (
    <>
      <FormErrorsList errors={errors} />

      <Form onSubmit={onCreate}>
        <button type="submit" className="btn btn-outline-secondary" disabled={isLoading}>
          I have a question
        </button>
      </Form>
    </>
  );
}

export function renderNewVideoQuestionForm(elementId, options) {
  const node = ReactDOM.render(
    <NewVideoQuestionForm
      questionableId={options.questionableId}
      questionableType={options.questionableType}
      currentUser={options.currentUser}
    />,
    document.getElementById(elementId)
  );

  return node;
}
