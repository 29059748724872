import qs from "qs";
import React, { useCallback, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

import SaveSearchPreferencesButton from "./save_search_preferences_button";
import UpgradeButton from "../shared_components/upgrade_button";
import { AgeRange, Genre, Series, Standard, Subject, Tag } from "../../types";
import { chAnalyticsTrackEvent } from "../analytics";

import styles from "./search_filters.module.scss";
import classNames from "classnames";

const MAX_DISPLAY_OPTIONS = 8;
export const PREMIUM_FILTER_NAMES: Array<SearchFilterOptions> = ['standards', 'genre', 'decade', 'most_popular'];

export type SearchFilterOptions = 'age_range' | 'clip_length' | 'series' | 'no_profanity' | 'decade' | 'standards' | 'subjects' | 'tags' | 'ai_search' | 'genre' | 'media_type' | 'most_popular';

interface SearchFilterOption {
  label: string;
  value: string;
  checked?: boolean;
}

interface SearchFiltersProps {
  includedFilters: Array<SearchFilterOptions>;
  selectedSearchFilterValues: { [key: string]: any };
  isPaidUser: boolean;
  searchRequestPath: string;
  userId?: number;
  ageRanges?: Array<AgeRange>;
  subjects?: Array<Subject>;
  tags?: Array<Tag>;
  clipLengths?: Array<string>;
  allSeries?: Array<Series>;
  mediaTypes?: Array<Array<string>>;
  standards?: Array<Standard>;
  genres?: Array<Genre>;
  decades?: Array<Array<string>>;
  onSubmit?: (searchFilterValues: { [key: string]: any }) => void;
}

interface SearchMultiFilterSectionProps {
  title: string;
  multiSelectOptions: Array<SearchFilterOption>;
  filterName: string;
  onFilterSelected: (filterName: string, value: string | number | Array<SearchFilterOption>) => void;
  onFilterModalShown?: (filterName: string, title: string, filterOptions: Array<SearchFilterOption>) => void;
  disabled?: boolean;
}

interface SearchFilterModalProps {
  shown: boolean;
  title: string;
  filterOptions: Array<SearchFilterOption>;
  onHandleClose: () => void;
  onSubmit: (e, modalFilterOptions: Array<SearchFilterOption>) => void;
}

export function SearchFilters({ includedFilters, isPaidUser, searchRequestPath, ...props }: SearchFiltersProps) {
  const [modalSelectedFilter, setModalSelectedFilter] = useState('');
  const [modalSelectedTitle, setModalSelectedTitle] = useState('');
  const [modalFilterOptions, setModalFilterOptions] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  const [selectedSearchFilterValues, setSelectedSearchFilterValues] = useState({ ...props.selectedSearchFilterValues });

  let ageRangeOptions = [];
  let subjectsOptions = [];
  let tagsOptions = [];
  let clipLengthOptions = [];
  let seriesOptions = [];
  let mediaTypeOptions = [];
  let standardsOptions = [];
  let genreOptions = [];
  let decadeOptions = [];

  const premiumFiltersIncluded = useMemo(() => {
    return PREMIUM_FILTER_NAMES.some(filterName => includedFilters.includes(filterName));
  }, []);

  if (includedFilters.includes("age_range")) {
    ageRangeOptions = props.ageRanges.map(ageRange => ({ label: ageRange.name, value: ageRange.value.toString(), checked: selectedSearchFilterValues["age_range"] && selectedSearchFilterValues["age_range"].includes(ageRange.value.toString()) }));
  }

  if (includedFilters.includes("subjects")) {
    subjectsOptions = props.subjects.map(subject => ({ label: subject.name, value: subject.id.toString(), checked: selectedSearchFilterValues["subjects"] && selectedSearchFilterValues["subjects"].includes(subject.id.toString()) }));
  }

  if (includedFilters.includes("tags")) {
    tagsOptions = props.tags.map(tag => ({ label: tag.name, value: tag.id.toString(), checked: selectedSearchFilterValues["tags"] && selectedSearchFilterValues["tags"].includes(tag.id.toString()) }));
  }

  if (includedFilters.includes("clip_length")) {
    clipLengthOptions = props.clipLengths.map((clipLength, index) => ({ label: clipLength, value: index.toString(), checked: selectedSearchFilterValues["clip_length"] && selectedSearchFilterValues["clip_length"].includes(index.toString()) }));
  }

  if (includedFilters.includes("series")) {
    seriesOptions = props.allSeries.map(series => ({ label: series.name, value: series.id.toString(), checked: selectedSearchFilterValues["series"] && selectedSearchFilterValues["series"].includes(series.id.toString()) }));
  }

  if (includedFilters.includes("media_type")) {
    mediaTypeOptions = props.mediaTypes.map(mediaTypeAry => ({ label: mediaTypeAry[0], value: mediaTypeAry[1].toString(), checked: selectedSearchFilterValues["media_type"] && selectedSearchFilterValues["media_type"].includes(mediaTypeAry[1].toString()) }));
  }

  if (includedFilters.includes("standards")) {
    standardsOptions = props.standards.map(standard => ({ label: standard.code, value: standard.id.toString(), checked: selectedSearchFilterValues["standards"] && selectedSearchFilterValues["standards"].includes(standard.id.toString()) }));
  }

  if (includedFilters.includes("genre")) {
    genreOptions = props.genres.map(genre => ({ label: genre.name, value: genre.id.toString(), checked: selectedSearchFilterValues["genre"] && selectedSearchFilterValues["genre"].includes(genre.id.toString()) }));
  }

  if (includedFilters.includes("decade")) {
    decadeOptions = props.decades.map(decadeAry => ({ label: decadeAry[0], value: decadeAry[1].toString(), checked: selectedSearchFilterValues["decade"] && selectedSearchFilterValues["decade"].includes(decadeAry[1].toString()) }));
  }

  const reloadPageWithFilters = (updatedSelectedSearchFilterValues) => {
    const url = new URL(window.location.href);
    const params = {
      q: url.searchParams.get("q"),
      filters: updatedSelectedSearchFilterValues
    };

    url.search = qs.stringify(params, { arrayFormat: "brackets" });

    window.location.href = url.toString();
  };

  const onFilterSelected = (filterName: string, value: string | number | Array<SearchFilterOption>) => {
    const newSelectedSearchFilterValues = { ...selectedSearchFilterValues };

    if (value === null) {
      delete newSelectedSearchFilterValues[filterName];
    } else if (typeof (value) === "string" || typeof (value) === "number") {
      newSelectedSearchFilterValues[filterName] = value;
    } else {
      newSelectedSearchFilterValues[filterName] = value.filter(option => option.checked).map(option => option.value);
    }

    setSelectedSearchFilterValues(newSelectedSearchFilterValues);

    if (props.onSubmit) {
      props.onSubmit?.(newSelectedSearchFilterValues);
      return;
    }

    reloadPageWithFilters(newSelectedSearchFilterValues);
  };

  const onClearFilters = () => {
    const newSelectedSearchFilterValues = {};

    Object.keys(selectedSearchFilterValues).forEach(filterKey => {
      const filtersValue = selectedSearchFilterValues[filterKey];

      // Only copy arrays since single value filter keys are normally deleted when not selected
      if (typeof (filtersValue) === "object") {
        newSelectedSearchFilterValues[filterKey] = [];
      }
    });

    if (props.onSubmit) {
      setSelectedSearchFilterValues(newSelectedSearchFilterValues);
      props.onSubmit?.(newSelectedSearchFilterValues);
      return;
    }

    reloadPageWithFilters(newSelectedSearchFilterValues);
  };

  const onFilterModalShown = useCallback((filterName: string, title: string, filterOptions: Array<SearchFilterOption>) => {
    setModalSelectedFilter(filterName);
    setModalSelectedTitle(title);
    setModalFilterOptions(filterOptions);
    setModalShown(true);
  }, []);

  const onModalClose = useCallback(() => {
    setModalSelectedFilter('');
    setModalSelectedTitle('');
    setModalFilterOptions([]);
    setModalShown(false);
  }, []);

  const onModalSubmit = (e, modalSelectedFilters: Array<SearchFilterOption>) => {
    e.preventDefault();

    const selectedValues = modalSelectedFilters.filter(option => option.checked).map(option => option.value);

    const newSelectedSearchFilterValues = {
      ...selectedSearchFilterValues,
      [modalSelectedFilter]: selectedValues
    };

    if (props.onSubmit) {
      setSelectedSearchFilterValues(newSelectedSearchFilterValues);
      onModalClose();
      props.onSubmit?.(newSelectedSearchFilterValues);
      return;
    }

    reloadPageWithFilters(newSelectedSearchFilterValues);

    onModalClose();
  };

  const onUpgradeClicked = (link: string) => {
    chAnalyticsTrackEvent("paywall", {
      user_id: props.userId,
      feature: "search_filters",
      label: "click_paywall",
      link: link
    });
  };

  const multiFilterProps = { onFilterSelected, onFilterModalShown };

  return (
    <>
      {/* Search preferences */}
      {props.userId && <SaveSearchPreferencesButton userId={props.userId} searchFilterValues={selectedSearchFilterValues} />}

      {/* Clear All button */}
      <Button variant="outline-secondary" size="sm" className="px-3 mx-3 mb-3 py-0" onClick={onClearFilters}>
        Clear All
      </Button>

      <Form>
        {/* Single item filters */}
        <div className="mb-3">
          {includedFilters.includes("no_profanity") && renderSearchToggleFilterSection("No Profanity", "1", "no_profanity", onFilterSelected, (selectedSearchFilterValues["no_profanity"] === "1"))}
        </div>

        {/* Multi item filters */}
        {includedFilters.includes("age_range") && <SearchMultiFilterSection {...multiFilterProps} title="Grade Level" multiSelectOptions={ageRangeOptions} filterName={"age_range"} />}
        {includedFilters.includes("subjects") && <SearchMultiFilterSection {...multiFilterProps} title="Subject" multiSelectOptions={subjectsOptions} filterName="subjects" />}
        {includedFilters.includes("tags") && <SearchMultiFilterSection {...multiFilterProps} title="Topic" multiSelectOptions={tagsOptions} filterName="tags" />}
        {includedFilters.includes("clip_length") && <SearchMultiFilterSection {...multiFilterProps} title="Clip Length" multiSelectOptions={clipLengthOptions} filterName="clip_length" />}
        {includedFilters.includes("series") && <SearchMultiFilterSection {...multiFilterProps} title="Series" multiSelectOptions={seriesOptions} filterName="series" />}
        {includedFilters.includes("media_type") && <SearchMultiFilterSection {...multiFilterProps} title="Media Type" multiSelectOptions={mediaTypeOptions} filterName="media_type" />}

        {/* Premium */}
        {premiumFiltersIncluded &&
          <div className={!isPaidUser ? "border rounded" : ""}>
            {!isPaidUser &&
              <div className={classNames(styles.premiumHeading, "rounded", "p-2")}>
                <UpgradeButton
                  extraBtnClasses={classNames(styles.premiumUpgradeButton, "mr-1")}
                  onClick={() => onUpgradeClicked("upgrade_button")}
                />
                {' '}
                <span className="small mr-2">Premium Filters</span>
                {' '}
                <a
                  href="/pricing"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classNames(styles.premiumLearnMore, "small")}
                  onClick={() => onUpgradeClicked("learn_more_link")}
                >
                  Learn More
                </a>
              </div>
            }

            <div className="my-3">
              {includedFilters.includes("most_popular") && renderSearchToggleFilterSection("Most Popular", "1", "most_popular", onFilterSelected, (selectedSearchFilterValues["most_popular"] === "1"), !isPaidUser)}
            </div>

            {includedFilters.includes("standards") && <SearchMultiFilterSection {...multiFilterProps} title="Standards" multiSelectOptions={standardsOptions} filterName="standards" disabled={!isPaidUser} />}
            {includedFilters.includes("genre") && <SearchMultiFilterSection {...multiFilterProps} title="Genre" multiSelectOptions={genreOptions} filterName="genre" disabled={!isPaidUser} />}
            {includedFilters.includes("decade") && <SearchMultiFilterSection {...multiFilterProps} title="Decade" multiSelectOptions={decadeOptions} filterName="decade" disabled={!isPaidUser} />}
          </div>
        }
      </Form>

      {modalShown &&
        <SearchFilterModal
          shown={modalShown}
          title={modalSelectedTitle}
          filterOptions={modalFilterOptions}
          onHandleClose={onModalClose}
          onSubmit={onModalSubmit}
        />
      }
    </>
  );
}

function renderSearchToggleFilterSection(label: string, value: string, filterName: string, onFilterSelected, selected: boolean = false, disabled: boolean = false) {
  const elementId = `search_filter_${htmlAttrSafeName(label)}`;

  const onChange = e => {
    onFilterSelected(filterName, e.target.checked ? value : null);
  };

  return (
    <div className="px-3">
      <Form.Check
        id={elementId}
        type="checkbox"
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={selected}
      />
    </div>
  );
}

function SearchMultiFilterSection({ title, multiSelectOptions, filterName, onFilterSelected, onFilterModalShown, disabled = false }: SearchMultiFilterSectionProps) {
  const baseElementId = `search_filter_${htmlAttrSafeName(title)}`;
  const shouldShowMore = (multiSelectOptions.length > MAX_DISPLAY_OPTIONS);

  const onChange = (e, value) => {
    const newSelectOptions = [...multiSelectOptions];

    const changedOptionIndex = newSelectOptions.findIndex(option => option.value === value);
    const changedOption = newSelectOptions[changedOptionIndex];

    changedOption.checked = !changedOption.checked;

    newSelectOptions.splice(changedOptionIndex, 1, changedOption);

    onFilterSelected(filterName, newSelectOptions);
  };

  return (
    <div className="mb-3 px-3">
      <h6 className="font-weight-bold">{title}</h6>

      <div>
        {multiSelectOptions.length < 1 && <div className={disabled ? 'text-muted' : ''}>None available</div>}

        {multiSelectOptions.slice(0, MAX_DISPLAY_OPTIONS - 1).map(option =>
          <Form.Check
            key={`${baseElementId}_${htmlAttrSafeName(option.value)}`}
            id={`${baseElementId}_${htmlAttrSafeName(option.value)}`}
            type="checkbox"
            label={option.label}
            value={option.value}
            checked={!!option.checked}
            onChange={e => onChange(e, option.value)}
            disabled={disabled}
          />
        )}

        {shouldShowMore &&
          <Button variant="link" size="sm" disabled={disabled} onClick={() => onFilterModalShown(filterName, title, multiSelectOptions)}>
            show more
          </Button>
        }
      </div>
    </div>
  );
}

function SearchFilterModal({ shown, title, filterOptions, onHandleClose, onSubmit }: SearchFilterModalProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFilterOptions, setSearchFilterOptions] = useState<Array<SearchFilterOption>>([...filterOptions]);

  const baseElementId = `search_filter_${htmlAttrSafeName(title)}`;

  let displayFilterOptions = searchFilterOptions;

  if (searchQuery.length > 0) {
    displayFilterOptions = displayFilterOptions.filter(option => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
  }

  const onSelected = (checked, value) => {
    const newFilterOptions = [...filterOptions];

    const changedOptionIndex = newFilterOptions.findIndex(option => option.value === value);
    const changedOption = newFilterOptions[changedOptionIndex];

    changedOption.checked = checked;

    newFilterOptions.splice(changedOptionIndex, 1, changedOption);

    setSearchFilterOptions(newFilterOptions);
  };

  return (
    <Modal show={shown} onHide={onHandleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Filter by {title}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={e => onSubmit(e, searchFilterOptions)}>
        <Modal.Body>
          <div className="mb-3">
            <h6 className="font-weight-bold">Filters</h6>

            <InputGroup className="mb-3">
              <Form.Control
                type="input"
                placeholder="Search"
                aria-label="Search"
                onChange={e => setSearchQuery(e.target.value)}
              />
            </InputGroup>

            <div className={styles.modalOptionsContainer}>
              {displayFilterOptions.map(option =>
                <Form.Check
                  key={`${baseElementId}_modal_${htmlAttrSafeName(option.value)}`}
                  id={`${baseElementId}_modal_${htmlAttrSafeName(option.value)}`}
                  type="checkbox"
                  label={option.label}
                  value={option.value}
                  defaultChecked={option.checked}
                  onChange={e => onSelected(e.target.checked, option.value)}
                />
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center px-5">
          <Button type="submit" variant="primary" className="px-3" aria-label="Apply">
            Apply
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function htmlAttrSafeName(str: string) {
  return str.toString().toLowerCase().replaceAll(" ", "_");
}

export function renderSearchFilters(elementId, options) {
  const node = ReactDOM.render(
    <SearchFilters
      ageRanges={options.ageRanges}
      subjects={options.subjects}
      tags={options.tags}
      clipLengths={options.clipLengths}
      allSeries={options.allSeries}
      mediaTypes={options.mediaTypes}
      standards={options.standards}
      genres={options.genres}
      decades={options.decades}
      includedFilters={options.includedFilters}
      selectedSearchFilterValues={options.selectedSearchFilterValues}
      isPaidUser={options.isPaidUser}
      searchRequestPath={options.searchRequestPath}
      userId={options.userId}
      onSubmit={options.onSubmit}
    />,
    document.getElementById(elementId)
  );

  return node;
}