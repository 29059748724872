import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_CONTAINER_CLASS = "border rounded mb-3 bg-white";

interface Props {
  children: React.ReactNode;
  extraContainerClasses?: string;
  header: string;
  iconName?: string;
}

export default function ContainerWithHeading({ children, extraContainerClasses, header, iconName }: Props) {
  const theExtraContainerClasses = extraContainerClasses || "";
  const theContainerClass = `${DEFAULT_CONTAINER_CLASS} ${theExtraContainerClasses}`;

  return (
    <div className={theContainerClass}>
      <div className="px-3 py-2 rounded-top">
        {iconName &&
          <FontAwesomeIcon icon={iconName} size="lg" className="mr-2" />
        }

        {header}
      </div>

      <hr className="m-0" />

      <div className="px-3 py-2 rounded-bottom">
        {children}
      </div>
    </div>
  );
}
