import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validate } from "email-validator";

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";

const MAX_SITES_QUANTITY = 1000;

function NewQuoteForm() {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [organization, setOrganization] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [numSites, setNumSites] = useState(0);

  const onCreate = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data;

    try {
      const response = await postData({
        url: "/quotes",
        data: {
          quote: {
            organization: organization,
            first_name: firstName,
            last_name: lastName,
            title: title,
            email: email,
            num_sites: numSites,
            num_users: 0
          }
        }
      });

      data = response.data;
    } catch (e) {
      data = e.response.data;
    }

    if (data.errors) {
      setErrors(data.errors);
    } else if (data.quote_id) {
      window.location.href = `/quotes/${data.quote_id}`;
    }

    setIsLoading(false);
  };

  const handleNumSitesChange = (e) => {
    let newNumSites = isNaN(e.target.value) ? 0 : parseInt(e.target.value, 10);

    if (newNumSites > MAX_SITES_QUANTITY) {
      newNumSites = MAX_SITES_QUANTITY;
    }

    setNumSites(newNumSites);
  };

  const saveButtonEnabled = !isLoading &&
    organization.length > 0 &&
    firstName.length > 0 &&
    lastName.length > 0 &&
    title.length > 0 &&
    email.length > 0 &&
    validate(email) &&
    numSites && numSites > 0
    ;

  return (
    <>
      <FormErrorsList errors={errors} />

      <Form onSubmit={onCreate}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Form.Label htmlFor="quote_organization">School, District or Organization Name</Form.Label>
            <Form.Control id="quote_organization" type="text" onChange={e => setOrganization(e.target.value)} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_first_name">Contact First Name</Form.Label>
            <Form.Control id="quote_first_name" type="text" onChange={e => setFirstName(e.target.value)} />
          </div>

          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_last_name">Contact Last Name</Form.Label>
            <Form.Control id="quote_last_name" type="text" onChange={e => setLastName(e.target.value)} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_title">Contact Title</Form.Label>
            <Form.Control id="quote_title" type="text" onChange={e => setTitle(e.target.value)} />
          </div>

          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_email">Contact Email</Form.Label>
            <Form.Control id="quote_email" type="email" onChange={e => setEmail(e.target.value)} />
          </div>
        </div>

        <hr />

        <div className="form-row">
          <div className="form-group col-md-6">
            <Form.Label htmlFor="quote_num_sites"># Sites</Form.Label>
            <Form.Control id="quote_num_sites" type="number" min="1" max={MAX_SITES_QUANTITY} value={numSites} onChange={handleNumSitesChange} />

            <small className="form-text">
              A site refers to a school or a campus.
            </small>
          </div>
        </div>

        <hr></hr>

        <div className="text-right">
          <Button type="submit" variant="primary" disabled={!saveButtonEnabled}>
            <span className="mr-2">Save and View Quote</span>
            <FontAwesomeIcon icon="arrow-right" />
          </Button>
        </div>
      </Form>
    </>
  );
}

export function renderNewQuoteForm(elementId, options) {
  const node = ReactDOM.render(
    <NewQuoteForm />,
    document.getElementById(elementId)
  );

  return node;
}