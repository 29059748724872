import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactQuill from 'react-quill';
import { v4 as uuid } from "uuid";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { postData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import { EDITOR_FORMATS, EDITOR_MODULES } from "../../lib/quill_helper";
import { normalizeMinuteOrSecondValue, timestampFromSeconds } from "../time_functions";
import { getCurrentVideoPlayer } from "../video_player/functions";
import { PausePrompt } from "../../types";

import styles from "./new_pause_prompt_form.module.scss";
import 'react-quill/dist/quill.snow.css';

interface Props {
  promptableType: string;
  promptableId: number;
  canAddPausePrompt: boolean;
  onCreate: (pausePrompt: PausePrompt, canAddPausePrompt: boolean) => void;
  onCancel: () => void;
  prompt?: string;
}

const baseElementId = `new-pause-prompt-form-${uuid()}`;
const autoResumeElementId = `${baseElementId}-auto-resume`;
const pauseMinutesElementId = `${baseElementId}-pause-minutes`;
const pauseSecondsElementId = `${baseElementId}-pause-seconds`;
const privacyElementId = `${baseElementId}-privacy`;

export default function NewPausePromptForm(props: Props) {
  const [prompt, setPrompt] = useState(props.prompt || '');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [autoResume, setAutoResume] = useState(true);
  const [isPublic, setIsPublic] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);

  useEffect(() => {
    const theIntervalId = setInterval(() => {
      const thePlayer = getCurrentVideoPlayer();

      if (thePlayer) {
        setCurrentPlayerTime(thePlayer.currentTime || 0);
      }
    }, 500);

    return () => clearInterval(theIntervalId);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setIsDisabled(true);

    try {
      const { data } = await postData({
        url: "/pause_prompts",
        data: {
          pause_prompt: {
            prompt: prompt,
            start_time: currentPlayerTime,
            pause_duration_minutes: minutes,
            pause_duration_seconds: seconds,
            auto_resume: autoResume,
            public: isPublic,
            promptable_id: props.promptableId,
            promptable_type: props.promptableType
          }
        }
      });

      props.onCreate(data.pause_prompt, data.can_add_pause_prompt);
    } catch (e) {
      if (e.response.data.errors) {
        setErrors(e.response.data.errors);
      }

      setIsDisabled(false);
    }
  };

  return (
    <>
      {errors.length > 0 &&
        <div className="p-3">
          <FormErrorsList errors={errors} />
        </div>
      }

      <Form onSubmit={onSubmit}>
        <div className="d-flex flex-wrap align-items-center p-3">
          <h5 className="mb-0 mr-3">Add a Pause Prompt</h5>
          <span className={styles.timeDisplay}>
            {timestampFromSeconds(currentPlayerTime)}
          </span>
        </div>

        <hr className="m-0" />

        <div className="pt-3 px-3">
          <Form.Row>
            <Form.Group className="col-md-12">
              <ReactQuill
                className={styles.editor}
                theme="snow"
                value={prompt}
                modules={EDITOR_MODULES}
                formats={EDITOR_FORMATS}
                onChange={setPrompt}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group className="col-md-3">
              <Form.Label htmlFor={autoResumeElementId}>Timed?</Form.Label>
              <Form.Control as="select" id={autoResumeElementId} size="sm" defaultValue={autoResume.toString()} onChange={e => setAutoResume(e.target.value === "true")}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label htmlFor={pauseMinutesElementId}>Pause Length</Form.Label>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="input-group input-group-sm">
                    <Form.Control
                      id={pauseMinutesElementId}
                      type="number"
                      min={0}
                      max={59}
                      disabled={!autoResume}
                      value={minutes}
                      onChange={e => setMinutes(normalizeMinuteOrSecondValue(e.target.value))}
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">min</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-group input-group-sm">
                    <Form.Control
                      id={pauseSecondsElementId}
                      type="number"
                      min={0}
                      max={59}
                      disabled={!autoResume}
                      value={seconds}
                      onChange={e => setSeconds(normalizeMinuteOrSecondValue(e.target.value))}
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">sec</span>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="col-md-3">
              <Form.Label htmlFor={privacyElementId}>Privacy</Form.Label>
              <Form.Control as="select" id={privacyElementId} size="sm" defaultValue={isPublic.toString()} onChange={e => setIsPublic(e.target.value === "true")}>
                <option value="true">Public</option>
                <option value="false">Private</option>
              </Form.Control>
            </Form.Group>
          </Form.Row >
        </div>

        <hr className="m-0" />

        <div className={classNames("d-flex flex-row flex-wrap p-3", styles.formFooter)}>
          <Button variant="outline-secondary" size="sm" className="px-3" onClick={props.onCancel}>
            Cancel
          </Button>

          <div className="flex-grow-1"></div>

          <Button type="submit" variant="primary" size="sm" className="px-3" disabled={isDisabled}>
            Add Prompt
          </Button>
        </div>
      </Form>
    </>
  );
}
