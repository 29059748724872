import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Truncate from "react-truncate";

import FormErrorsList from "../shared_components/form_errors_list";
import { patchData, deleteData } from "../global_functions";
import { User, VideoQuestion } from "../../types";
import styles from "./video_question_item.module.scss";

interface Props {
  videoQuestion: VideoQuestion;
  currentUser: User;
}

function correctUser(user: User, videoQuestion: VideoQuestion) {
  return (user?.id === videoQuestion?.user_id);
}

export function VideoQuestionItem({ videoQuestion, currentUser }: Props) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionText, setQuestionText] = useState(videoQuestion.question || '');
  const [theVideoQuestion, setTheVideoQuestion] = useState<VideoQuestion>(videoQuestion);
  const [errors, setErrors] = useState([]);

  const onUpdate = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data;

    try {
      const response = await patchData({
        url: `/video_questions/${videoQuestion.id}`,
        data: {
          user_name: currentUser.name,
          question: questionText
        }
      });

      data = response.data;
    } catch (e) {
      data = e.response.data;
    }

    if (data.errors) {
      setErrors(data.errors);
    } else if (data.video_question) {
      setTheVideoQuestion(data.video_question);
      setQuestionText(data.video_question.question);
      setIsEditMode(false);
    }

    setIsLoading(false);
  };

  const onEdit = (e) => {
    e.preventDefault();

    if (!correctUser(currentUser, videoQuestion)) return false;

    setIsEditMode(true);
  };

  const onCancelEdit = (e) => {
    setQuestionText(theVideoQuestion.question);
    setIsEditMode(false);
  };

  const onDelete = async (e) => {
    e.preventDefault();

    if (!correctUser(currentUser, videoQuestion)) return false;

    let data;

    try {
      const response = await deleteData({ url: `/video_questions/${videoQuestion.id}` });
      data = response.data;
    } catch (e) {
      data = e.response.data;
    }

    if (data.errors) {
      setErrors(data.errors);
    } else if (data.video_question) {
      setTheVideoQuestion(null);
    }
  };

  if (!theVideoQuestion) return null;

  return (
    <>
      <div className={styles.item_container}>
        <a href="#" onClick={onEdit} title={theVideoQuestion.question}>
          <div>{theVideoQuestion.display_time}</div>

          {!isEditMode &&
            <Truncate lines={2}>
              {theVideoQuestion.question}
            </Truncate>
          }
        </a>

        {!isEditMode && correctUser(currentUser, videoQuestion) &&
          <>
            <div className="flex-grow-1"></div>

            <div className="d-flex justify-content-center mt-3">
              <button type="button" onClick={onDelete} className="btn btn-link text-decoration-none text-red">
                <FontAwesomeIcon icon="trash" />
                <span className="sr-only">Delete</span>
              </button>
            </div>
          </>
        }

        {isEditMode &&
          <div className="mt-2">
            <FormErrorsList errors={errors} />

            <Form onSubmit={onUpdate}>
              <div>
                <textarea
                  className="form-control"
                  value={questionText}
                  placeholder="Enter your question"
                  rows={2}
                  onChange={e => setQuestionText(e.target.value)}
                />

                <div className="d-flex flex-wrap justify-content-end mt-2">
                  <button type="submit" className="btn btn-sm btn-primary mr-1" disabled={isLoading}>
                    <FontAwesomeIcon icon="check" />
                    <span className="sr-only">Save</span>
                  </button>

                  <button type="button" className="btn btn-sm btn-outline-secondary" onClick={onCancelEdit} disabled={isLoading}>
                    <FontAwesomeIcon icon="times" />
                    <span className="sr-only">Cancel</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        }
      </div>
    </>
  );
}