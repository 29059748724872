import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ReactQuill from 'react-quill';
import { v4 as uuid } from "uuid";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { patchData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import { EDITOR_FORMATS, EDITOR_MODULES } from "../../lib/quill_helper";
import { normalizeMinuteOrSecondValue, timestampFromSeconds } from "../time_functions";
import { getCurrentVideoPlayer } from "../video_player/functions";
import { PausePrompt } from "../../types";

import styles from "./new_pause_prompt_form.module.scss";
import 'react-quill/dist/quill.snow.css';

interface Props {
  pausePrompt: PausePrompt;
  onUpdate: (pausePrompt: PausePrompt) => void;
  onCancel: () => void;
}

const baseElementId = `edit-pause-prompt-form-${uuid()}`;
const autoResumeElementId = `${baseElementId}-auto-resume`;
const pauseMinutesElementId = `${baseElementId}-pause-minutes`;
const pauseSecondsElementId = `${baseElementId}-pause-seconds`;
const privacyElementId = `${baseElementId}-privacy`;

export default function EditPausePromptForm({ pausePrompt, ...props }: Props) {
  const [prompt, setPrompt] = useState(pausePrompt.prompt);
  const [minutes, setMinutes] = useState(pausePrompt.extract_pause_duration_minutes);
  const [seconds, setSeconds] = useState(pausePrompt.extract_pause_duration_seconds);
  const [autoResume, setAutoResume] = useState(pausePrompt.auto_resume);
  const [isPublic, setIsPublic] = useState(pausePrompt.public);

  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const [currentPlayerTime, setCurrentPlayerTime] = useState(pausePrompt.start_time);

  const mountedRef = useRef(true);

  useEffect(() => {
    const thePlayer = getCurrentVideoPlayer();

    if (thePlayer) {
      thePlayer.seekTo(pausePrompt.start_time);
    }

    const theIntervalId = setInterval(() => {
      const thePlayer = getCurrentVideoPlayer();

      if (thePlayer) {
        setCurrentPlayerTime(thePlayer.currentTime || 0);
      }
    }, 500);

    return () => {
      clearInterval(theIntervalId);
      mountedRef.current = false;
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setIsDisabled(true);

    try {
      const { data } = await patchData({
        url: `/pause_prompts/${pausePrompt.id}`,
        data: {
          pause_prompt: {
            prompt: prompt,
            start_time: currentPlayerTime,
            pause_duration_minutes: minutes,
            pause_duration_seconds: seconds,
            auto_resume: autoResume,
            public: isPublic
          }
        }
      });

      props.onUpdate(data.pause_prompt);
    } catch (e) {
      if (e.response.data.errors) {
        setErrors(e.response.data.errors);
      }
    } finally {
      if (mountedRef.current) {
        setIsDisabled(false);
      }
    }
  };

  return (
    <>
      {errors.length > 0 &&
        <div className="p-3">
          <FormErrorsList errors={errors} />
        </div>
      }

      <Form onSubmit={onSubmit}>
        <div className="d-flex flex-wrap align-items-center p-3">
          <h5 className="mb-0 mr-3">Edit Pause Prompt</h5>

          <span className={classNames(styles.timeDisplay, "mr-3")}>
            {timestampFromSeconds(currentPlayerTime)}
          </span>

          <span className="small text-muted">
            Original time {timestampFromSeconds(pausePrompt.start_time)}
          </span>
        </div>

        <hr className="m-0" />

        <div className="pt-3 px-3">
          <Form.Row>
            <Form.Group className="col-md-12">
              <ReactQuill
                className={styles.editor}
                theme="snow"
                value={prompt}
                modules={EDITOR_MODULES}
                formats={EDITOR_FORMATS}
                onChange={setPrompt}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group className="col-md-3">
              <Form.Label htmlFor={autoResumeElementId}>Timed?</Form.Label>
              <Form.Control as="select" id={autoResumeElementId} size="sm" defaultValue={autoResume.toString()} onChange={e => setAutoResume(e.target.value === "true")}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label htmlFor={pauseMinutesElementId}>Pause Length</Form.Label>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="input-group input-group-sm">
                    <Form.Control
                      id={pauseMinutesElementId}
                      type="number"
                      min={0}
                      max={59}
                      disabled={!autoResume}
                      value={minutes}
                      onChange={e => setMinutes(normalizeMinuteOrSecondValue(e.target.value))}
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">min</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-group input-group-sm">
                    <Form.Control
                      id={pauseSecondsElementId}
                      type="number"
                      min={0}
                      max={59}
                      disabled={!autoResume}
                      value={seconds}
                      onChange={e => setSeconds(normalizeMinuteOrSecondValue(e.target.value))}
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">sec</span>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="col-md-3">
              <Form.Label htmlFor={privacyElementId}>Privacy</Form.Label>
              <Form.Control as="select" id={privacyElementId} size="sm" defaultValue={isPublic.toString()} onChange={e => setIsPublic(e.target.value === "true")}>
                <option value="true">Public</option>
                <option value="false">Private</option>
              </Form.Control>
            </Form.Group>
          </Form.Row >
        </div>

        <hr className="m-0" />

        <div className={classNames("d-flex flex-row flex-wrap p-3", styles.formFooter)}>
          <Button variant="outline-secondary" size="sm" className="px-3" onClick={props.onCancel}>
            Cancel
          </Button>

          <div className="flex-grow-1"></div>

          <Button type="submit" variant="primary" size="sm" className="px-3" disabled={isDisabled}>
            Save Prompt
          </Button>
        </div>
      </Form >
    </>
  );
}
