import React from "react";
import classNames from "classnames";

import UpgradeButton from "../shared_components/upgrade_button";

import styles from "./paywall_upgrade_section.module.scss";

interface Props {
  urlPath?: string;
  onLinkClick: (e) => void;
}

export default function PaywallUpgradeSection({ urlPath = "/pricing", onLinkClick }: Props) {
  return (
    <div className={classNames("rounded mt-4 p-3", styles.upgradeBackground)}>
      <div className="d-flex align-items-center">
        <div className="mr-3">
          <UpgradeButton disableClicks={true} />
        </div>

        <div className="mr-4">
          Upgrade to the <span className={styles.premiumPlanText}>Premium plan</span>
        </div>

        <div className="flex-grow-1"></div>

        <a href={urlPath} className="btn btn-primary flex-shrink-0" onClick={onLinkClick}>
          Learn More
        </a>
      </div>
    </div>
  );
}