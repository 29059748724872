import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable, { TableColumn } from 'react-data-table-component';

import { AssignUserLicenseButton } from "./assign_user_license_button";
import InviteUsersField from "../shared_components/invite_users_field";
import { OrganizationDisplayType, OrganizationType } from "../../types";

import "./view_educators_modal.scss";

interface Educator {
  id: number;
  name: string;
  email: string;
  last_active?: string;
  organization_name?: string;
}

interface Props {
  educators: Array<Educator>;
  totalLicenseCount?: number;
  userLicenseObj: object;
  organization: {
    id: number;
    licenseType: 'teacher' | 'school' | 'district';
    displayType: OrganizationDisplayType;
  }
}

function isEducatorMatch(educator, filterText) {
  if (!filterText) return false;

  const theFilterText = filterText && filterText.toLowerCase();

  if (educator.name && educator.name.toLowerCase().includes(theFilterText)) {
    return true;
  }

  if (educator.email && educator.email.toLowerCase().includes(theFilterText)) {
    return true;
  }

  if (educator.last_active && educator.last_active.toLowerCase().includes(theFilterText)) {
    return true;
  }

  if (educator.organization_name && educator.organization_name.toLowerCase().includes(theFilterText)) {
    return true;
  }

  return false;
}

function educatorLastActive(lastActive) {
  if (!lastActive) return "---";

  // Source: https://momentjs.com/docs/#/displaying/fromnow
  return moment(lastActive).fromNow();
}

function renderLicensesRemaining(remainingLicenseCount, totalLicenseCount) {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-3">Licenses Remaining: {remainingLicenseCount} / {totalLicenseCount}</div>

      {remainingLicenseCount == 0 &&
        <a href="/contact" className="small" target="_blank" rel="noopener noreferrer">
          Get more licenses
        </a>
      }
    </div>
  );
}

function ViewEducatorsModal(props: Props) {
  const [isShown, setIsShown] = useState(false);
  const [userLicenseObj, setUserLicenseObj] = useState(props.userLicenseObj);
  const [filterText, setFilterText] = useState("");

  let remainingLicenseCount = (props.totalLicenseCount !== null) ? (props.totalLicenseCount - Object.keys(userLicenseObj).length) : 1;

  if (remainingLicenseCount < 0) {
    remainingLicenseCount = 0;
  }

  let filteredEducators = props.educators.map(educator => {
    return { ...educator, last_active: educatorLastActive(educator.last_active) };
  });

  if (filterText.length > 0) {
    filteredEducators = filteredEducators.filter(educator => isEducatorMatch(educator, filterText));
  }

  const handleShow = () => setIsShown(true);
  const handleClose = () => setIsShown(false);
  const handleFilterChange = e => setFilterText(e.target.value);
  const onSuccess = (data) => {
    const { assigned_license: assignedLicense, removed_user_id: removedUserId, status } = data;

    const newLicenseObj = { ...userLicenseObj };

    if (status === "removed") {
      delete newLicenseObj[removedUserId];
    } else if (status === "added") {
      newLicenseObj[assignedLicense.user_id] = assignedLicense.id;
    }

    setUserLicenseObj(newLicenseObj);
  };

  let tableColumns: Array<TableColumn<Educator>> = [
    {
      name: "Name",
      selector: educator => educator.name,
      sortable: true,
      wrap: true
    },
    {
      name: "Email",
      selector: educator => educator.email,
      sortable: true,
      wrap: true
    }
  ];

  if (props.organization.displayType === "District") {
    tableColumns.push({
      name: "School",
      selector: educator => educator.organization_name,
      sortable: true,
      wrap: true
    });
  }

  tableColumns = tableColumns.concat([
    {
      name: "Last Login",
      selector: educator => educator.last_active,
      sortable: true,
      wrap: true
    },
    {
      name: "License",
      cell: educator => {
        return (
          <AssignUserLicenseButton
            anyRemainingLicenses={(remainingLicenseCount > 0)}
            assignedLicenseId={userLicenseObj[educator.id]}
            userId={educator.id}
            organizationId={props.organization.id}
            onSuccess={onSuccess}
          />
        );
      }
    }
  ]);

  return (
    <>
      <Button size="sm" variant="outline-secondary" onClick={handleShow}>
        Manage
      </Button>

      <Modal className="view-educators-modal" show={isShown} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon="chalkboard-teacher" />
            &nbsp;
            Educators in your {props.organization.displayType.toLowerCase()}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tabs defaultActiveKey="manage" id="view-educators-modal-tabs">
            <Tab eventKey="manage" title="Manage Educators">
              <div className="mt-3">
                {(props.totalLicenseCount !== null) && renderLicensesRemaining(remainingLicenseCount, props.totalLicenseCount)}

                <div className="d-flex">
                  <div className="flex-grow-1"></div>

                  <div>
                    <input type="input" className="form-control form-control-sm" placeholder="Filter" value={filterText} onChange={handleFilterChange} />
                  </div>
                </div>

                <div className="table-container">
                  <DataTable
                    columns={tableColumns}
                    data={filteredEducators}
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey="invite" title="Invite">
              <div className="mt-3">
                <p>Send up to 50 invitations at one time.</p>

                <div className="w-100">
                  <InviteUsersField postUrl={`/organizations/${props.organization.id}/send_invitations`} />
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function renderViewEducatorsModal(elementId, options) {
  const node = ReactDOM.render(
    <ViewEducatorsModal
      educators={options.educators}
      totalLicenseCount={options.totalLicenseCount}
      userLicenseObj={options.userLicenseObj}
      organization={options.organization}
    />,
    document.getElementById(elementId)
  );

  return node;
}